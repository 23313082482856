import React from "react";
import { isNotEmptyString } from "../../../helpers/JsHelper";

export default class AreaField extends React.Component {

    render() {
        const {label,value,attemptedSave, maxLength, mandatory, id, disabled, onChange} = this.props;
        const valueLength = value ? value.length : 0;
        const isMissing = attemptedSave && mandatory && !isNotEmptyString(value, true);
        const isTooLong = attemptedSave && maxLength > 0 && valueLength > maxLength;
        
        
        return <div className={"form-group mb-3"}>
            <div className="d-flex w-100 align-items-baseline">
                <label className="input-label my-0" htmlFor={id}>{label}</label>
                <span
                    className={`ml-auto small ${isTooLong ? "text-danger" : "text-secondary"}`}>{valueLength}/{maxLength}</span>
            </div>
            <textarea 
                            disabled={disabled}
                            id={id}
                            placeholder={disabled ? '' : `Enter ${label}`}
                            className={`form-control form-control-sm ${isMissing || isTooLong ? "border-danger" : ""}`}
                            value={value}
                            onChange={onChange}/>
            {isMissing && <div className="text-danger">Please enter the {label}</div>}
            {isTooLong && <div className="text-danger">{label} cannot exceed {maxLength} characters</div>}
        </div>;
    }

}