export const APP_FING_URL = "https://app.fing.com";
export const WEB_FING_URL = "https://www.fing.com";
export const CDN_IMAGES_URL = "https://cdn.fing.io/images";
export const HELP_URL = "https://help.fing.com";
export const COMMUNITY_URL = "https://community.fing.com";
export const GET_FING_URL = "https://get.fing.com";
export const FACEBOOK_FING_URL = "https://www.facebook.com/fingapp";
export const TWITTER_FING_URL = "https://twitter.com/fingapp";
export const LINKEDIN_FING_URL = "https://www.linkedin.com/company/fing-network-scanner/";
export const APPLE_FING_URL = "https://itunes.apple.com/us/app/fing-network-scanner/id430921107";
export const GOOGLE_FING_URL = "https://play.google.com/store/apps/details?id=com.overlook.android.fing";
export const WINDOWS_FING_URL = "https://www.fing.com/fing-desktop/download-windows";
export const MACOS_FING_URL = "https://www.fing.com/fing-desktop/download-mac";
export const SNAP_FING_URL = "https://snapcraft.io/fing-agent";
