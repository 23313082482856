import React from "react";

import { isUserSignedIn, UserContext } from "../UserContext";

import AppNavbar from "../components/AppNavbar";
import AppFooter from "../components/AppFooter";

import AccountPageTitle from "./AccountPageTitle";
import AccountSignedOutEmptyState from "./AccountSignedOutEmptyState";

import DataTableTest from "../components/table/DataTable";

import { remove, put, handleErrorFetch } from "../services/APIService";

import { formatLongAbsoluteDate } from "../helpers/DateHelper";
import { flatObjectPayload } from "../helpers/AccountHelper";
import { ToastContainer } from "react-toastify";
import { successToast, failedToast } from "../helpers/ToastHelper";
import {withRouter} from "react-router-dom";

class AccountConnections extends React.Component {

    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
        this.inputRef = React.createRef();

        this.state = {
            showDeleteModal: false,
            showRenameModal: false,
            selectedRow: null
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "Fing - Linked Apps";
    }

    // --------------------------------------------------------------------------------
    // MAIN RENDERING
    // --------------------------------------------------------------------------------


    render() {
        return (
            <div>
                <AppNavbar mode='account' />

                <main id="content" role="main">

                    <UserContext.Consumer>
                        {userContext => this.renderMainContent(userContext)}
                    </UserContext.Consumer>

                </main>

                <AppFooter />
            </div>
        );
    }


    renderMainContent(userContext) {
        if (isUserSignedIn(userContext)) {
            return (
                <div>
                    <div className="container space-2">
                        <AccountPageTitle
                            title="Your connections"
                            subtitle="Review each web, mobile and desktop session. Unlink to force a sign out" />
                        {this.renderTable()}
                    </div>
                    {this.renderRenameModal(userContext)}
                    {this.renderDeleteModal(userContext)}
                    <ToastContainer />
                </div>
            );
        } else {
            return this.renderEmptyState();
        }
    }

    renderEmptyState() {
        return <AccountSignedOutEmptyState />;
    }

    renderTable() {

        const headers = [
            { name: "clientType", displayField: "", sortable: false },
            { name: "clientName", displayField: "Name", sortable: true },
            { name: "clientVersion", displayField: "Version", sortable: true },
            { name: "clientPlatform", displayField: "Platform", sortable: true },
            { name: "firstAccess", displayField: "First activity", sortable: true },
            { name: "lastAccess", displayField: "Most recent activity", sortable: true },
            { name: "actions", displayField: "", sortable: false }
        ]

        const onRenderCell = (header, row) => {

            switch (header.name) {
                case "clientType":
                    const type = row[header.name] == null ? "" : row[header.name].toLowerCase();
                    switch (type) {
                        case 'mobile':
                            const platform = row["clientPlatform"] == null ? "" : row["clientPlatform"].toLowerCase();
                            if (platform.indexOf("ipad") !== -1) {
                                return <td><span className="fas fa-tablet-alt" /></td>;
                            } else if (platform.indexOf("iphone") !== -1) {
                                return <td><span className="fas fa-mobile" /></td>;
                            } else {
                                return <td><span className="fas fa-mobile-alt" /></td>;
                            }
                        case "desktop": return <td><span className="fas fa-desktop" /></td>;
                        case "sentinel": return <td><span className="fas fa-user-secret" /></td>;
                        case "browser": return <td><span className="fab fa-chrome" /></td>;
                        default: return <td><span className="fas fa-question-circle" /></td>;
                    }

                case "firstAccess":
                case "lastAccess":
                    // return <td>{intl.get('generic_date', { date: new Date(row[header.name]) })}</td>
                    return <td>{formatLongAbsoluteDate(new Date(row[header.name]).getTime())}</td>

                case "clientName":
                case "clientPlatform":
                    return <td className="min-w-33rem">{row[header.name] || ""}</td>;

                case "actions":
                    return <td className="d-flex justify-content-end">
                        <button className="btn btn-outline-secondary btn-xs mr-2"
                            onClick={() => this.setState({ showRenameModal: true, selectedRow: row })}>Rename
                        </button>
                        <button className="btn btn-danger btn-xs"
                            onClick={() => this.setState({ showDeleteModal: true, selectedRow: row })}>Delete
                        </button>
                    </td>
                default:
                    return <td>{row[header.name] || ""}</td>;
            }
        }


        return <DataTableTest
            ref={this.tableRef}
            search={false}
            autoBlockSearch={false}
            pagination={true}
            entries={true}
            resources="/rest/users/connections"
            name="Connections"
            headers={headers}
            onRenderCell={onRenderCell}
        />

    }

    renderDeleteModal(userContext) {

        const { showDeleteModal, selectedRow } = this.state;

        const handleClose = () => this.setState({ showDeleteModal: false, selectedRow: null });

        const handleSubmit = () => {

            const { selectedRow } = this.state;

            if (selectedRow && selectedRow.__rowId) {

                const url = "/rest/users/connections/" + selectedRow.__rowId['clientToken'];

                return remove(url)
                    .then(() => {
                        successToast("Connection deleted.");
                        this.tableRef.current.parentUpdateData({ total: -1 });
                    })
                    .catch((err) => {
                        failedToast("Something went wrong.");
                        if (err && err.code === 401) {
                            let newContext = handleErrorFetch(userContext);
                            userContext.userContextUpdater(newContext);
                        }
                        console.log('Error in unlink connection: ', err)
                    })
                    .finally(() => this.setState({ showDeleteModal: false, selectedRow: null }))

            }

        }


        if (!selectedRow) return "";

        return (
            <div>
                <div className={`modal-backdrop fade ${showDeleteModal ? "show d-block" : "d-none"}`}></div>
                <div className={`modal fade ${showDeleteModal ? "show d-block" : "d-none"}`} role="dialog" tabIndex="-1"
                    aria-labelledby="Delete_Modal_label" aria-hidden={!showDeleteModal} aria-modal={showDeleteModal} >
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div id="Delete_Modal_label" className="modal-header bg-danger p-3">
                                <h4 className="modal-title text-white">Dismiss {selectedRow["clientName"]} ?</h4>
                                <button type="button" className="close text-white" onClick={handleClose} data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span><span className="sr-only">Close</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container">
                                    <p>
                                        The browser <span className="text-primary">{selectedRow["clientName"]}</span> will be un-linked from your Fing account.
                                        You will need to re-enter your email and password on that device to connect it again.
                                    </p>
                                    <p>
                                        Do you want to proceed?
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-danger btn-xs mr-2"
                                        onClick={handleSubmit}>Unlink
                                    </button>
                                    <button className="btn btn-outline-secondary btn-xs"
                                        onClick={() => this.setState({ showDeleteModal: false, selectedRow: null })}>Dismiss
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }



    renderRenameModal(userContext) {

        const { showRenameModal, selectedRow } = this.state;

        const handleClose = () => this.setState({ showRenameModal: false, selectedRow: null });

        const handleSubmit = () => {

            const { selectedRow } = this.state;

            if (selectedRow && selectedRow.__rowId && this.inputRef.current) {

                const url = "/rest/users/connections/" + selectedRow.__rowId['clientToken'];

                selectedRow['clientName'] = this.inputRef.current.value;

                const payload = flatObjectPayload(selectedRow);

                return put(url, payload)
                    .then(() => {

                        successToast("Connection updated.");

                        this.tableRef.current.parentUpdateData()
                    })
                    .catch((err) => {
                        
                        failedToast("Something went wrong.");
                        if (err && err.code === 401) {
                            let newContext = handleErrorFetch(userContext);
                            userContext.userContextUpdater(newContext);
                        }
                        console.log('Error in unlink connection: ', err)
                    }
                    )
                    .finally(() => this.setState({ showDeleteModal: false, selectedRow: null }))
            }

        }

        if (!selectedRow) return "";

        return (
            <div>
                <div className={`modal-backdrop fade ${showRenameModal ? "show d-block" : "d-none"}`}></div>
                <div className={`modal fade ${showRenameModal ? "show d-block" : "d-none"}`} role="dialog" tabIndex="-1"
                    aria-labelledby="Delete_Modal_label" aria-hidden={!showRenameModal} aria-modal={showRenameModal} >
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div id="Delete_Modal_label" className="modal-header p-3">
                                <h4 className="modal-title">Rename {selectedRow["clientName"]} ?</h4>
                                <button type="button" className="close" onClick={handleClose} data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span><span className="sr-only">Close</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container">
                                    <h6 className="">Name</h6>
                                    <p>
                                        Assign a new name to this connection
                                    </p>
                                    <div className="input-group input-group-sm">
                                        <input type="text" defaultValue={selectedRow["clientName"]} ref={this.inputRef} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-primary btn-xs mr-2"
                                        onClick={handleSubmit}>Rename
                                    </button>
                                    <button className="btn btn-outline-secondary btn-xs"
                                        onClick={() => this.setState({ showRenameModal: false, selectedRow: null })}>Dismiss
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(AccountConnections)