import React from "react";

import AppNavbar from "../../AppNavbar";
import {formatLongAbsoluteDate} from "../../../helpers/DateHelper";
import DataTable from "../DataTable";
import {isUserProfileLoaded, isUserSignedIn, UserContext} from "../../../UserContext";
import AccountSignedOutEmptyState from "../../../account/AccountSignedOutEmptyState";
import {Link, Redirect} from "react-router-dom";
import { getNoJson } from "../../../services/APIService";
import { handleApiError } from "../helper/ErrorHelper";
import Tooltip from './Tooltip';
import { getParamsFromUrl, HEADERS, EXTRA_SEARCH_FIELDS, ON_RENDER_CELL } from '../helper/TableHelper';

export default class MainContent extends React.Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            forbidden: false
        }
    }

    componentDidMount() {
        if(!this.props.checkUrl) return;
        getNoJson(this.props.checkUrl)
            .then((response) => {
                if(response.status !== 200){
                    this.props.history.replace({ pathname: `/`});
                }
            })
            .catch((err) => {
                this.props.history.replace({ pathname: `/`});
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            });
    }

    // --------------------------------------------------------------------------------
    // MAIN RENDERING
    // --------------------------------------------------------------------------------

    render() {
        return (
            <UserContext.Consumer>
                {userContext => this.renderMainContent(userContext)}
            </UserContext.Consumer>
        );
    }

    renderMainContent(userContext) {
        const {forbidden} = this.state;

        if (forbidden === true)
            return <Redirect to="/account/dashboard"/>;

        if (!isUserProfileLoaded(userContext))
            return '';

        if (isUserSignedIn(userContext)) {
            return <div>
                <AppNavbar />
                <main id="content" role="main">
                    {this.props.children}
                </main>
            </div>

        } else {
            return <div>
                <AppNavbar />
                <main id="content" role="main">
                    <AccountSignedOutEmptyState />
                </main>
            </div>
        }
    }

}