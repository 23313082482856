import React from "react";

import DataTable from "./DataTable";
import {Link} from "react-router-dom";
import { getParamsFromUrl, HEADERS, EXTRA_SEARCH_FIELDS, ON_RENDER_CELL, QUERY_URL } from './helper/TableHelper';
import { PERMISSION } from "./helper/PermissionHelper";
import { REST_URL_POST } from "./helper/URLHelper";

import TitleTable from "./extra/TitleTable";
import MainContent from "./extra/MainContent";

export default class AdminPostListPage extends React.Component {

    render() {
        return (
            <MainContent history={this.props.history} checkUrl={PERMISSION.POST.url+"view"}>
                <div className="container-fluid space-1 px-4">
                    <TitleTable title="Posts">
                        <div className="ml-auto">
                            <Link to="/admin/post/new" className="btn btn-sm btn-primary ml-2"><i className="fa fa-fw fa-plus mr-1"/>Add</Link>
                        </div>
                    </TitleTable>
                    <DataTable
                        search={true}
                        queryUrl={QUERY_URL.POST}
                        params={getParamsFromUrl(new URLSearchParams(this.props.location.search),QUERY_URL.POST)}
                        extraSearchFields={EXTRA_SEARCH_FIELDS.POST}
                        pagination={true}
                        autoBlockSearch={true}
                        excludePageNumbers={false}
                        resources={`${REST_URL_POST}s`}
                        name="Posts"
                        headers={HEADERS.POST}
                        onRenderCell={ON_RENDER_CELL.POST}
                        onForbidden={() => this.setState({forbidden: true})}
                        entries={true}/>
                </div>
            </MainContent>
        );
    }

}