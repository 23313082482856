import React from "react";
import { isNotEmptyString } from "../../../helpers/JsHelper";


export default class TextField extends React.Component {

    render() {
        const {id, label, mandatory, maxLength, value, attemptedSave, disabled, onChange, className} = this.props;
        const valueLength = value ? value.length : 0;

        const isMissing = attemptedSave && mandatory && !isNotEmptyString(value, true);
        const isTooLong = attemptedSave && maxLength > 0 && valueLength > maxLength;
        return (
            <div className={"form-group " + className}>
                <div className="d-flex w-100 align-items-baseline">
                    <label className="input-label my-0" htmlFor={id}>{label}</label>
                    <span
                        className={`ml-auto small ${isTooLong ? "text-danger" : "text-secondary"}`}>{valueLength}/{maxLength}</span>
                </div>
                <input type="text" id={id}
                    className={`form-control form-control-sm my-0 ${isMissing || isTooLong ? "border-danger" : ""}`}
                    placeholder={disabled ? '' : `Enter ${label}`}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                />
                {isMissing && <div className="text-danger">Please enter the {label}</div>}
                {isTooLong && <div className="text-danger">{label} cannot exceed {maxLength} characters</div>}
            </div>
        );
    }

}