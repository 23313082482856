import { formatSmartAbsoluteDate } from './DateHelper';
import intl from 'react-intl-universal';

function isDown(node) {
    return node.state === 'DOWN';
}

function isUp(node) {
    return node.state === 'UP';
}

function getIpAddress(node) {
    let ipaddr = node.address_list && node.address_list.length ? node.address_list[0] : null;
    if (ipaddr === "0.0.0.0") ipaddr = intl.get('generic_not_in_network');
    return ipaddr;
}

function isHwAddressFake(address) {
    if (address.length !== 6 || address[0] !== 0x02)
        return false;
    for (let i = 1; i < address.length; i++) {
        if (address[i] !== 0)
            return false;
    }
    return true;
}

function getBestIcon(name) {
    let icon = require("../assets/icons/dt_generic_lg.png");
    if (name) {
        icon = require("../assets/icons/dt_" + name.toLowerCase() + "_lg.png");
    }
    return icon;
}

function getLastOnlineToDownTime(node) {
    if (!isDown()) return node.statechangetime;
    const maxSeenTime = Math.max(node.lastseentime, node.lastinrangetime); // TOCHECK
    if (maxSeenTime === 0) return node.statechangetime;
    return maxSeenTime;
}

function getSharpStateChangeTime(node) {
    if (!isDown(node)) return node.statechangetime;
    else return getLastOnlineToDownTime(node);
}

function toPrivacyString(address, privacyMode) {
    let res = "";

    let startPrivacy;
    if (privacyMode) {
        startPrivacy = (address.length > 3 ? address.length - 3 : 0);
    } else {
        startPrivacy = address.length;
    }

    for (let i = 0; i < address.length; ++i) {
        if (i < startPrivacy) {
            let strPart = (parseInt(address[i]) & 0xFF).toString(16).toUpperCase();
            if (strPart.length < 2) strPart = "0" + strPart;
            res = res + strPart;
        } else {
            res = res + "XX";
        }
        if (i < (address.length - 1)) res = res + (':');
    }
    return res;
}


function bestMake(node) {
    if (node.best_make)
        return node.best_make;
    if (node.mac_vendor)
        return node.mac_vendor;
    return null;
}

function bestMakeAndModelAsArray(node) {
    if (!node || !node.best_make)
        return [];

    // const make = getBestMake();
    let make = node.best_make;
    let model = node.best_model;
    if (make && model) {
        return [make, model];
    } else if (model) {
        return [model];
    } else if (make) {
        return [make];
    } else {
        return [node.mac_vendor];
    }
}

function _getByteSize() {
    return 4;
}

function ipAddressParse(input) {
    const arrayParts = input.split(".");
    if (arrayParts.length !== 4) return null;
    const arrayByteAddress = [];
    for (let i=0; i<4; ++i) {
        try {
            arrayByteAddress[i] = (parseInt(arrayParts[i])&0xFF);
        } catch (err) {
            return null;
        }
    }
    return arrayByteAddress;
}

function toSplittedStringPrefix(address, netPrefixLength) {
    if (!address) return ""
    const showPrefixBytes = parseInt(netPrefixLength / 8);
    let res = "";
    for (let i = 0; i < showPrefixBytes && i < address.length; ++i) {
        let stonk = (parseInt(address[i]) & 0xFF)
        res += stonk
        if (i < (_getByteSize() - 1)) res += '.';
    }
    return res.toString();
}

function toSplittedStringSuffix(address, netPrefixLength) {
    if (!address) return ""
    const skipPefixBytes = parseInt(netPrefixLength / 8);
    let res = "";
    for (let i = 0; i < _getByteSize(); ++i) {
        if (i < skipPefixBytes) continue;
        res += (parseInt(address[i]) & 0xFF)
        if (i < (_getByteSize() - 1)) res += '.';
    }
    return res.toString();
}

function bestModel(node, privacyMode) {
    if (node.best_model)
        return node.best_model;
    if (node.best_os)
        return node.best_os;
    if (node.mac_address && isHwAddressFake(node.mac_address))
        return toPrivacyString(node.mac_address, privacyMode);
    return null;
}

function stateChangeTime(node) {
    const when = getSharpStateChangeTime(node);
    if (when > 0)
        return formatSmartAbsoluteDate(when) + " ago";
    return null;
}

// public IconType getBestIconType() {
//     if (agent) return IconType.FINGBOX;
//     if (iconType!= IconType.UNDEFINED) return iconType;

//     if (!getDeviceIdentifiers().isEmpty()) {
//         ArrayList<DeviceIdentifier> sorted = new ArrayList<>(getDeviceIdentifiers());
//         Collections.sort(sorted, DevIdBestDevTypeComparator);
//         DeviceIdentifier bestPick = sorted.get(0);
//         if (bestPick.getDeviceType() != null) return IconType.parse(bestPick.getDeviceType());
//     }
//     return IconType.UNDEFINED;
// }

// public IconType getBestIconTypeOrGeneric() {
//     IconType iconType = getBestIconType();
//     return iconType != IconType.UNDEFINED ? iconType : IconType.GENERIC;
// }


export default {
    getIpAddress,
    isHwAddressFake,
    isDown,
    isUp,
    getBestIcon,
    toPrivacyString,
    getSharpStateChangeTime,
    bestMake,
    bestMakeAndModelAsArray,
    bestModel,
    stateChangeTime,
    toSplittedStringPrefix,
    toSplittedStringSuffix,
    ipAddressParse
}