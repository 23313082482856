import React from "react";

import {
    get,
    put,
    remove
} from '../../services/APIService';
import {ToastContainer} from "react-toastify";
import {failedToast, successToast} from "../../helpers/ToastHelper";
import ModalDialog, {hideDialogById, ModalDialogBody, ModalDialogFooter, showDialogById} from "../dialog/ModalDialog";
import {Link} from "react-router-dom";
import {
    convertDateToState,
} from './helper/ConversionHelper';
import { controlMandatoryFields, controlMaxLengthFields } from "./helper/ControlHelper";
import { handleApiError } from "./helper/ErrorHelper";
import { TITLES } from './helper/TitlesHelper';
import { MANDATORY } from './helper/MandatoryHelper';
import { COPY } from './helper/CopyHelper';
import { DISABLED } from './helper/DisableHelper';
import { MAX_LENGTH } from './helper/MaxLengthHelper';
import { checkAllPermissions, PERMISSION } from "./helper/PermissionHelper";
import { REST_URL_CONNECTION } from "./helper/URLHelper";
import TableDataField from "./fields/TableDataField";
import TextField from "./fields/TextField";

import CardTable from "./extra/CardTable";
import MainContent from "./extra/MainContent";
import EventComponent from "./extra/EventComponent";

const maxLengths = MAX_LENGTH.CONNECTION;

const mandatory = MANDATORY.CONNECTION;

const disabled = DISABLED.CONNECTION;

const copyable = COPY.CONNECTION;

const titles = TITLES.CONNECTION;

const UNLINK_DIALOG = "UNLINK_DIALOG";

const permissionTypes = PERMISSION.CONNECTION.types;
const permissionUrl = PERMISSION.CONNECTION.url;
export default class AdminConnectionEditPage extends EventComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            attemptedSave: false,
            forbidden: false,
            loading: true
        };
    }

    componentDidMount() {
        checkAllPermissions(permissionUrl,permissionTypes).then(result=> {
            const state = this.state;
            for(const key in result){
                state['permission_'+key] = result[key];
            }
            this.setState({...state},this.initWithConnId())
        }).catch((err) => {
            this.props.history.replace({ pathname: `/`});
            handleApiError(err, this.context, () => this.setState({forbidden: true}));
        });
    }

    initWithConnId() {
        if(this.state.permission_view !== null && this.state.permission_view === false) 
            this.props.history.replace({ pathname: `/`});
        let connId = this.props.match.params.connId;
        get(`${REST_URL_CONNECTION}?token=${connId}`)
            .then((a) => {
                this.convertFullConnToState(a);
            })
            .catch((err) => {
                failedToast("Unable to read connection");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    convertFullConnToState(response) {
        const newState = this.state;
        if (response) {
            const conn = response;
            newState.userId = conn.userId;
            newState.clientId = conn.clientId;
            newState.clientName = conn.clientName;
            newState.clientType = conn.clientType;
            newState.clientVersion = conn.clientVersion;
            newState.clientPlatform = conn.clientPlatform;
            if(conn.firstAccess)
                newState.firstAccess = convertDateToState(new Date(conn.firstAccess));
            if(conn.lastAccess)
                newState.lastAccess = convertDateToState(new Date(conn.lastAccess));
            newState.lastAccessAddress = conn.lastAccessAddress;
            newState.loginProvidernotificationToken = conn.loginProvidernotificationToken;
            newState.apnsToken = conn.apnsToken;
        }
        this.setState(newState);
    }

    // --------------------------------------------------------------------------------
    // MAIN RENDERING
    // --------------------------------------------------------------------------------

    render() {
        const onSave = ()=>this.saveConn();
        const onUnlink = () => showDialogById(UNLINK_DIALOG);
        
        return (
            <MainContent history={this.props.history}>
                <div className="container-fluid space-1 px-4">
                    <div className="d-flex border-bottom mb-6">
                        <div>
                            <h2 className="d-inline-block">Edit Connection</h2>
                            <span className="text-secondary mx-2">/</span>
                            <Link to="/admin/connections">Back to Connections</Link>
                        </div>
                        <div className="ml-auto">
                            {this.state.permission_delete && <button className="btn btn-sm btn-danger mr-2" onClick={onUnlink}>Unlink</button>}
                            {this.state.permission_edit && <button className="btn btn-sm btn-primary" onClick={onSave}>Save</button>}
                        </div>
                    </div>
                    <ToastContainer/>
                    {this.renderForm()}
                    {this.renderData()}
                    {this.renderUnlinkDialog()}
                </div>
            </MainContent>
            
        );
    }

    unlinkClient() {
        let connId = this.props.match.params.connId;
        remove(`${REST_URL_CONNECTION}?token=${connId}`)
            .then((a) => {
                if (a) {
                    successToast("Client unlinked");
                    this.props.history.replace({pathname: `/admin/connections`});
                }
            })
            .catch((err) => {
                failedToast("Unable to unlink client");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }
    
    saveConn() {

        // === VALIDATE ===
        if(!controlMandatoryFields(this.state,mandatory,this.setState({attemptedSave: true}))) 
            return
        if(!controlMaxLengthFields(this.state,maxLengths,this.setState({attemptedSave: true})))
            return
        
        // === SEND ===

        let connId = this.props.match.params.connId;
        
        const body = {
            clientName: this.state.clientName,
        }

        this.setState({loading: true, attemptedSave: false});
        put(`${REST_URL_CONNECTION}?token=${connId}`, body)
            .then((a) => {
                if (a) {
                    successToast("Connection saved");
                    this.convertFullConnToState(a);
                    if (connId) {
                        this.props.history.replace({ pathname: `/admin/connection/${connId}`})
                    }
                }
            })
            .catch((err) => {
                failedToast("Unable to save connection");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    // -------------------------------------------------------------------------------------------------

    renderData() {
        return <>
            <h3>About this connection</h3>
            <div className="row">
                <div className="col-xs-12 col-md-6 col-sm-12 mb-2">
                    <CardTable title="Client info" className="h-100">
                        {this.renderDataField("userId")}
                        {this.renderDataField("clientId")}
                        {this.renderDataField("clientType")}
                        {this.renderDataField("clientVersion")}
                        {this.renderDataField("clientPlatform")}
                    </CardTable>
                </div>
                <div className="col-xs-12 col-md-6 col-sm-12 mb-2">
                    <CardTable title="Access and Tokens info" className="h-100">
                        {this.renderDataField("firstAccess")}
                        {this.renderDataField("lastAccess")}
                        {this.renderDataField("lastAccessAddress")}
                        {this.renderDataField("loginProvidernotificationToken")}
                        {this.renderDataField("apnsToken")}
                    </CardTable>
                </div>
                
            </div>
        </>
    }

    renderForm() {
        return (
            <form>
                <div className="row">
                    <div className="col-6">
                        {this.renderTextField("clientName")}
                    </div>
                </div>
            </form>
        );
    }

    renderDataField(field) {
        return <TableDataField value={this.state[field] || ''} label={titles[field]} copyable={copyable[field]}/>
    }

    renderTextField(id, dis) {
        return <TextField
            id={id}
            className="mb-3"
            value={this.state[id] || ''}
            attemptedSave={this.state.attemptedSave}
            label={titles[id]}
            mandatory={mandatory[id] === true}
            maxLength={maxLengths[id] || 0}
            onChange={this.handleInputFieldChange}
            disabled={dis || disabled[id]}/>
    }

    renderUnlinkDialog() {
        const onClose = () => {
            hideDialogById(UNLINK_DIALOG);
        }
        const onUnlink = () => {
            hideDialogById(UNLINK_DIALOG);
            this.unlinkClient();
        };

        const clientName = this.state.clientName;
        return <ModalDialog id={UNLINK_DIALOG} title={"Unlink " + clientName} onClose={onClose}>
            <ModalDialogBody className="py-3">
                {clientName} will be unlinked from your Fing account.  
                You will need to re-enter your email and password on that device to connect it again. 
                <br/>
                <br/>
                Do you want to proceed?
            </ModalDialogBody>
            <ModalDialogFooter className="py-3">
                <button className="btn btn-xs btn-ghost-danger" onClick={onClose}>Dismiss</button>
                <button className="btn btn-xs btn-primary" onClick={onUnlink}>Unlink</button>
            </ModalDialogFooter>
        </ModalDialog>;
    }

}