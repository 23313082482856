import React, { Component } from "react";
import intl from 'react-intl-universal';
import { Link } from "react-router-dom";


import AppNavbar from "../InternetNavbar";
import AppFooter from "../../components/AppFooter";

import { renderMetaTagsWithDefImg } from "../../helpers/MetaTagsHelper";
import { formatDuration } from '../../helpers/DateHelper';
import { formatPercentage } from '../../helpers/JsHelper';

import { getCountryName } from "../place/GeoCountries";
import { linkForPlace } from "../LinkHelper";
import OutageSeverityIndicator from "../isp/OutageSeverityIndicator";
import OutageMap from "./OutageMap";


export default class TopOutageReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            topOutages: [],
            totDays: 30,
            continentFilter: null
        };
    }

    validateRestResponse(response) {
        if (response.status !== 200) {
            throw Error(response.statusText);
        }
        return response;
    }

    catchRestError(response) {
        this.setState({ recentOutages: [], isLoading: false, isFound: false });
    }

    componentDidMount() {
        this.mainFetchSequence();
    }

    mainFetchSequence() {

        let days = this.props.match.params.days;
        if (!days || days === "") days = "30";

        let cnt = this.props.match.params.continent;
        if (!cnt || cnt === "") cnt = "all";

        return fetch('/rest/internet/topoutages/' + cnt + '?days=' + days)
            .then(response => this.validateRestResponse(response))
            .then(response => response.json())
            .then(data => {
                if (data.tops && data.tops.length > 0) {
                    this.setState({ topOutages: data.tops, totDays: data.days, continentFilter: data.where });

                } else {
                    this.setState({ topOutages: [], totDays: 30, continentFilter: null });

                }
            })
            .catch(e => this.catchRestError(e));
    }

    // --------------------------------------------------------------------------------
    // MAIN RENDERING
    // --------------------------------------------------------------------------------

    render() {
        return (
            <div>
                {this.renderMetaTags()}

                <AppNavbar />

                <main id="content" role="main">
                    <div>
                        <div className="container space-2">
                            {this.renderTopOutageSection()}
                        </div>
                    </div>

                </main>

                <AppFooter />
            </div>
        );
    }

    // --------------------------------------------------------------------------------
    // Render Outage carousel
    // --------------------------------------------------------------------------------

    renderMetaTags() {
        return renderMetaTagsWithDefImg(intl.get('metatag_outage_report_title'), intl.get('metatag_outage_report_descr'));
    }

    renderTopOutageSection() {
        if (this.state.topOutages.length === 0) {
            return <section />
        }

        let where = "";
        if (this.state.continentFilter) {
            where = " in " + this.state.continentFilter;
        }

        return (
            <section>
                <div className="w-md-100 w-lg-100 text-center mx-md-auto mb-8">
                    <h1 className="text-primary">{intl.getHTML('top_outage_title', { days: this.state.totDays, where: where })}</h1>
                </div>
                <div>
                    {this.renderTopOutageCards()}
                </div>
            </section>
        );
    }

    renderTopOutageCards() {
        let outages = this.state.topOutages.slice(0);    // Clone
        return outages.map(outageGroup => this.renderOutageGroup(outageGroup));
    }

    renderOutageGroup(outageGroup) {
        let foutage = outageGroup.reports[0];

        let isp = foutage.mainDimensionIspValue;
        // let region = foutage.mainDimensionRegionValue;
        let country = foutage.mainDimensionCountryValue;
        let countryFlag = foutage.countryFlag;
        let ispLink = '/provider/' + isp + '/' + getCountryName(country);
        let placeLink = linkForPlace(getCountryName(country), null);

        return (<div className="row border-bottom pb-5 mt-2 mb-10">

            <div className="col-lg-4 text-center align-middle">

                <div className="avatar avatar-xl mx-auto mb-3 mt-3">
                    <img className="avatar-img img-fluid rounded-square" src={foutage.imageUrl} alt={foutage.mainDimensionIspBestName + ' logo'} />
                </div>
                <div>
                    <h1 className="h3 mb-0"><Link to={ispLink} className="link-underline">{foutage.mainDimensionIspBestName}</Link></h1>
                    <h5><Link to={placeLink} className="link-underline">{countryFlag} {getCountryName(country)}</Link></h5>
                </div>
            </div>


            <div className="col-lg-8 mb-9 mb-lg-0">
                <OutageMap
                    outages={outageGroup.reports}
                />
            </div>


            <table className="table table-borderless table-striped my-5" width="60%">
                <thead>
                    <tr role="row">
                        <th><small className="fa fa-exclamation-triangle mr-1" />{intl.get('outage_severity')}</th>
                        <th><small className="fas fa-map mr-1" />{intl.get('outage_where')}</th>
                        <th><small className="fas fa-calendar-alt mr-1" />{intl.get('outage_when')}</th>
                        <th><small className="far fa-clock mr-1" />{intl.get('outage_duration')}</th>
                        <th><small className="fas fa-users mr-1" />{intl.get('outage_impact')}</th>
                        <th><small className="fas fa-bookmark mr-1" />{intl.get('outage_root_cause')}</th>
                    </tr>
                </thead>
                <tbody>
                    {outageGroup.reports.map((outage, index) => this.renderOutageRow(outage, index + 1, (index % 2 === 0)))}
                </tbody>
            </table>

        </div>
        );


    }

    renderOutageRow(item, rowid, isOdd) {

        // let isp = item.mainDimensionIspValue;
        // let region = item.mainDimensionRegionValue;
        // let country = item.mainDimensionCountryValue;
        let place = item.mainDimensionRegionValue ? item.mainDimensionRegionValue : getCountryName(item.mainDimensionCountryValue);
        // let countryFlag = item.countryFlag;
        let ispLink = '/outage/' + item.reportId; // '/provider/'+isp+'/'+getCountryName(country)+(region?'/'+region:'');
        // let placeLink = linkForPlace(getCountryName(country), region);
        let diffMsecs = (item.endTime - item.startTime);
        let impactScore = item.severityNum * 20;

        let impactAssessment = "";
        if (item.parentImpactPerc > 0 && item.mainDimensionIspValue) {
            impactAssessment = intl.get('top_outage_impact', { percentage: formatPercentage(item.parentImpactPerc), place: place });
        } else {
            impactAssessment = intl.get('top_outage_impact', { percentage: formatPercentage(item.impactPerc), place: item.mainDimensionIspBestName });
        }

        let rowClass = isOdd ? "odd" : "even";

        let reportLinkText = item.rootCause ? item.rootCause : "Provider";

        return (
            <tr role="row" key={rowid} className={rowClass}>
                <td><Link to={ispLink}><OutageSeverityIndicator score={impactScore} /></Link></td>
                <td>{place}</td>
                <td>{item.localFullDateTimeLong}</td>
                <td>{formatDuration(diffMsecs, false)}</td>
                <td>{impactAssessment}</td>
                <td><Link to={ispLink}>{reportLinkText}</Link></td>
            </tr>
        );

    }



}