const data = require('./countries.json');

let nameMap = {};
let codeMap = {};
data.forEach(mapCodeAndName);

function mapCodeAndName (country) {
    nameMap[country.name.toLowerCase()] = country;
    codeMap[country.code.toLowerCase()] = country;
}

export function getCountryCode(name) {
    return nameMap[name.toLowerCase()].code;
}

export function getCountryName(code) {
    return codeMap[code.toLowerCase()].name;
}

export function getCountryCode3(code) {
    return codeMap[code.toLowerCase()].code3;
}

export function getCountryObject(code) {
    return codeMap[code.toLowerCase()];
}

export function getCountryList() {
    return data;
}
