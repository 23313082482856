import React from "react";

import DataTable from "./DataTable";
import { getParamsFromUrl, HEADERS, EXTRA_SEARCH_FIELDS, ON_RENDER_CELL, QUERY_URL } from './helper/TableHelper';
import { PERMISSION } from "./helper/PermissionHelper";
import { REST_URL_USER } from "./helper/URLHelper";


import TitleTable from "./extra/TitleTable";
import MainContent from "./extra/MainContent";

export default class AdminUserListPage extends React.Component {

    render() {
        return (
            <MainContent history={this.props.history} checkUrl={PERMISSION.USER.url+"view"}>
                <div className="container-fluid space-1 px-4">
                    <TitleTable
                        title="Users"
                        info="Search will look into User ID">
                        <div className="ml-auto">
                            <a 
                                href="/app#admin:"
                                className="btn btn-sm btn-soft-warning">Previous version</a>
                        </div>
                    </TitleTable>
                    <DataTable
                        queryUrl={QUERY_URL.USER}
                        params={getParamsFromUrl(new URLSearchParams(this.props.location.search),QUERY_URL.USER)}
                        search={true}
                        excludePageNumbers={true}
                        pagination={true}
                        resources={`${REST_URL_USER}/list`}
                        autoBlockSearch={true}
                        extraSearchFields={EXTRA_SEARCH_FIELDS.USER}
                        name="Users"
                        headers={HEADERS.USER}
                        onRenderCell={ON_RENDER_CELL.USER}
                        onForbidden={() => this.setState({forbidden: true})}
                        entries={true}/>
                </div>
            </MainContent>
        );
    }

}