import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';


import AppNavbar from './InternetNavbar';
import AppFooter from '../components/AppFooter';
import { SessionContext } from "../SessionContext";

import { renderDefaultMetaTags } from "../helpers/MetaTagsHelper";

import HomePreferredIsp from "./HomePreferredIsp";
import { linkForPlace } from "./LinkHelper";
import { getCountryName } from "./place/GeoCountries";
import PlaceCarousel from "./place/PlaceCarousel";
import IspCardSummary from "./isp/IspCard";
import IspCardOutage from "./outage/OutageCard";

class Home extends Component {

    static contextType = SessionContext;

    constructor(props) {
        super(props);
        this.state = {
            countryCode: 'GB',
            session: null,
            topIspInCountry: [],
            recentOutages: []
        };
    }

    validateRestResponse(response) {
        if (response.status !== 200) {
            throw Error(response.statusText);
        }
        return response;
    }

    catchRestError(response) {
        this.setState({ item: {}, isLoading: false, isFound: false });
    }

    componentDidMount() {
        if (this.context.session && this.context.session.currentIsp) {
            this.setState({ session: this.context.session, countryCode: this.context.session.currentIsp.countryCode });
            this.mainFetchSequence(this.context.session.currentIsp);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.session && prevState.session.currentIsp) {
            return;
        }

        if (this.context.session && this.context.session.currentIsp) {
            this.setState({ session: this.context.session, countryCode: this.context.session.currentIsp.countryCode });
            this.mainFetchSequence(this.context.session.currentIsp.countryCode);
        }
    }

    mainFetchSequence(countryCode) {
        return fetch('/rest/internet/isps/' + countryCode + '?top=6')
            .then(response => this.validateRestResponse(response))
            .then(response => response.json())
            .then(data => {
                this.setState({ topIspInCountry: data });
                return fetch('/rest/internet/outages/all?top=12');
            })
            .then(response => this.validateRestResponse(response))
            .then(response => response.json())
            .then(data => {
                if (data.reports && data.reports.length > 0) {
                    this.setState({ recentOutages: data.reports });
                }

                if (typeof window.initSlickCarousel === "function") {
                    window.initSlickCarousel();
                }
            })
            .catch(e => this.catchRestError(e));
    }

    // --------------------------------------------------------------------------------
    // MAIN RENDERING
    // --------------------------------------------------------------------------------

    render() {

        return (
            <div>
                {renderDefaultMetaTags()}

                <AppNavbar />

                <main id="content" role="main">

                    {this.state.session && <HomePreferredIsp session={this.state.session} />}

                    <div className="container space-2">
                        {this.renderTopProvidersInCountrySection()}
                    </div>

                    {
                        this.state.session && this.state.session.suggestedPlaces &&
                        <div className="position-relative bg-light overflow-hidden">
                            <div className="container space-2">
                                {this.renderBestCountrySection(this.state.session.suggestedPlaces)}
                            </div>
                        </div>
                    }

                    <div>
                        <div className="container space-2">
                            {this.renderRecentOutageSection()}
                        </div>
                    </div>

                </main>

                <AppFooter />
            </div>
        );
    }

    // --------------------------------------------------------------------------------
    // Render ISP carousel
    // --------------------------------------------------------------------------------

    renderTopProvidersInCountrySection() {
        if (this.state.topIspInCountry.length === 0)
            return (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );

        const countryCode = this.state.countryCode !== '' ? this.state.countryCode : 'GB';
        const countryName = getCountryName(countryCode);

        return (
            <section>
                <div className="w-md-80 w-lg-80 text-center mx-md-auto mb-9">
                    <h2 className="text-primary">{intl.getHTML('home_top_providers_title', { countryName: countryName })}</h2>
                    <p>{intl.get('home_top_providers_subtitle')}</p>
                    <Link className="btn btn-soft-primary btn-wide transition-3d-hover"
                        to={linkForPlace(countryName)}>{intl.get('home_top_providers_action')}</Link>
                </div>
                <div className="js-slick-carousel slick slick-equal-height slick-gutters-3 mb-7"
                    data-hs-slick-carousel-options='{
                    "prevArrow": "<span class=\"fas fa-arrow-left slick-arrow slick-arrow-primary slick-arrow-left slick-arrow-offset slick-arrow-centered-y rounded-circle \"></span>",
                    "nextArrow": "<span class=\"fas fa-arrow-right slick-arrow slick-arrow-primary slick-arrow-right slick-arrow-offset slick-arrow-centered-y rounded-circle \"></span>",
                    "slidesToShow": 3,
                    "dots": true,
                    "dotsClass": "slick-pagination mt-3",
                    "responsive": [{
                        "breakpoint": 992,
                               "settings": {
                        "slidesToShow": 2
                               }
                             }, {
                        "breakpoint": 768,
                               "settings": {
                        "slidesToShow": 1
                               }
                             }]
                  }'
                >
                    {this.renderBestIspsInCountryCards()}

                </div>
            </section>
        );
    }

    renderBestIspsInCountryCards() {
        return this.state.topIspInCountry.map(isp => this.renderIspCard(isp));
    }

    renderIspCard(isp) {
        return (
            <IspCardSummary
                className="js-slide card card-frame shadow-sm transition-3d-hover mt-1 mb-3"
                isp={isp}
            />
        );
    }

    // --------------------------------------------------------------------------------
    // Render Country carousel
    // --------------------------------------------------------------------------------

    renderBestCountrySection(itemList) {
        if (itemList.length === 0)
            return <section />;

        return (
            <section>
                <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-9 space-top-2">
                    <span className="btn btn-xs btn-soft-success btn-pill mb-2">{intl.get('home_top_countries_badge')}</span>
                    <h2 className="text-primary">{intl.getHTML('home_top_countries_title')}</h2>
                    <p>{intl.get('home_top_countries_subtitle')}</p>
                </div>

                <PlaceCarousel items={itemList}
                    makeTitle={place => getCountryName(place.countryCode)}
                    makeLink={place => linkForPlace(getCountryName(place.countryCode))} />
            </section>
        );
    }

    // --------------------------------------------------------------------------------
    // Render Outage carousel
    // --------------------------------------------------------------------------------

    renderRecentOutageSection() {
        if (this.state.recentOutages.length === 0) {
            return <section />
        }

        return (
            <section>
                <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-9">
                    <h2 className="text-primary">{intl.getHTML('home_recent_outages_title')}</h2>
                    <p>{intl.get('home_recent_outages_subtitle')}</p>
                    <Link className="btn btn-soft-primary btn-wide transition-3d-hover"
                        to={'/outages'}>{intl.get('home_recent_outages_action')}</Link>
                    <span> </span>
                    <Link className="btn btn-soft-primary btn-wide transition-3d-hover"
                        to={'/outages/live'}>{intl.get('home_live_outages_action')}</Link>
                    <span> </span>
                    <Link className="btn btn-soft-primary btn-wide transition-3d-hover"
                        to={'/outages/top-report'}>{intl.get('home_top_outages_action')}</Link>
                </div>

                <div className="js-slick-carousel slick slick-equal-height slick-gutters-3 mb-7"
                    data-hs-slick-carousel-options='{
                    "prevArrow": "<span class=\"fas fa-arrow-left slick-arrow slick-arrow-primary slick-arrow-left slick-arrow-offset slick-arrow-centered-y rounded-circle \"></span>",
                    "nextArrow": "<span class=\"fas fa-arrow-right slick-arrow slick-arrow-primary slick-arrow-right slick-arrow-offset slick-arrow-centered-y rounded-circle \"></span>",
                    "slidesToShow": 3,
                    "dots": true,
                    "dotsClass": "slick-pagination mt-3",
                    "responsive": [{
                        "breakpoint": 992,
                               "settings": {
                        "slidesToShow": 2
                               }
                             }, {
                        "breakpoint": 768,
                               "settings": {
                        "slidesToShow": 1
                               }
                             }]
                  }'
                >

                    {this.renderRecentOutageCards()}
                </div>
            </section>
        );
    }

    renderRecentOutageCards() {
        let outages = this.state.recentOutages.slice(0);    // Clone
        return outages.map(outage => this.renderOutageCard(outage));
    }

    renderOutageCard(outage) {
        return <IspCardOutage
            className="js-slide card card-frame shadow-sm mt-1 mb-3"
            outage={outage} />;
    }

}

export default Home;