import React from "react";
import Select from "react-select";
import { isNotEmptyString } from "../../../helpers/JsHelper";
import { reactSelectStyles } from "../helper/SelectHelper";

// labelSpan, selectSpan -> How many columns takes the cell?

export default class TableEnumField extends React.Component {

    render() {
        const {label, value, mandatory, isMulti, enumValues, attemptedSave, id, labelSpan, selectSpan, onChange} = this.props;

        const isMissing = attemptedSave && mandatory === true && (!value || !isNotEmptyString(value.value, true));
        const colorText = isMissing ? "text-danger" : "text-dark";
        return (
            <tr>
                <td className="p-2" colspan={labelSpan || 1}><span className={colorText}>{label}</span></td>
                <td className="p-2" colspan={selectSpan || 1} style={{maxWidth:400}}>
                    <Select
                        name={id}
                        isMulti={isMulti || false}
                        isSearchable={false}
                        styles={reactSelectStyles}
                        placeholder={`${label}`}
                        value={value}
                        onChange={onChange}
                        options={enumValues}
                    />
                </td>
            </tr>
        );
    }

}