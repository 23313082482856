export const MULTIPLE = {
    IAB: {
        platforms: true,
        'account-types': true,
        'tech-attitude': true,
        'purchase-states':true
    },
    IAM: {
        platforms: true,
        'account-types': true,
        'tech-attitude': true,
        'purchase-states':true
    },
    USER: {
        userRoles: true
    }
}