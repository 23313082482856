import { formatLongAbsoluteDate } from "../../../helpers/DateHelper";
import { ENUMS } from "./EnumHelper";
import React from "react";
import { Link } from "react-router-dom";
import { showDialogById } from "../../dialog/ModalDialog";

const ANY = { value: "", label: "ANY", badge: ''};

export function getParamsFromUrl(query, values){
    let params = {};
    for(const value of values){
        if(query.has(value)){
            params[value] = query.get(value);
        }
    }
    return params;
}

function renderSingleBadgeCell(values, val){
    return <td className="p-2">{values.find(v => v.value === val) ? values.find(v => v.value === val).badge : val}</td>
}

function renderMultipleBadgeCell(values, val){
    if(val.includes(',')){
        return <td className="p-2">{val.split(',').map(val => values.find(v => v.value === val).badge)}</td>
    }
    return renderSingleBadgeCell(values, val)
}

function renderMultipleBadgeCellUsingArray(values, val){
    if(val.length > 0){
        return <td className="p-2">{val.map(val => values.find(v => v.value === val).badge)}</td>
    }
    return ''
}

function renderDateCell(value){
    return <td className="p-2">{value && formatLongAbsoluteDate(new Date(value).getTime())}</td>
}

function renderTextCell(value){
    return <td className="p-2">{value}</td>
}

export const QUERY_URL = {
    IN_APP:['platform', 'purchaseState', 'accountType'],
    AGENT:['state','connectionState', 'search'],
    CONNECTION:['clientType', 'search'],
    DISCOUNT_OFFER:['discountPlatform', 'search'],
    POST:['category', 'format', 'state', 'search'],
    USER:['accountType', 'search'],
    VALIDATION:['validationState', 'search'],

}

export const EXTRA_SEARCH_FIELDS = {
    IN_APP: [
        {param: "platform", label: "Platform", type: "enum", enumValues: [...ENUMS.MESSAGE.PLATFORM,ANY]},
        {param: "purchaseState", label: "Purchase State", type: "enum", enumValues: [...ENUMS.MESSAGE.PURCHASE_STATE,ANY]},
        {param: "accountType", label: "Account Type", type: "enum", enumValues: [...ENUMS.MESSAGE.ACCOUNT_TYPE,ANY]},
    ],
    AGENT: [
        {param: "state", label: "State", type: "enum", enumValues: ENUMS.AGENT.STATE},
        {param: "connectionState", label: "Connection State", type: "enum", enumValues: ENUMS.AGENT.CONNECTION}
    ],
    CONNECTION: [
        {param: "clientType", label: "Client Type", type: "enum", enumValues: ENUMS.CONNECTION.TYPE},
        {param: "lastAccess", label: "Last Update", type: "date", range: true}
    ],
    DISCOUNT_OFFER: [
        {param: "discountPlatform", label: "Discount Platform", type: "enum", enumValues: ENUMS.DISCOUNT_OFFER.PLATFORM, multiple: true},
        {param: "startDate", label: "Start Date", type: "date", range: true},
        {param: "endDate", label: "End Date", type: "date", range: true}
    ],
    POST: [
        {param: "category", label: "Category", type: "enum", enumValues: ENUMS.POST.CATEGORY, multiple: true},
        {param: "format", label: "Format", type: "enum", enumValues: ENUMS.POST.FORMAT, multiple: true},
        {param: "state", label: "State", type: "enum", enumValues: ENUMS.POST.STATE, multiple: true}
    ],
    USER:[
        {param: "accountType", label: "Account", type: "enum", enumValues: 
            [...ENUMS.USER.ACCOUNT_TYPE,{ value: "", label: "ANY", badge: ''}]
        }
    ],
    VALIDATION:[
        {param: "validationState", label: "ValidationState", type: "enum", enumValues: ENUMS.VALIDATION.STATE, multiple: true},
        {param: "creationDate", label: "Creation Date", type: "date", range: true},
        {param: "termsAcceptanceDate", label: "Terms Acceptance Date", type: "date", range: true}
    ]
}

export const HEADERS = {
    SUBSCRIPTION:[
        {name: "platform", displayField: "Platform"},
        {name: "state", displayField: "State"},
        {name: "periodStartDate", displayField: "Start Date"},
        {name: "periodEndDate", displayField: "End Date"},
        {name: "purchaseDate", displayField: "Purchase Date"},
        {name: "lastUpdateTime", displayField: "Last Update"}
    ],
    in_app: (type) => [
        {name: "switch", displayField: ""},
        {name: "id", displayField: type === 'IAB' ? "Banner ID" : 'Message ID'},
        {name: "layout", displayField: "Layout"},
        {name: "platform", displayField: "Platform"},
        {name: "accountType", displayField: "Account Types"},
        {name: "purchaseState", displayField: "Purchase States"},
        {name: "action", displayField: ""}
    ],
    AGENT:[
        {name: "agentId", displayField: "Agent ID", sortable: true},
        {name: "state", displayField: "State"},
        {name: "connectionState", displayField: "Connection State"},
        {name: "userId", displayField: "User ID", sortable: true},
        {name: "shardId", displayField: "Shard ID", sortable: true},
        {name: "customName", displayField: "Name", sortable: true},
        {name: "firstActivationDate", displayField: "Activation", sortable: true},
        {name: "hardwarePlatform", displayField: "Hardware", sortable: true},
        {name: "agentVersion", displayField: "Version", sortable: true},
        {name: "fingboxType", displayField: "Type", sortable: true},
        {name: "agentChannel", displayField: "Channel", sortable: true},
        {name: "action", displayField: ""}
    ],
    CONNECTION:[
        { name: "userId", displayField: "User ID", sortable: true, width:"150px"},
        { name: "clientId", displayField: "Client ID", sortable: true, width:"150px"},
        { name: "clientName", displayField: "Client name", sortable: true, width:"200px"},
        { name: "clientVersion", displayField: "Client version", sortable: true, width:"100px"},
        { name: "clientPlatform", displayField: "Client platform", sortable: true, width:"200px"},
        { name: "firstAccess", displayField: "First access", sortable: true, width:"100px"},
        { name: "lastAccess", displayField: "Last access", sortable: true, width:"100px"},
        { name: "action", displayField: ""}
    ],
    DISCOUNT_OFFER:[
        { name: "discountId", displayField: "Discount ID", sortable: true },
        { name: "description", displayField: "Description", sortable: true },
        { name: "startDate", displayField: "Start Date", sortable: true },
        { name: "endDate", displayField: "End Date", sortable: true },
        { name: "enabled", displayField: "Enabled"},
        { name: "allowedPlans", displayField: "Plans", sortable: true},
        { name: "allowedCountries", displayField: "Countries", sortable: true},
        { name: "purchaseRules", displayField: "Purchase Rules", sortable: true },
        { name: "discountPlatform", displayField: "Discount Platform", sortable: true },
        { name: "actions", displayField: ""}
    ],
    POST: [
        { name: "previewImageUrl", displayField: "Image", sortable: false },
        { name: "title", displayField: "Title", sortable: true },
        { name: "description", displayField: "Description", sortable: true },
        { name: "category", displayField: "Category", sortable: true },
        { name: "format", displayField: "Format", sortable: true },
        { name: "state", displayField: "State", sortable: true },
        { name: "publishDate", displayField: "Published", sortable: true },
        { name: "insertDate", displayField: "Inserted", sortable: true },
        { name: "openCount", displayField: "Open", sortable: true },
        { name: "likeCount", displayField: "Likes", sortable: true },
        { name: "dislikeCount", displayField: "Dislikes", sortable: true },
        { name: "actions", displayField: "", sortable: false }
    ],
    USER: [
        {name: "accountType", displayField: "Account Type", width: "100px"},
        {name: "name", displayField: "Name", width:"250px"},
        {name: "userId", displayField: "User ID", width:"250px"},
        {name: "countryCode", displayField: "Country Code", width:"100px"},
        {name: "networkCount", displayField: "Networks"},
        {name: "action", displayField: ""}
    ],
    VALIDATION:[
        { name: "userId", displayField: "User ID", sortable: true },
        { name: "gatewayHwAddress", displayField: "Gateway", sortable: true },
        { name: "blockingClientHwAddress", displayField: "Blocking Device", sortable: true },
        { name: "validationState", displayField: "State", sortable: true },
        { name: "creationDate", displayField: "Created", sortable: true },
        { name: "termsAcceptanceDate", displayField: "Terms Accepted", sortable: true },
        { name: "blockedDevicesCount", displayField: "Blocked Devices", sortable: true },
        { name: "devicesCount", displayField: "Devices", sortable: true },
        { name: "personalDevicesCount", displayField: "Personal", sortable: true },
        { name: "digitalMediaDevicesCount", displayField: "Digital Media", sortable: true },
        { name: "smartHomeDevicesCount", displayField: "Smart Home", sortable: true },
        { name: "networkingDevicesCount", displayField: "Networking", sortable: true },
        { name: "gamingDevicesCount", displayField: "Gaming", sortable: true },
        { name: "protectionDevicesCount", displayField: "Protection", sortable: true },
        { name: "itDevicesCount", displayField: "IT Devices", sortable: true },
        { name: "actions", displayField: "", sortable: false }
    ]
}

export const ON_RENDER_CELL = {
    AGENT: (header, row, loading) => {
        const agentId = row.__rowId && row.__rowId['agentId'] ? row.__rowId['agentId'] : "";
        const value = row[header.name] || "";
        const stateValues = ENUMS.AGENT.STATE;
        const connectionValues = ENUMS.AGENT.CONNECTION;
        switch (header.name) {
            case "agentId":
                return renderTextCell(agentId);
            case "state":
                return renderSingleBadgeCell(stateValues,value)
            case "connectionState":
                return renderSingleBadgeCell(connectionValues, value)
            case "customName":
                return <td className="p-2">
                    {row[header.name] == null ? "" : row[header.name]}
                    {row['masterUserId'] == null ? "" : <span className="badge badge-danger ml-2">Watched</span>}
                </td>;
            case "fingboxType":
            case "shardId":
            case "userId":
            case "hardwarePlatform":
            case "agentVersion":
            case "agentChannel":
                return <td className="p-2">{row[header.name] == null ? "" : row[header.name]}</td>;
            case "firstActivationDate":
                return renderDateCell(row[header.name])
            case "action":
                return <td className="p-2 d-flex justify-content-end">
                    {loading ? <button disabled={loading} className="btn btn-xs btn-soft-dark">
                        <i className="fas fa-fw fa-pencil-alt mr-1"/>Edit
                    </button> :
                    <Link to={`/admin/agent/${agentId}`} 
                        className="btn btn-xs btn-soft-primary">
                            <i className="fas fa-fw fa-pencil-alt mr-1"/>
                            Edit
                        </Link>}
                </td>
            default:
                return renderTextCell(row[header.name]);
        }
    },
    CONNECTION: (header, row, loading) => {
        let connId = row.__rowId && row.__rowId['clientToken'] ? row.__rowId['clientToken'] : "";
        if(connId.includes('/')){
            connId = encodeURIComponent(connId);
        }
        switch (header.name) {
            case "userId":
            case "clientId":
            case "clientName":
            case "clientType":
            case "clientVersion":
            case "clientPlatform":
                return renderTextCell(row[header.name])
            case "firstAccess":
            case "lastAccess":
                return renderDateCell(row[header.name])
            case "action":
                return <td className="p-2 d-flex justify-content-end">
                    {loading ? <button disabled={loading} className="btn btn-xs btn-soft-dark">
                        <i className="fas fa-fw fa-pencil-alt mr-1"/>Edit
                    </button> :
                    <Link to={`/admin/connection/${connId}`} 
                        className="btn btn-xs btn-soft-primary">
                            <i className="fas fa-fw fa-pencil-alt mr-1"/>
                            Edit
                        </Link>}
                </td>
            default:
                return renderTextCell(row[header.name]);
        }
    },
    DISCOUNT_OFFER:(header, row, loading) => {
        const discountOfferId = row.__rowId && row.__rowId['discountOfferId'] ? row.__rowId['discountOfferId'] : "new";
        switch (header.name) {
            case "promoText":
            case "allowedPlans":
            case "allowedCountries":
            case "purchaseRules":
            case "discountId":
                return renderTextCell(row[header.name])
            case "enabled":
                return <td className="p-2">{row[header.name] == null ? "" : (row[header.name] === false ? 'NO' : 'YES')}</td>;
            case "startDate":
            case "endDate":
                return renderDateCell(row[header.name])
            case "actions":
                return <td className="p-2 d-flex justify-content-end">
                    {loading ? <button disabled={loading} className="btn btn-xs btn-soft-dark">
                        <i className="fas fa-fw fa-pencil-alt mr-1"/>Edit
                    </button> :
                    <Link to={`/admin/offer/${discountOfferId}`} 
                        className="btn btn-xs btn-soft-primary">
                            <i className="fas fa-fw fa-pencil-alt mr-1"/>
                            Edit
                        </Link>}
                </td>
            default:
                return renderTextCell(row[header.name]);
        }
    },
    POST: (header, row, loading) => {

        switch (header.name) {
            case "format":
                const format = row[header.name] == null ? "" : row[header.name].toLowerCase();
                switch (format) {
                    case "article": return <td className="p-2"><span className="fas fa-fw fa-file text-black"/></td>;
                    case "podcast": return <td className="p-2"><span className="fas fa-fw fa-podcast text-yellow"/></td>;
                    case "video": return <td className="p-2"><span className="fab fa-fw fa-youtube text-red"/></td>;
                    case "discussion": return <td className="p-2"><span className="fas fa-fw fa-comments text-blue"/></td>;
                    default: return <td className="p-2"><span className="fas fa-question-circle"/></td>;
                }
            case "category":
                const category = row[header.name] == null ? "" : row[header.name].toLowerCase();
                switch (category) {
                    case "review": return <td className="p-2">Review</td>;
                    case "comparison": return <td className="p-2">Comparison</td>;
                    case "how_to": return <td className="p-2">How-to</td>;
                    case "tip_n_trick": return <td className="p-2">Tips</td>;
                    case "news": return <td className="p-2">News</td>;
                    case "security_notice": return <td className="p-2">Security</td>;
                    default: return <td className="p-2">{category}</td>;
                }
            case "state":
                const state = row[header.name] == null ? "" : row[header.name].toLowerCase();
                switch (state) {
                    case "published": return <td className="p-2"><span className="fas fa-fw fa-check text-success"/></td>;
                    case "broken": return <td className="p-2"><span className="fas fa-fw fa-unlink text-danger"/></td>;
                    case "suspended": return <td className="p-2"><span className="fas fa-fw fa-pause text-warning"/></td>;
                    case "removed": return <td className="p-2"><span className="fas fa-fw fa-times  text-dark"/></td>;
                    default: return <td className="p-2"><span className="fas fa-question-circle"/></td>;
                }
            case "publishDate":
            case "insertDate":
                return renderDateCell(row[header.name])

            case "previewImageUrl":
                return <td className="p-2 text-center">{row[header.name] && <img alt="Thumbnail" src={row[header.name]} width={64}/>}</td>;

            case "actions":
                const postId = row.__rowId && row.__rowId['postId'] ? row.__rowId['postId'] : "new";
                return <td className="p-2 d-flex justify-content-end">
                    {loading ? <button disabled={loading} className="btn btn-xs btn-soft-dark">
                        <i className="fas fa-fw fa-pencil-alt mr-1"/>Edit
                    </button> :
                    <Link to={`/admin/post/${postId}`} 
                        target="_blank"
                        className="btn btn-xs btn-soft-primary">
                            <i className="fas fa-fw fa-pencil-alt mr-1"/>
                            Edit
                        </Link>}
                </td>

            case "score":
                let likes = row["likeCount"] || 0;
                let dislikes = row["dislikeCount"] || 0;
                let outdated = row["outdatedCount"] || 0;
                let curator = row["curatorScore"] || 0;
                return renderTextCell(likes-dislikes-outdated+curator);
            default:
                return renderTextCell(row[header.name]);
        }
    },
    USER: (header, row, loading) => {
        const userId = row.userId ? row.userId : "";
        switch (header.name) {
            case "userId":
                return renderTextCell(userId);
            case "accountType":
                return renderSingleBadgeCell(ENUMS.USER.ACCOUNT_TYPE, row[header.name])
                
            case "networkCount":
            case "name":
            case "countryCode":
                return renderTextCell(row[header.name]);
            case "action":
                
                return <td className="p-2 d-flex justify-content-end">
                    {loading ? <button disabled={loading} className="btn btn-xs btn-soft-dark">
                        <i className="fas fa-fw fa-pencil-alt mr-1"/>Edit
                    </button> :
                    <Link to={`/admin/user/${userId}`} 
                        className="btn btn-xs btn-soft-primary">
                            <i className="fas fa-fw fa-pencil-alt mr-1"/>
                            Edit
                        </Link>}
                </td>
            default:
                return renderTextCell(row[header.name]);
        }
    },
    VALIDATION: (header, row, loading) => {

        switch (header.name) {
            case "validationState":
                const enumValue = row[header.name] || "";
                switch (enumValue) {
                    case "APPROVED":
                        return <td className="p-2"><span className="badge badge-success">Approved</span></td>;
                    case "PENDING":
                        return <td className="p-2"><span className="badge badge-warning">Pending</span></td>;
                    case "REJECTED":
                        return <td className="p-2"><span className="badge badge-danger">Rejected</span></td>;
                    default:
                        return <td className="p-2">{enumValue}</td>;
                }
            case "userId":
            case "gatewayHwAddress":
            case "blockingClientHwAddress":
            case "comment":
            case "blockedDevicesCount":
            case "publicIpAddress":
            case "devicesCount":
            case "personalDevicesCount":
            case "digitalMediaDevicesCount":
            case "smartHomeDevicesCount":
            case "networkingDevicesCount":
            case "gamingDevicesCount":
            case "protectionDevicesCount":
            case "itDevicesCount":
                return renderTextCell(row[header.name]);
            case "creationDate":
            case "termsAcceptanceDate":
                return renderDateCell(row[header.name])
            case "actions":
                const validationId = row.__rowId && row.__rowId['validationId'] >= 0 ? row.__rowId['validationId'] : -1;
                return validationId >= 0 ? <td className="p-2 d-flex justify-content-end">
                    {loading ? <button disabled={loading} className="btn btn-xs btn-soft-dark">
                        <i className="fas fa-fw fa-pencil-alt mr-1"/>Edit
                    </button> :
                    <Link to={`/admin/validation/${validationId}`} 
                        className="btn btn-xs btn-soft-primary">
                            <i className="fas fa-fw fa-pencil-alt mr-1"/>
                            Edit
                        </Link>}
                </td> : <span />
            default:
                return renderTextCell(row[header.name]);
        }
    },
    IAM: (header, row, switchComponent) => {
        const id = row.__rowId && row.__rowId['id'] ? row.__rowId['id'] : "";
        const value = row[header.name] || "";
        const layoutValues = ENUMS.MESSAGE.LAYOUT.IAM;
        const platformValues = ENUMS.MESSAGE.PLATFORM;
        const purchaseStateValues = ENUMS.MESSAGE.PURCHASE_STATE;
        const accountTypeValues = ENUMS.MESSAGE.ACCOUNT_TYPE;

        const onPreview = () => {
            showDialogById(id + "_IAM_DIALOG")
        }
        

        switch (header.name) {
            case "switch":
                return switchComponent(id)
            case "id":
                return renderTextCell(id);
            case "layout":
                return renderSingleBadgeCell(layoutValues, value)
            case "platform":
                return renderMultipleBadgeCell(platformValues, value)
            case "accountType":
                return renderMultipleBadgeCell(accountTypeValues, value)
            case "purchaseState":
                return renderMultipleBadgeCell(purchaseStateValues, value)
            case "action":
                return <td className="p-2 d-flex justify-content-end">
                    <div className="btn btn-xs btn-soft-primary mr-1" onClick={onPreview}>
                        <i className="fas fa-fw fa-eye"/>
                    </div>
                    <Link to={`/admin/iam/${id}`} className="btn btn-xs btn-soft-primary mr-1">
                        <i className="fas fa-fw fa-pencil-alt"/>
                    </Link>
                    
                </td>
            default:
                return renderTextCell(row[header.name]);
        }
    },
    IAB: (header, row, switchComponent) => {
        const id = row.__rowId && row.__rowId['id'] ? row.__rowId['id'] : "";
        const value = row[header.name] || "";
        const layoutValues = ENUMS.MESSAGE.LAYOUT.IAB;
        const platformValues = ENUMS.MESSAGE.PLATFORM;
        const purchaseStateValues = ENUMS.MESSAGE.PURCHASE_STATE;
        const accountTypeValues = ENUMS.MESSAGE.ACCOUNT_TYPE;

        const onPreview = () => showDialogById(id + "_IAB_DIALOG")

        switch (header.name) {
            case "switch":
                return switchComponent(id);
            case "id":
                return renderTextCell(id);
            case "layout":
                return renderSingleBadgeCell(layoutValues, value);
            case "platform":
                return renderMultipleBadgeCell(platformValues, value)
            case "accountType":
                return renderMultipleBadgeCell(accountTypeValues, value)
            case "purchaseState":
                return renderMultipleBadgeCell(purchaseStateValues, value)
            case "action":
                return <td className="p-2 d-flex justify-content-end">
                    <div className="btn btn-xs btn-soft-primary mr-1" onClick={onPreview}>
                        <i className="fas fa-fw fa-eye"/>
                    </div>
                    <Link to={`/admin/iab/${id}`} className="btn btn-xs btn-soft-primary mr-1">
                        <i className="fas fa-fw fa-pencil-alt"/>
                    </Link>
                </td>
            default:
                return renderTextCell(row[header.name]);
        }
    }
}