import React from "react";

import {
    put,
    remove,
    get
} from '../../services/APIService';
import {ToastContainer} from "react-toastify";
import {failedToast, successToast} from "../../helpers/ToastHelper";
import ModalDialog, {hideDialogById, ModalDialogBody, ModalDialogFooter, showDialogById} from "../dialog/ModalDialog";
import {isEmptyObject, isNotEmptyString} from "../../helpers/JsHelper";
import {Link} from "react-router-dom";
import {
    convertEnumToState,
    convertMultiEnumToState,
    convertTimestampToDate,
    convertDateToTimestamp,
    convertStateToArray
} from './helper/ConversionHelper';
import { controlMandatoryFields, controlMaxLengthFields, controlRegexFields } from "./helper/ControlHelper";
import { handleApiError } from "./helper/ErrorHelper";
import { ENUMS } from "./helper/EnumHelper";
import { TITLES } from './helper/TitlesHelper';
import { REGEX } from './helper/RegexHelper';
import { MANDATORY } from './helper/MandatoryHelper';
import { MULTIPLE } from './helper/MultipleHelper';
import { checkAllPermissions, PERMISSION } from "./helper/PermissionHelper";

import TableSwitchField from "./fields/TableSwitchField";
import TableEnumField from "./fields/TableEnumField";
import TableNumberField from "./fields/TableNumberField";
import TableTextField from "./fields/TableTextField";
import TextField from "./fields/TextField";
import TableDateField from "./fields/TableDateField";

import CardTable from "./extra/CardTable";
import MainContent from "./extra/MainContent";
import EventComponent from "./extra/EventComponent";
import { DEFAULT } from './helper/DefaultHelper';

const formGroupCls = "form-group mb-1";
 
const maxLengths = {
}

const defaultValues = DEFAULT.IAM;

const regex = REGEX.IAM;

const mandatory = MANDATORY.IAM;

const disabled = {
}

const copyable = {
}

const multiple = MULTIPLE.IAM;

const titles = TITLES.IAM;

const DELETE_IAM_DIALOG = "DELETE_IAM_DIALOG";
const IMPORT_DIALOG = "IMPORT_DIALOG";

const permissionTypes = PERMISSION.IN_APP.types;
const permissionUrl = PERMISSION.IN_APP.url;

const setEnumsOnState = (state,target,field,enums) => {
    if(target[field])
        state[field] = multiple[field] ? convertMultiEnumToState(enums,target[field]) : convertEnumToState(enums, target[field]);
}

export default class AdminIAMEditPage extends EventComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            attemptedSave: false,
            forbidden: false,
            loading: true,
            cloning: false,
            visibleTitle: false,
            visibleBody: false,
            visibleButton: false,
            importing: -1
        };
    }

    componentDidMount() {
        checkAllPermissions(permissionUrl,permissionTypes).then(result=> {
            const state = this.state;
            for(const key in result){
                state['permission_'+key] = result[key];
            }
            this.setState({...state},this.initWithIAMId())
        });
    }

    initWithIAMId() {
        if(this.state.permission_view !== null && this.state.permission_view === false)  
            this.props.history.replace({ pathname: `/`});
        let iamId = this.props.match.params.iamId;
        if(iamId !== 'new'){
            get(`/rest/admin/notification/${iamId}`)
                .then((msg) => {
                    this.setState({record:msg}, () => this.convertFullIAMToState(JSON.parse(msg.jsonBody)))
                })
                .catch((err) => {
                    failedToast("Unable to read message");
                    handleApiError(err, this.context, () => this.setState({forbidden: true}));
                })
                .finally(() => this.setState({loading: false}));
        }
    }

    convertFullIAMToState(response) {
        const newState = this.state;
        
        if (response) {
            const iam = response;
            newState.iamId = iam.id;
            newState.enabled = iam.enabled;
            if(iam.scheduling){
                const scheduling = iam.scheduling;
                if(scheduling?.start_ms)
                    newState.start_ms = convertTimestampToDate(scheduling.start_ms);
                if(scheduling?.end_ms)
                    newState.end_ms = convertTimestampToDate(scheduling.end_ms);
                if(scheduling['frequency-per-device'])
                    newState['frequency-per-device'] = Number(scheduling['frequency-per-device']);
                if(scheduling['days-between-display'])
                    newState['days-between-display'] = Number(scheduling['days-between-display']);
                if(scheduling['days-across-any-message'])
                    newState['days-across-any-message'] = Number(scheduling['days-across-any-message']);
                if(scheduling['days-since-first-open'])
                    newState['days-since-first-open'] = Number(scheduling['days-since-first-open']);
            }
            if(iam.style){
                const style = iam.style;
                if(style.titles)
                    for(const c of ENUMS.COUNTRIES){
                        const code = c.value;
                        newState[code + '-title'] = style.titles[code];
                        newState[code + '-bodies'] = style.bodies[code];
                    }
                setEnumsOnState(newState,style,"layout",ENUMS.MESSAGE.LAYOUT.IAM);

                if(style?.images?.default) 
                    newState["image-default"] = style.images.default;
                if(style?.images?.landscape) 
                    newState["image-landscape"] = style.images.landscape;
                if(style?.images?.animated) 
                    newState["image-animated"] = style.images.animated;
                if(style?.primary_button?.action) 
                    newState["action-button"] = style.primary_button.action;
                if(style?.primary_button?.texts)
                    for(const c of ENUMS.COUNTRIES){
                        const code = c.value;
                        newState[code + '-primary-button-texts'] = style.primary_button.texts[code];
                    }
            }
            if(iam.target){
                const setHasHardwareState = (newState, target, id) => {
                    if(target[id] === undefined || target[id] === null)
                        newState[id] = ENUMS.MESSAGE.HAS_HARDWARE[0];
                    else if(target[id])
                        newState[id] = ENUMS.MESSAGE.HAS_HARDWARE[1];
                    else
                        newState[id] = ENUMS.MESSAGE.HAS_HARDWARE[2];
                    return newState[id]
                }
                const target = iam.target;

                newState['account-has-desktop'] = setHasHardwareState(newState, target, 'account-has-desktop');
                newState['account-has-fingbox'] = setHasHardwareState(newState, target, 'account-has-fingbox');
                newState['account-has-mobile'] = setHasHardwareState(newState, target, 'account-has-mobile');
    
                if(target['app-version-v2'] && target['app-version-v2']['min'])
                    newState["app-version-v2-min"] = target['app-version-v2']['min'];
                if(target['app-version-v2'] && target['app-version-v2']['max'])
                    newState["app-version-v2-max"] = target['app-version-v2']['max'];
                if(target["countries-exclude"] && target["countries-exclude"].length > 0)
                    newState["countries-exclude"] = target["countries-exclude"].join(',');
                if(target["countries"] && target["countries"].length > 0)
                    newState["countries"] = target["countries"].join(',');
                setEnumsOnState(newState,target,"platforms",ENUMS.MESSAGE.PLATFORM);
                setEnumsOnState(newState,target,'account-types',ENUMS.MESSAGE.ACCOUNT_TYPE);
                setEnumsOnState(newState,target,"tech-attitude",ENUMS.MESSAGE.TECH_ATTITUDE);
                setEnumsOnState(newState,target,"purchase-states",ENUMS.MESSAGE.PURCHASE_STATE);
            } 
            if(iam.conversion){
                const conversion = iam.conversion;
                if(conversion.display)
                    newState["conversion-display"] = conversion.display;
                if(conversion.click)
                    newState["conversion-click"] = conversion.click;
            }
            if(iam.notification){
                const notification = iam.notification;
                newState['notification-enabled'] = notification["enabled"];
                if(notification["local-time"])
                    newState["notification-local-time"] = notification["local-time"];
            }
        }
        this.setState(newState);
    }

    // --------------------------------------------------------------------------------
    // MAIN RENDERING
    // --------------------------------------------------------------------------------

    render() {
        let iamId = this.props.match.params.iamId;

        const onClone = () => {
            const str =  window.location.href;
            const lastIndex = str.lastIndexOf("/");
            const path = str.substring(0, lastIndex);
            const newUrl = path + "/new";
            window.history.pushState({}, null, newUrl);
            this.props.match.params.iamId = 'new';
            get(`/rest/admin/notification/${iamId}`)
                .then((msg) => {
                    msg.id = '';

                    this.setState({record:msg,cloning: true}, () => {
                        const jsonBody = JSON.parse(msg.jsonBody);
                        jsonBody.id = '';
                        this.convertFullIAMToState(jsonBody)
                    })
                })
                .catch((err) => {
                    failedToast("Unable to read message");
                    handleApiError(err, this.context, () => this.setState({forbidden: true}));
                })
                .finally(() => this.setState({loading: false}));
           
        }

        return (
            <MainContent>
                <div className="container-fluid space-1 px-4">
                    <div className="d-flex border-bottom mb-6">
                        <div>
                            <h2 className="d-inline-block">{iamId === 'new' ? 'Add In App Message' : 'Edit In App Message'}</h2>
                            <span className="text-secondary mx-2">/</span>
                            <Link to="/admin/list-iam">Back to In App Messages list</Link>
                        </div>
                        <div className="ml-auto">
                            {iamId !== 'new' && this.state.permission_edit && !this.state.cloning && 
                                <button className="btn btn-sm btn-warning mr-2" onClick={onClone}>Clone</button>}
                            {iamId !== 'new' && this.state.permission_edit && 
                                <button className="btn btn-sm btn-danger mr-2" onClick={()=> showDialogById(DELETE_IAM_DIALOG)}>Delete</button>}

                            {this.state.permission_edit && 
                                <button className="btn btn-sm btn-primary" onClick={() => this.saveIAM()}>Save</button>}
                        </div>
                    </div>
                    <ToastContainer/>
                    {this.renderForm()}
                    {this.renderDeleteIAMDialog()}
                    {this.renderImportDialog()}
                </div>
            </MainContent>
            
        );
    }
    
    saveIAM() {

        const msg = this.state;
        let IAM = this.state.record;
        let iamId = this.props.match.params.iamId;

        // === VALIDATE ===
        mandatory['en-title'] = msg.layout?.value !== "IMAGE";
        mandatory['en-bodies'] = msg.layout?.value !== "IMAGE"; 
        mandatory['en-primary-button-texts'] = true;
        

        if(!controlMandatoryFields(this.state,mandatory,() => this.setState({attemptedSave: true}))) 
            return
        if(!controlMaxLengthFields(this.state,maxLengths,() => this.setState({attemptedSave: true})))
            return
        if(!controlRegexFields(this.state,regex,() => this.setState({attemptedSave: true})))
            return
        
        // === SEND ===

        
        let URL = `/rest/admin/notification/${iamId}`;
        if(iamId === 'new'){
            URL = `/rest/admin/notification/new`
            iamId = msg.iamId;
            
        }

        const getValue = (val) => val && val.length > 0 ? val.map(v=>v.value).join(',') : ''
        const getValueFromEnum = (val) => val && val.value ? val.value : '' 

        IAM = {
            accountType: getValue(msg['account-types']),
            id: iamId,
            jsonBody: null,
            layout: getValueFromEnum(msg['layout']),
            platform: getValue(msg['platforms']),
            purchaseState: getValue(msg['purchase-states']),
            type: 'MESSAGE',
            __rowId: {id: iamId}
        }



        let titles = {};
        let buttons = {};
        let bodies = {};

        for(const c of ENUMS.COUNTRIES){
            const code = c.value;
            if(msg[code + '-title']) 
                titles[code] = msg[code + '-title'] || '';
            if(msg[code + '-bodies']) 
                bodies[code] = msg[code + '-bodies'] || '';
            if(msg[code + '-primary-button-texts']) 
                buttons[code] = msg[code + '-primary-button-texts'] || '';
        }

        let scheduling = {};
        scheduling['start_ms'] = convertDateToTimestamp(msg.start_ms || defaultValues["start_ms"]);
        
        if(msg.end_ms){
            scheduling['end_ms'] = convertDateToTimestamp(msg.end_ms);
        } 
        if(msg['frequency-per-device'] && msg['frequency-per-device'] > 0){
            scheduling['frequency-per-device'] = Number(msg['frequency-per-device']);
        }
        if(msg['days-between-display'] && msg['days-between-display'] > 0){
            scheduling['days-between-display'] = Number(msg['days-between-display']);
        }
        if(msg['days-across-any-message'] && msg['days-across-any-message'] > 0){
            scheduling['days-across-any-message'] = Number(msg['days-across-any-message']);
        }
        if(msg['days-since-first-open'] && msg['days-since-first-open'] > 0){
            scheduling['days-since-first-open'] = Number(msg['days-since-first-open']);
        }

        const appVersion = {}
        if(msg["app-version-v2-min"]){
            appVersion.min = msg["app-version-v2-min"]
        }
        if(msg["app-version-v2-max"]){
            appVersion.max = msg["app-version-v2-max"]
        }

        const target = {}

        const setHasHardwareValue = (val) => {
            const check = msg[val] ? msg[val].value : defaultValues[val].value;
            switch(check) {
                case "true":
                    target[val] = true;
                    break;
                case "false":
                    target[val] = false;
                    break;
                case "all":
                    break;
                default:
                    break;
            }
        }

        setHasHardwareValue('account-has-desktop')
        setHasHardwareValue('account-has-fingbox')
        setHasHardwareValue('account-has-mobile')

        if(!isEmptyObject(appVersion))
            target['app-version-v2'] = appVersion;

        if(msg["countries-exclude"])
            target['countries-exclude'] = msg["countries-exclude"].split(",")
        if(msg["countries"])
            target['countries'] = msg["countries"].split(",")

        const platforms = convertStateToArray(msg['platforms'])
        if(platforms.length > 0)
            target['platforms'] = platforms;

        const accountTypes = convertStateToArray(msg['account-types'])
        if(accountTypes.length > 0)
            target['account-types'] = accountTypes;
            
        const techAttitude = convertStateToArray(msg['tech-attitude'])
        if(techAttitude.length > 0)
            target['tech-attitude'] = techAttitude;
            
        const purchaseStates = convertStateToArray(msg['purchase-states'])
        if(purchaseStates.length > 0)
            target['purchase-states'] = purchaseStates;

        const conversion = {}
        if(msg['conversion-click'])
            conversion['click'] = msg['conversion-click']
        if(msg['conversion-display'])
            conversion['display'] = msg['conversion-display']
        
        const images = {}
        if(msg['image-default'])
            images['default'] = msg['image-default']
        if(msg['image-landscape'])
            images['landscape'] = msg['image-landscape']
        if(msg['image-animated'])
            images['animated'] = msg['image-animated']

        const notification = {}
        if(msg['notification-enabled'])
            notification['enabled'] = msg['notification-enabled']
        if(msg['notification-local-time'])
            notification['local-time'] = msg['notification-local-time']

        const primaryButtons = {
            texts: buttons
        }

        if(msg['action-button'])
            primaryButtons['action'] = msg['action-button']

        const body = {
            id: iamId,
            style: {
                primary_button: primaryButtons,
                layout: msg['layout'].value
            },
        }
        if(msg.enabled)
            body['enabled'] = msg.enabled
        if(!isEmptyObject(conversion))
            body['conversion'] = conversion;
        if(!isEmptyObject(bodies))
            body.style['bodies'] = bodies;
        if(!isEmptyObject(titles))
            body.style['titles'] = titles;
        if(!isEmptyObject(primaryButtons))
            body.style['primary_button'] = primaryButtons;
        if(!isEmptyObject(images))
            body.style['images'] = images;
        if(!isEmptyObject(scheduling))
            body['scheduling'] = scheduling;
        if(!isEmptyObject(target))
            body['target'] = target;
        if(!isEmptyObject(notification))
            body['notification'] = notification;
        IAM.jsonBody = JSON.stringify(body);


        this.setState({loading: true, attemptedSave: false});
        put(URL, IAM)
            .then((msg) => {
                if (msg) {
                    successToast("IAM saved");
                    this.setState({record:msg}, () => this.convertFullIAMToState(JSON.parse(msg.jsonBody)))
                    if (iamId) {
                        this.props.history.replace({ pathname: `/admin/iam/${iamId}`})
                    }
                }
            })
            .catch((err) => {
                failedToast("Unable to save IAM");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    deleteIAM() {
        const iamId = this.props.match.params.iamId;

        this.setState({loading: true});
        remove(`/rest/admin/notification/${iamId}`)
            .then((result) => {
                successToast("IAM removed");
                this.props.history.replace({pathname: `/admin/list-iam`});
            })
            .catch((err) => {
                failedToast("Unable to delete IAM");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    // -------------------------------------------------------------------------------------------------

    renderForm() {
        let iamId = this.props.match.params.iamId;

        return (
            <form>
                <div className="row">
                    <div className="col-6">
                        {this.renderData()}
                    </div>
                    <div className="col-6">
                        <CardTable title="Info" className="mb-2">
                            {this.renderTextTableField("iamId", iamId !== 'new' && !this.state.cloning)}
                            {this.renderSwitchTableField("enabled")}
                            {this.renderEnumTableField("layout", ENUMS.MESSAGE.LAYOUT.IAM)}
                        </CardTable>
                        <CardTable title="Scheduling" className="mb-2">
                            {this.renderDateTableField("start_ms")}
                            {this.renderDateTableField("end_ms")}
                            {this.renderNumberTableField("frequency-per-device")}
                            {this.renderNumberTableField("days-between-display")}
                            {this.renderNumberTableField("days-across-any-message")}
                            {this.renderNumberTableField("days-since-first-open")}
                        </CardTable>
                        <CardTable title="Target" className="mb-2">
                            {this.renderEnumTableField("account-has-desktop", ENUMS.MESSAGE.HAS_HARDWARE)}
                            {this.renderEnumTableField("account-has-fingbox", ENUMS.MESSAGE.HAS_HARDWARE)}
                            {this.renderEnumTableField("account-has-mobile", ENUMS.MESSAGE.HAS_HARDWARE)}
                            {this.renderTextTableField("app-version-v2-min")}
                            {this.renderTextTableField("app-version-v2-max")}
                            {this.renderTextTableField("countries")}
                            {this.renderTextTableField("countries-exclude")}
                            {this.renderEnumTableField("purchase-states", ENUMS.MESSAGE.PURCHASE_STATE)}
                            {this.renderEnumTableField("platforms", ENUMS.MESSAGE.PLATFORM)}
                            {this.renderEnumTableField("account-types", ENUMS.MESSAGE.ACCOUNT_TYPE)}
                            {this.renderEnumTableField("tech-attitude", ENUMS.USER.TECH_ATTITUDE)}
                        </CardTable>
                        <CardTable title="Notification" className="mb-2">
                            {this.renderTextTableField("notification-local-time")}
                            {this.renderSwitchTableField("notification-enabled")}
                        </CardTable>
                    </div>
                </div>
            </form>
        );
    }
    

    renderData(){
        const onImportTitles = () => {
            this.setState({importing: 0}, showDialogById(IMPORT_DIALOG));
        }
        const onImportBodies = () => {
            this.setState({importing: 1}, showDialogById(IMPORT_DIALOG));
        }
        const onImportButtons = () => {
            this.setState({importing: 2}, showDialogById(IMPORT_DIALOG));
        }
        return (
            <div>
                <CardTable title="Links" className="mb-2">
                    {this.renderTextField("action-button")}
                    {this.renderTextField("image-default")}
                    {this.renderTextField("image-landscape")}
                    {this.renderTextField("image-animated")}
                    {this.renderTextField("conversion-display")}
                    {this.renderTextField("conversion-click")}
                </CardTable>
                <div className="card card-bordered mb-2">
                    <div className="card-header bg-soft-primary p-2" >
                        <div className="d-flex align-items-center justify-content-start">
                            <i  onClick={()=>this.setState({visibleTitle: !this.state.visibleTitle})}
                                className={this.state.visibleTitle ? "fa fas fa-minus mr-2" : "fa fas fa-plus mr-2"}></i>
                            <span className="text-dark">Titles</span>
                        </div>
                        {this.state.permission_edit && 
                            <button type="button" className="btn btn-sm btn-primary ml-auto" onClick={onImportTitles}>Import</button>}
                    </div>
                    {this.state.visibleTitle && <div className="card-body p-2">
                        {ENUMS.COUNTRIES.map(c => 
                            <div className="d-flex align-items-center justify-content-start mb-2">
                                <div style={{width:50}} className="text-dark px-2">{c.label}</div> 
                                {this.renderCustomTextField(c.value + "-title")}
                            </div>    
                        )}
                    </div>}
                </div>
                <div className="card card-bordered mb-2">
                    <div className="card-header bg-soft-primary p-2">
                        <div className="d-flex align-items-center justify-content-start">
                            <i  onClick={()=>this.setState({visibleBody: !this.state.visibleBody})}
                                className={this.state.visibleBody ? "fa fas fa-minus mr-2" : "fa fas fa-plus mr-2"}></i>
                            <span className="text-dark">Bodies</span>
                        </div>
                        {this.state.permission_edit && 
                            <button type="button" className="btn btn-sm btn-primary ml-auto" onClick={onImportBodies}>Import</button>}
                    </div>
                    {this.state.visibleBody && <div className="card-body p-2">
                        {ENUMS.COUNTRIES.map(c => 
                            <div className="d-flex align-items-center justify-content-start mb-2">
                                <div style={{width:50}} className="text-dark px-2">{c.label}</div> 
                                {this.renderCustomTextField(c.value + "-bodies")}
                            </div>    
                        )}
                    </div>}
                </div>
                <div className="card card-bordered mb-2">
                    <div className="card-header bg-soft-primary p-2">
                        <div className="d-flex align-items-center justify-content-start">
                            <i  onClick={()=>this.setState({visibleButton: !this.state.visibleButton})}
                                className={this.state.visibleButton ? "fa fas fa-minus mr-2" : "fa fas fa-plus mr-2"}></i>
                            <span className="text-dark">Buttons</span>
                        </div>
                        {this.state.permission_edit && 
                            <button type="button" className="btn btn-sm btn-primary ml-auto" onClick={onImportButtons}>Import</button>}
                    </div>
                    {this.state.visibleButton && <div className="card-body p-2">
                        {ENUMS.COUNTRIES.map(c => 
                            <div className="d-flex align-items-center justify-content-start mb-2">
                                <div style={{width:50}} className="text-dark px-2">{c.label}</div> 
                                {this.renderCustomTextField(c.value + "-primary-button-texts")}
                            </div>    
                        )}
                    </div>}
                </div>
            </div>
        )
    }


    // --------------------------------------------------------------------------------
    // FORM FIELDS
    // --------------------------------------------------------------------------------

    renderDateTableField(id) {
        return (
            <TableDateField
                value={this.state[id] || defaultValues[id]}
                attemptedSave={this.state.attemptedSave}
                disabled={disabled[id]}
                label={titles[id]}
                id={id}
                mandatory={mandatory[id]}
                onChange={this.handleInputFieldChange}/>
        )
    }
    

    renderTextField(id, dis) {
        return (
            <TextField
                id={id}
                className="p-2 mb-0"
                value={this.state[id] || defaultValues[id] || ''}
                attemptedSave={this.state.attemptedSave}
                label={titles[id] || 'text'}
                mandatory={mandatory[id] === true}
                maxLength={maxLengths[id] || 0}
                onChange={this.handleInputFieldChange}
                disabled={disabled[id] || dis}/>
        )
    }

    renderTextTableField(id, dis) {
        return (
            <TableTextField
                value={this.state[id] || defaultValues[id] || ''}
                label={titles[id] || 'text'}
                id={id}
                disabled={disabled[id] || dis}
                mandatory={mandatory[id]}
                maxLength={maxLengths[id] || 0}
                attemptedSave={this.state.attemptedSave}
                onChange={this.handleInputFieldChange}/>
            
        )
        
    }

    renderCustomTextField(id) {
        const attemptedSave = this.state.attemptedSave;
        const value = this.state[id] || '';
        
        const isMissing = attemptedSave && mandatory[id] === true && !isNotEmptyString(value, true);
        return <div className={formGroupCls + " w-100"}>
            <input type="text" id={id}
                   className={`form-control form-control-sm my-0 ${isMissing}`}
                   placeholder={`Enter text`}
                   value={value}
                   onChange={this.handleInputFieldChange}
            />
            {isMissing && <div className="text-danger">Please enter the text</div>}
        </div>;
    }

    

    renderEnumTableField(id, enumValues) {

        return (
            <TableEnumField
                onChange={this.handleSelectFieldChange}
                isMulti={multiple[id]}
                label={titles[id]}
                value={this.state[id] || defaultValues[id]}
                attemptedSave={this.state.attemptedSave}
                id={id}
                enumValues={enumValues}
                mandatory={mandatory[id]}/>
        )
    }

    renderSwitchTableField(id) {
        return <TableSwitchField
            label={titles[id]}
            value={this.state[id] || false} 
            onChange={(event)=>this.handleSwitchFieldChange(event)} 
            disabled={false} 
            id={id}/>
    }

    renderNumberTableField(id) {

        return <TableNumberField
            value={this.state[id]}
            label={titles[id]}
            disabled={disabled[id]}
            onChange={this.handleInputFieldChange}
            id={id}/>
    }

    renderDeleteIAMDialog() {
        const onClose = () => hideDialogById(DELETE_IAM_DIALOG);
        const onDelete = () => {
            hideDialogById(DELETE_IAM_DIALOG);
            this.deleteIAM();
        };
        return <ModalDialog id={DELETE_IAM_DIALOG} title={"Confirm Delete IAM"} onClose={onClose}>
            <ModalDialogBody className="py-3">
                Are you sure you want to delete this IAM?
            </ModalDialogBody>
            <ModalDialogFooter className="py-3">
                <button className="btn btn-xs btn-ghost-danger" onClick={onDelete}>Yes, delete it entirely</button>
                <button className="btn btn-xs btn-primary" onClick={onClose}>No</button>
            </ModalDialogFooter>
        </ModalDialog>;
    }

    renderImportDialog() {
        const onClose = () => this.setState({translationText:''}, () => hideDialogById(IMPORT_DIALOG))
        const onImport = () => {
            const json = JSON.parse(this.state.translationText);
            let text = '';
            if(importing === 0){
                text = "-title";
            } else if(importing === 1){
                text = "-bodies";
            } else if(importing === 2){
                text = "-primary-button-texts";
            }
            ENUMS.COUNTRIES.forEach(c => this.setState({[c.value + text]:json[c.value]}));
            this.setState({translationText:''}, () => hideDialogById(IMPORT_DIALOG))
        };
        const importing = this.state.importing;
        let title = '';
        if(importing === 0){
            title = "Import titles translations";
        } else if(importing === 1){
            title = "Import bodies translations";
        } else if(importing === 2){
            title = "Import buttons translations";
        }
        return <ModalDialog id={IMPORT_DIALOG} title={title} onClose={onClose}>
            <ModalDialogBody className="py-3">
                <h5>Use the following text to create translations from chatGPT</h5>
                <hr/>
                <span>Translate "example" in these languages <b>en ja ko ru ar de es fr id nl pt tr vi zh zh-tw it</b> and give me a json</span>
                <hr/>
                <h5>Change "example" with the phrase that you need to translate</h5>
                <textarea 
                    rows="5" 
                    cols="50"
                    value={this.state.translationText}
                    onChange={(event)=>this.setState({translationText:event.target.value})}>
                    Paste the result here
                </textarea>
                

            </ModalDialogBody>
            <ModalDialogFooter className="py-3">
                <button className="btn btn-xs btn-ghost-danger" onClick={onClose}>Close</button>
                <button className="btn btn-xs btn-primary" onClick={onImport}>Import</button>
            </ModalDialogFooter>
        </ModalDialog>;
    }
}