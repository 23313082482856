/**
 * Created by marco on 2/6/21.
 *
 * (C) Copyright Fing
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Re-usable functions to programmatically show/hide the dialog with the given HTML element ID
// Only one modal dialog can be displayed in a page, at any given time
export const showDialogById = (name) => { window.$(`#${name}`).modal('show'); };
export const hideDialogById = (name) => { window.$(`#${name}`).modal('hide'); };

/**
 * Standard modal dialog.
 */
export default class ModalDialog extends Component {

    render() {
        const {id, className, size, title, onClose, subtitle} = this.props;
        const outerClass = className || '';
        const sizeClass = size || '';
        return (
            <div id={id} className={`modal fade ${outerClass}`} tabIndex="-1" role="dialog" aria-modal={true}
                 aria-labelledby="modalDialogLabel" aria-hidden="true" data-backdrop="true" >
                <div className={`modal-dialog modal-dialog-scrollable ${sizeClass}`} role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalDialogLabel">{title}</h5>
                            <p>{subtitle}</p>
                            <button type="button" className="btn btn-xs btn-icon btn-soft-secondary"
                                    data-dismiss="modal" aria-label="Close" onClick={onClose}>
                                <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill="currentColor"
                                          d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"/>
                                </svg>
                            </button>
                        </div>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }

}

/**
 * Standard modal dialog body.
 */
export class ModalDialogBody extends Component {

    render() {
        const {className} = this.props;
        return (
            <div className={`${className || ''} modal-body`}>
                {React.Children.toArray(this.props.children)}
            </div>
        )
    }
}

/**
 * Standard modal dialog footer.
 */
export class ModalDialogFooter extends Component {

    render() {
        const {className} = this.props;
        return (
            <div className={`${className || ''} modal-footer`}>
                {React.Children.toArray(this.props.children)}
            </div>
        )
    }
}

ModalDialog.propTypes = {
    onClose: PropTypes.func,
    size: PropTypes.string,
    title: PropTypes.string
};