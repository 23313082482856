import React from "react";

import {
    get,
    put,
    remove
} from '../../services/APIService';
import {ToastContainer} from "react-toastify";
import {failedToast, successToast} from "../../helpers/ToastHelper";
import ModalDialog, {hideDialogById, ModalDialogBody, ModalDialogFooter, showDialogById} from "../dialog/ModalDialog";
import {Link, Redirect} from "react-router-dom";
import {
    convertDateToState,
    convertEnumToState,
    convertStateToDate,
    convertStateToEnum,
    convertStateToMultiEnum,
    convertMultiEnumToState
} from './helper/ConversionHelper';
import { controlMandatoryFields, controlMaxLengthFields } from "./helper/ControlHelper";
import { handleApiError } from "./helper/ErrorHelper";
import { ENUMS } from "./helper/EnumHelper";
import { HEADERS } from "./helper/TableHelper";
import { APP_FING_URL } from "../../Constants";
import { timezones } from "../../helpers/AccountHelper";
import { TITLES } from './helper/TitlesHelper';
import { COPY } from './helper/CopyHelper';
import { DISABLED } from './helper/DisableHelper';
import { MAX_LENGTH } from './helper/MaxLengthHelper';
import { MULTIPLE } from './helper/MultipleHelper';
import { checkAllPermissions, PERMISSION } from "./helper/PermissionHelper";
import {
    REST_URL_AGENT,
    REST_URL_CONNECTION,
    REST_URL_USER
} from './helper/URLHelper';
import TableDataField from "./fields/TableDataField";
import TextField from "./fields/TextField";
import TableSwitchField from "./fields/TableSwitchField";
import EnumField from "./fields/EnumField";

import CardTable from "./extra/CardTable";
import MainContent from "./extra/MainContent";
import EventComponent from "./extra/EventComponent";

const tZones = timezones();

const maxLengths = MAX_LENGTH.USER;

const mandatory = {}

const disabled = DISABLED.USER;

const copyable = COPY.USER;

const multiple = MULTIPLE.USER;

const titles = TITLES.USER;

const DELETE_USER_DIALOG = "DELETE_USER_DIALOG";
const UNLOCK_USER_DIALOG = "UNLOCK_USER_DIALOG";

const permissionTypes = PERMISSION.USER.types;
const permissionUrl = PERMISSION.USER.url;
export default class AdminUserEditPage extends EventComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            attemptedSave: false,
            forbidden: false,
            loading: true
        };
    }

    componentDidMount() {
        checkAllPermissions(permissionUrl,permissionTypes).then(result=> {
            const state = this.state;
            for(const key in result){
                state['permission_'+key] = result[key];
            }
            this.setState({...state},this.initWithUserId())
        });
    }

    initWithUserId(){
        if(this.state.permission_view !== null && this.state.permission_view === false)  
            this.props.history.replace({ pathname: `/`});

        const userId = this.props.match.params.userId;
        
        get(`${REST_URL_USER}/${userId}`)
            .then((a) => {
                this.convertFullUserToState(a);
            })
            .catch(err => handleApiError(err, this.context, () => this.setState({forbidden: true})))
            .finally(() => this.setState({loading: false}));
        get(`${REST_URL_USER}/${userId}/zendeskId`)
            .then((a) => this.setState({zendeskId: a}))
            .catch(err => handleApiError(err, this.context, null));
        get(`${REST_URL_CONNECTION}/${userId}/distribution`)
            .then((response) => {
                if(response?.points){
                    this.setState({
                        connectionStats:response.points
                    })
                }
            })
            .catch(err => handleApiError(err, this.context, null))
        get(`${REST_URL_AGENT}/${userId}/distribution`)
            .then((response) => {
                if(response?.points){
                    this.setState({
                        agentsStats:response.points
                    })
                }
            })
            .catch(err => handleApiError(err, this.context, null)) 
        get(`/rest/admin/subscriptions/mobile/${userId}`)
            .then((response) => {
                if(response?.data){
                    this.setState({
                        mobileStats:response.data
                    })
                }
            })
            .catch(err => handleApiError(err, this.context, null))
        get(`/rest/admin/subscriptions/web/${userId}`)
            .then((response) => {
                if(response?.data){
                    this.setState({
                        webStats:response.data
                    })
                }
            })
            .catch(err => handleApiError(err, this.context, null))        
    }

    convertFullUserToState(response) {
        const newState = this.state;
        if (response) {
            const user = response;
            newState.password = user.password;
            newState.name = user.name;
            newState.firstName = user.firstName;
            newState.lastName = user.lastName;
            newState.email = user.email;
            newState.timezone = user.timezone;
            newState.googleUserId = user.googleUserId;
            newState.facebookUserId = user.facebookUserId;
            newState.appleId = user.appleId;
            newState.jobTitle = user.jobTitle;

            if(user.timezone)
                newState.timezone = convertEnumToState(tZones.map(v => {return {label:v, value:v}}), user.timezone);
            if(user.profilePictureUrl && user.profilePictureUrl.startsWith('images'))
                newState.profilePictureUrl = APP_FING_URL + '/' + user.profilePictureUrl;
            else if(user.profilePictureUrl && user.profilePictureUrl.startsWith('https'))
                newState.profilePictureUrl = user.profilePictureUrl;
            else { newState.profilePictureUrl = APP_FING_URL + '/images/avatar/avatar-4.png'; }
            if(user.techAttitude)
                newState.techAttitude = convertEnumToState(ENUMS.USER.TECH_ATTITUDE,user.techAttitude);
            newState.professionalField = user.professionalField;
            if(user.registrationDate)
                newState.registrationDate = convertDateToState(new Date(user.registrationDate));
            newState.verified = user.verified;
            if(user.accountType)
                newState.accountType = convertEnumToState(ENUMS.USER.ACCOUNT_TYPE,user.accountType);
            newState.subscribedToNewsletter = user.subscribedToNewsletter;
            newState.subscribedToContent = user.subscribedToContent;
            newState.enableDigitalPresenceStats = user.enableDigitalPresenceStats;
            newState.alertEmail = user.alertEmail;
            if(user.alertMailType)
                newState.alertMailType = convertEnumToState(ENUMS.USER.ALERT_MAIL_TYPE,user.alertMailType);
            newState.networkCount = String(user.networkCount);
            if(user.alertMessageType)
                newState.alertMessageType = convertEnumToState(ENUMS.USER.ALERT_MESSAGE_TYPE,user.alertMessageType);
            newState.fadNodeId = user.fadNodeId;
            if(user.userRoles)
                newState.userRoles = convertMultiEnumToState(ENUMS.USER.ROLES, user.userRoles);
            newState.countryCode = user.countryCode;
            newState.countryRegion = user.countryRegion;
            newState.countryCity = user.countryCity;
            newState.isp = user.isp;
            if(user.ispAlertType)
                newState.ispAlertType = convertEnumToState(ENUMS.USER.ISP_ALERT_TYPE,user.ispAlertType);
            newState.languageCode = user.languageCode;
            newState.companyName = user.companyName;
            newState.recogCount = String(user.recogCount);
            newState.failedLoginAttempts = String(user.failedLoginAttempts);
            newState.lockoutUntil = user.lockoutUntil;
            newState.stripeCustomerId = user.stripeCustomerId;
            newState.stripeActiveSubscriptionId = user.stripeActiveSubscriptionId;
            
        }
        this.setState(newState);
    }

    // --------------------------------------------------------------------------------
    // MAIN RENDERING
    // --------------------------------------------------------------------------------

    render() {
        const onSave = ()=>this.saveUser();
        const onUnlock = () => showDialogById(UNLOCK_USER_DIALOG);
        const onDelete = ()=> showDialogById(DELETE_USER_DIALOG);
        
        return (
            <MainContent history={this.props.history}>
                <div className="container-fluid space-1 px-4">
                    <div className="d-flex border-bottom mb-6">
                        <div>
                            <h2 className="d-inline-block">Edit User</h2>
                            <span className="text-secondary mx-2">/</span>
                            <Link to="/admin/users">Back to Users</Link>
                        </div>
                        <div className="ml-auto">
                            {this.state.permission_delete && <button className="btn btn-sm btn-danger mr-2" onClick={onDelete}>Delete</button>}

                            {this.state.permission_edit && this.state.lockoutUntil &&
                                <button className="btn btn-sm btn-warning mr-2" onClick={onUnlock}>Unlock</button>}

                            {this.state.permission_edit && <button className="btn btn-sm btn-primary" onClick={onSave}>Save</button>}
                        </div>
                    </div>
                    <ToastContainer/>
                    {this.renderDeleteUserDialog()}
                    {this.renderUnlockUserDialog()}
                    {this.renderForm()}
                </div>
            </MainContent>
            
        );
    }
    
    saveUser() {

        // === VALIDATE ===
        if(!controlMandatoryFields(this.state,mandatory,this.setState({attemptedSave: true}))) 
            return
        if(!controlMaxLengthFields(this.state,maxLengths,this.setState({attemptedSave: true})))
            return
        
        // === SEND ===

        let body = {};

        const userId = this.props.match.params.userId;

        body = {
            userId: userId,
            name: this.state.name,
            password: this.state.password,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            timezone: convertStateToEnum(this.state.timezone),
            googleUserId: this.state.googleUserId,
            facebookUserId: this.state.facebookUserId,
            appleId: this.state.appleId,
            jobTitle: this.state.jobTitle,
            techAttitude: convertStateToEnum(this.state.techAttitude),
            professionalField: this.state.professionalField,
            registrationDate: convertStateToDate(this.state.registrationDate),
            verified: this.state.verified,
            accountType: convertStateToEnum(this.state.accountType),
            subscribedToNewsletter: this.state.subscribedToNewsletter,
            subscribedToContent: this.state.subscribedToContent,
            enableDigitalPresenceStats: this.state.enableDigitalPresenceStats,
            alertEmail: this.state.alertEmail,
            profilePictureUrl: this.state.profilePictureUrl,
            alertMailType: convertStateToEnum(this.state.alertMailType),
            networkCount: Number(this.state.networkCount),
            alertMessageType: convertStateToEnum(this.state.alertMessageType),
            fadNodeId: this.state.fadNodeId,
            userRoles: convertStateToMultiEnum(this.state.userRoles),
            countryCode: this.state.countryCode,
            countryRegion: this.state.countryRegion,
            countryCity: this.state.countryCity,
            isp: this.state.isp,
            ispAlertType: convertStateToEnum(this.state.ispAlertType),
            languageCode: this.state.languageCode,
            companyName: this.state.companyName,
            recogCount: Number(this.state.recogCount),
            failedLoginAttempts: Number(this.state.failedLoginAttempts),
            lockoutUntil: this.state.lockoutUntil,
            stripeCustomerId: this.state.stripeCustomerId,
            stripeActiveSubscriptionId: this.state.stripeActiveSubscriptionId
        }

        this.setState({loading: true, attemptedSave: false});
        put(`${REST_URL_USER}/${userId}`, body)
            .then((a) => {
                if (a) {
                    successToast("User saved");
                    this.convertFullUserToState(a);
                    if (userId) {
                        this.props.history.replace({ pathname: `/admin/user/${userId}`})
                    }
                }
            })
            .catch((err) => {
                failedToast("Unable to save user");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    deleteUser() {
        const userId = this.props.match.params.userId;

        this.setState({loading: true});
        remove(`${REST_URL_USER}/${userId}`)
            .then((result) => {
                successToast("User removed");
                this.props.history.replace({pathname: `/admin/users`});
            })
            .catch((err) => {
                failedToast("Unable to delete user");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    unlockUser() {
        const userId = this.props.match.params.userId;

        this.setState({loading: true});
        put(`${REST_URL_USER}/${userId}/unlock`)
            .then((result) => {
                successToast("User unlocked");
                this.props.history.replace({pathname: `/admin/users`});
            })
            .catch((err) => {
                failedToast("Unable to unlock user");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    // -------------------------------------------------------------------------------------------------

    renderForm() {
        const {connectionStats, agentsStats, mobileStats, webStats} = this.state;
        const userId = this.props.match.params.userId;

        const CONNECTION_URL = "/admin/connections?search=";
        const AGENT_URL = "/admin/agents?search=";

        const STRIPE_URL = "https://dashboard.stripe.com/login?redirect=";

        const connectionUrl = (clientType) => {
            return `${CONNECTION_URL}${userId}&clientType=${clientType}`;
        }
        const agentUrl = (connectionState) => {
            return `${AGENT_URL}${userId}&connectionState=${connectionState}`;
        }

        const stripeCustomerUrl = `${STRIPE_URL}%2Fcustomers%2F${this.state.stripeCustomerId}`;

        const stripeSubscriptionUrl = `${STRIPE_URL}%2Fsubscriptions%2F${this.state.stripeActiveSubscriptionId}`;

        let allStats = [];
        if(mobileStats?.length > 0){
            allStats = [...mobileStats];
        } 
        if(webStats?.length > 0){
            webStats.forEach(item => item.platform = "STRIPE");
            allStats = [...allStats,...webStats];
        }

        return (
            <form>
                <div className="row">
                    <div className="col-8">
                        <div className="card card-bordered mb-2">
                            <div className="card-body p-2">
                                <div className="d-flex align-items-center justify-content-start">
                                    <div className="avatar avatar-lg avatar-circle text-center m-2 mr-6">
                                        <img 
                                            className="avatar-img img-fluid bg-primary80" 
                                            src={this.state.profilePictureUrl}/>
                                    </div>
                                    <div className="w-100">
                                        {this.renderTextField("name")}
                                    </div>
                                    
                                </div>
                                <MultipleField className="col-6">
                                    {this.renderTextField("firstName")}
                                    {this.renderTextField("lastName")}
                                </MultipleField>
                                <MultipleField className="col-6">
                                    {this.renderTextField("email")}
                                    {this.renderEnumField("timezone", tZones.map(v => {return {label:v, value:v}}))}
                                </MultipleField>
                            
                                <MultipleField className="col-6">
                                    {this.renderTextField("jobTitle")}
                                    {this.renderEnumField("techAttitude", ENUMS.USER.TECH_ATTITUDE)}
                                </MultipleField>
                                <MultipleField className="col-6">
                                    {this.renderTextField("professionalField")}
                                    {this.state.permission_edit ? 
                                        this.renderEnumField("accountType", ENUMS.USER.ACCOUNT_TYPE) :
                                        this.renderTextField("accountType")
                                    }
                                </MultipleField>
                                <MultipleField className="col-6">
                                    {this.state.permission_edit ? 
                                        this.renderEnumField("userRoles", ENUMS.USER.ROLES) :
                                        this.renderTextField("userRoles")
                                    }
                                    {this.renderTextField("companyName")}
                                </MultipleField>
                                <MultipleField className="col-6">
                                    {this.renderTextField("countryCode")}
                                    {this.renderTextField("countryRegion")}
                                </MultipleField>
                                <MultipleField className="col-6">
                                    {this.renderTextField("countryCity")}
                                    {this.renderTextField("isp")}
                                </MultipleField>
                                <MultipleField className="col-6">
                                    {this.renderTextField("alertEmail")}
                                    {this.renderTextField("languageCode")}
                                </MultipleField>
                                <MultipleField className="col-6">
                                    {this.renderEnumField("ispAlertType", ENUMS.USER.ISP_ALERT_TYPE)}
                                    {this.renderEnumField("alertMailType", ENUMS.USER.ALERT_MAIL_TYPE)}
                                </MultipleField>
                                <div className="row">
                                    <div className="col-6">
                                        {this.renderEnumField("alertMessageType", ENUMS.USER.ALERT_MESSAGE_TYPE)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {allStats?.length > 0 && <div className="card card-bordered mb-2">
                            <div className="card-header bg-soft-primary p-2">
                                Subscriptions
                            </div>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        {React.Children.toArray(HEADERS.SUBSCRIPTION.map(val => 
                                            <th className="h6 text-dark p-2" scope="col">
                                                <span className="d-flex align-items-center">
                                                    {val.displayField}
                                                </span>
                                            </th>))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {allStats.map(m => <tr>
                                        {React.Children.toArray(HEADERS.SUBSCRIPTION.map(val => {
                                            if(val.name === "platform" || val.name === "state"){
                                                return <td className="p-2">{m[val.name]}</td>
                                            } 
                                            return <td className="p-2">{convertDateToState(new Date(m[val.name]))}</td>
                                        }))}
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>}
                        
                    </div>
                    <div className="col-4">
                        <CardTable title="Stripe info" className="mb-2">
                            <TableField title="Customer ID" stats={this.state.stripeCustomerId} url={stripeCustomerUrl}/>
                            <TableField title="Active Sub ID" stats={this.state.stripeActiveSubscriptionId} url={stripeSubscriptionUrl}/>
                            
                        </CardTable>
                        <CardTable title="User info" className="mb-2">
                            {this.renderDataField("zendeskId")}
                            {this.renderDataField("googleUserId")}
                            {this.renderDataField("facebookUserId")}
                            {this.renderDataField("appleId")}
                            {this.renderDataField("fadNodeId")}
                            {this.renderDataField("networkCount")}
                            {this.renderDataField("recogCount")}
                            {this.renderDataField("registrationDate")}
                            {this.renderDataField("failedLoginAttempts")}
                            {this.renderSwitchField("subscribedToNewsletter")}
                            {this.renderSwitchField("enableDigitalPresenceStats")}
                            {this.renderSwitchField("subscribedToContent")}
                            {this.renderSwitchField("verified")}
                        </CardTable>

                        {connectionStats && 
                            <CardTable title="Connections" className="mb-2" 
                                footer={<Link to={`${CONNECTION_URL}${userId}`}>See all</Link>}>
                                <TableField title="Desktop" stats={connectionStats} url={connectionUrl("DESKTOP")}/>
                                <TableField title="Mobile" stats={connectionStats} url={connectionUrl("MOBILE")}/>
                                <TableField title="Sentinel" stats={connectionStats} url={connectionUrl("SENTINEL")}/>
                                <TableField title="Browser" stats={connectionStats} url={connectionUrl("BROWSER")}/>
                                <TableField title="Embedded" stats={connectionStats} url={connectionUrl("EMBEDDED")}/>
                            </CardTable>}
                        
                        {agentsStats && 
                            <CardTable title="Agents" className="mb-2" 
                                footer={<Link to={`${AGENT_URL}${userId}`}>See all</Link>}>
                                <TableField title="Connected" stats={agentsStats} url={agentUrl("CONNECTED")}/>
                                <TableField title="Disconnected" stats={agentsStats} url={agentUrl("DISCONNECTED")}/>
                            </CardTable>}
                        
                    </div>
                </div>
            </form>
        );
    }

    renderDataField(field) {
        return <TableDataField 
            value={this.state[field] || ''} 
            label={titles[field]} 
            copyable={copyable[field]}/>
    }
    

    renderTextField(id, dis) {
        return <TextField
            id={id}
            className="mb-3"
            value={this.state[id] || ''}
            attemptedSave={this.state.attemptedSave}
            label={titles[id]}
            mandatory={mandatory[id] === true}
            maxLength={maxLengths[id] || 0}
            onChange={this.handleInputFieldChange}
            disabled={dis || disabled[id]}/>
    }

    renderEnumField(id, enumValues) {
        return <EnumField
            id={id}
            mandatory={mandatory[id] === true}
            isMulti={multiple[id] || false}
            attemptedSave={this.state.attemptedSave}
            value={this.state[id]}
            label={titles[id]}
            enumValues={enumValues}
            onChange={this.handleSelectFieldChange}/>
    }

    renderSwitchField(id) {
        return <TableSwitchField 
            id={id} 
            switchSpan={2}
            disabled={disabled[id]}
            value={this.state[id] || false}
            label={titles[id]}
            onChange={(event)=>this.handleSwitchFieldChange(event)}/>
    }

    renderDeleteUserDialog() {
        const onClose = () => hideDialogById(DELETE_USER_DIALOG);
        const onDelete = () => {
            hideDialogById(DELETE_USER_DIALOG);
            this.deleteUser();
        };
        return <ModalDialog id={DELETE_USER_DIALOG} title={"Confirm Delete user"} onClose={onClose}>
            <ModalDialogBody className="py-3">
                Are you sure you want to delete this user?
            </ModalDialogBody>
            <ModalDialogFooter className="py-3">
                <button className="btn btn-xs btn-ghost-primary" onClick={onClose}>Close</button>
                <button className="btn btn-xs btn-danger" onClick={onDelete}>Yes</button>
            </ModalDialogFooter>
        </ModalDialog>;
    }

    renderUnlockUserDialog() {
        const onClose = () => hideDialogById(UNLOCK_USER_DIALOG);
        const onUnlock = () => {
            hideDialogById(UNLOCK_USER_DIALOG);
            this.unlockUser();
        };
        return <ModalDialog id={UNLOCK_USER_DIALOG} title={"Confirm Unlock user"} onClose={onClose}>
            <ModalDialogBody className="py-3">
                Are you sure you want to unlock this user?
            </ModalDialogBody>
            <ModalDialogFooter>
                <button className="btn btn-xs btn-ghost-danger" onClick={onClose}>No</button>
                <button className="btn btn-xs btn-primary" onClick={onUnlock}>Yes</button>
            </ModalDialogFooter>
        </ModalDialog>;
    }

}

const MultipleField = (props) => {
    const body = props.children.map(col => 
        <div className={props.className}>{col}</div>
    )
    return (
        <div className="row">
            {React.Children.toArray(body)}
        </div>
    );
}

const TableField = (props) => {
    let value = 0;
    let label = '';

    if(props?.stats){
        if(Array.isArray(props.stats)){
            if(props.stats.length > 0){
                const statsFiltered = props.stats.filter(val => val.name === props.title)
                if(statsFiltered[0]){
                    value = statsFiltered[0].value;
                } 
            }
        }    
    }
    if(props?.label){
        label = props.label;
    }
    const link = <Link to={props.url}>{value || label}</Link>;
    return (
        <tr className={props.className}>
            <td className="p-2" style={{width:"170px"}}>{props.title}</td>
            <td className="p-2">{value > 0 || label ? link : 'N/A'}</td>
        </tr>
    );
}
