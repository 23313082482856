/**
 * Created by marco on 28/03/2020.
 *
 * (C) Copyright Fing
 */

import React, {Component} from 'react';
import {Link} from "react-router-dom";

import {get} from "../services/APIService";
import {getPrettyLanguage} from '../helpers/AccountHelper';

export default class AccountNotificationsSummary extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userSettings: null
        };
    }

    componentDidMount() {

        get('/rest/users/settings')
            .then((res) => {
                this.setState({userSettings: res})
            })
            .catch((error) => console.log('Error get settings: ', error));
    }


    render() {
        const {userSettings} = this.state;

        if (!userSettings) return null;

        const userNotifications = userSettings.userNotifications;

        const hasEmailEnabled = userNotifications.alertMailType && userNotifications.alertMailType !== "DISABLED";
        const hasPushEnabled = userNotifications.alertMessageType && userNotifications.alertMessageType !== "DISABLED";

        let netNotifText = "Disabled";
        if (hasEmailEnabled && hasPushEnabled) netNotifText = "Email+Mobile";
        else if (hasEmailEnabled) netNotifText = "Email only";
        else if (hasPushEnabled) netNotifText = "Mobile only";

        const Row = ({title, value}) => <div className="row align-items-baseline">
            <div className="col-lg-4">
                <label className="input-label mt-0">{title}</label>
            </div>
            <div className="col-lg-8">
                {value}
            </div>
        </div>

        return <div className="card card-bordered">
            <div className="card-body">
                {userNotifications &&
                    <>
                        <Row title="Language" value={userNotifications.languageCode ? getPrettyLanguage(userNotifications.languageCode) : "English"} />
                        <Row title="Newsletter" value={userNotifications.subscribedToNewsletter ? "Enabled" : "Disabled"} />
                        <Row title="Content Updates" value={userNotifications.subscribedToContent ? "Enabled" : "Disabled"} />
                        <Row title="Network events" value={netNotifText} />
                        <Row title="ISP events" value={!userNotifications.ispAlertType || userNotifications.ispAlertType === "DISABLED" ?
                            "Disabled" : "Enabled in my area"} />
                    </>}

                <div className="row mt-3">
                    <div className="col-sm-12">
                        <Link className="btn btn-xs btn-primary"
                              to="/account/notifications/edit">
                            Edit Notification Settings</Link>
                    </div>
                </div>
            </div>
        </div>;
    }

}
