/**
 * Created by marco on 28/03/2020.
 *
 * (C) Copyright Fing
 */

import React, {Component} from 'react';
// import intl from 'react-intl-universal';
import {get} from "../services/APIService";
import WaitingComponent from '../components/WaitingComponent';
import {Link} from "react-router-dom";
import IftttImg from "../assets/svg/other/ifttt_48.svg"
import ZapierImg from "../assets/svg/other/zapier_48.svg"
import GoogleAssistantImg from "../assets/svg/other/google_assistant_48.svg"

export default class AccountAppsSummary extends Component {

    constructor(props) {
        super(props);

        this.state = {
            apps: null
        };
    }

    componentDidMount() {
        get("/rest/users/apps")
            .then((res) => {
                this.setState({ apps: res.data });
            })
            .catch((e) => {
                console.log('Error in get auth apps: ', e);
                this.setState({ apps: [] })
            })
    }

    renderItem(img, title, subtitle) {
        return <li className="list-group-item" key={title}>
            <div className="row align-items-md-center">
                <div className="col-md-8 mb-5 mb-md-0">
                    <div className="media align-items-center">
                        <div className="avatar">
                            <img src={img} />
                        </div>
                        <div className="media-body font-size-1 ml-3">
                            <h4 className="mb-0">{title}</h4>
                            <span className="d-block text-muted">{subtitle}</span>
                        </div>
                    </div>
                </div>
            </div>
        </li>;
    }

    render() {
        let { apps } = this.state;

        if (!apps) return <div className="card card-bordered">
            <div className="card-body">
                <WaitingComponent />
            </div>
        </div>;

        if (apps && apps.length === 0) {
            return <div className="card card-bordered">
                <div className="card-body text-center">
                    You don't have any authorized app
                </div>
            </div>
        }

        let appsPreviews = apps.slice(0, apps.length <= 3 ? apps.length : 3);
        // Temporary fix until we update the core libs with latest image URL
        const imageForApp = (app) => {
            switch (app.clientName) {
                case "IFTTT":
                    return IftttImg;
                case "Zapier":
                    return ZapierImg;
                case "Actions on Google":
                    return GoogleAssistantImg;
                default:
                    return app.clientImageUrl;
            }
        }

        return (
            <div className="card card-bordered">
                <ul className="list-group list-group-flush my-3">
                    {appsPreviews.map((app) => {
                        return this.renderItem(imageForApp(app), app.clientName,app.sessionCount > 0 ?
                                    <div>
                                        <p className="my-2"><strong className="text-dark">{app.sessionCount} </strong> total sessions</p>
                                        {app.activeSessionCount > 0 ?
                                            <p className="my-2"><strong className="text-dark">{app.activeSessionCount} </strong> active sessions</p> :
                                            ""
                                        }
                                    </div> :
                                    <span className="muted my-3">You dont't have any session with this app</span>);
                        })}
                </ul>
                <div className="card-body">
                    <Link className="btn btn-xs btn-primary mb-1 transition-3d-hover" to="/account/apps">
                        Manage your apps</Link>
                </div>
            </div>
        )
    }

}