export const COPY = {
    AGENT:{
        agentId: true,
        masterUserId: true,
        shardId: true,
        networkId: true,
        agentToken: true,
        agentSerialNo: true,
        lastActivationUserId: true,
        lastDeactivationUserId: true
    },
    CONNECTION: {
        loginProvidernotificationToken: true,
        apnsToken: true,
        userId: true,
        clientId: true,
    },
    USER: {
        stripeCustomerId: true,
        stripeActiveSubscriptionId: true,
        googleUserId: true,
        zendeskId: true,
        facebookUserId: true,
        appleId: true,
        fadNodeId: true
    },
    VALIDATION: {
        userId: true,
        gatewayHwAddress: true,
        blockingClientHwAddress: true,
        blockedDevices: true,
        publicIpAddress: true
    }
}