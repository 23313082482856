import React from "react";

import {createUrlWithParams, get, handleErrorFetch, put, remove} from "../../services/APIService";
import {ToastContainer} from "react-toastify";
import {failedToast, successToast} from "../../helpers/ToastHelper";
import ModalDialog, {hideDialogById, ModalDialogBody, ModalDialogFooter, showDialogById} from "../dialog/ModalDialog";
import SearchBar from "../SearchBar";
import {Link} from "react-router-dom";
import { convertDateToState, convertEnumToState, convertStateToDate, convertStateToEnum } from "./helper/ConversionHelper";
import { controlMandatoryFields, controlMaxLengthFields } from "./helper/ControlHelper";
import { handleApiError } from "./helper/ErrorHelper";
import { ENUMS } from "./helper/EnumHelper";
import { MANDATORY } from './helper/MandatoryHelper';
import { MAX_LENGTH } from './helper/MaxLengthHelper';
import { checkAllPermissions, PERMISSION } from "./helper/PermissionHelper";
import { REST_URL_POST } from "./helper/URLHelper";
import TextField from "./fields/TextField";
import EnumField from "./fields/EnumField";
import AreaField from "./fields/AreaField";
import DateField from "./fields/DateField";

import MainContent from "./extra/MainContent";
import EventComponent from "./extra/EventComponent";

const formGroupCls = "form-group mb-3";

const maxLengths = MAX_LENGTH.POST;

const mandatory = MANDATORY.POST;

const titles = {
    contentUrl: "Content URL",
    title: "Title",
    description: "Description",
    previewImageUrl: "Preview Image URL",
    publishDate: "Publish Date",
    source: "Source website",
    sourceIconURL: "Source Icon URL",
    category: "Category",
    format: "Format",
    languageCode: "Language",
    state: "State",
    insertDate: "Insert Date",
    curatorScore: "Curator Score",
    curatorComment: "Curator Comment"
}

const ADD_LINK_DIALOG = "ADD_LINK_DIALOG";
const DELETE_POST_DIALOG = "DELETE_POST_DIALOG";

const permissionTypes = PERMISSION.POST.types;
const permissionUrl = PERMISSION.POST.url;
export default class AdminPostEditPage extends EventComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            attemptedSave: false,
            forbidden: false,
            loading: true
        };
    }

    componentDidMount() {
        checkAllPermissions(permissionUrl,permissionTypes).then(result=> {
            const state = this.state;
            for(const key in result){
                state['permission_'+key] = result[key];
            }
            this.setState({...state},this.initWithPostId())
        }).catch((err) => {
            this.props.history.replace({ pathname: `/`});
            handleApiError(err, this.context, () => this.setState({forbidden: true}));
        });
        
    }

    initWithEmptyReactState() {
        const d = new Date();
        this.setState({
            contentUrl: "",
            format: ENUMS.POST.FORMAT[0],
            state: ENUMS.POST.STATE[0],
            languageCode: ENUMS.POST.LANGUAGE[0],
            insertDate: convertDateToState(d),
            curatorScore: 0,
            links: {}
        });
    }

    initWithPostId() {
        if(this.state.permission_view !== null && this.state.permission_view === false) 
            this.props.history.replace({ pathname: `/`});
        const postId = this.props.match.params.postId;

        if (postId && Number(postId) > 0) {
            get(`${REST_URL_POST}/${postId}`)
                .then((fullPost) => {
                    if (fullPost) {
                        this.convertFullPostToState(fullPost);
                    }
                })
                .catch((err) => {
                    failedToast("Unable to read post");
                    handleApiError(err, this.context, () => this.setState({forbidden: true}));
                })
                .finally(() => this.setState({loading: false}));
        } else {
            this.initWithEmptyReactState();
        }
        
    }

    convertFullPostToState(response) {
        const newState = {}
        if (response.post) {
            const post = response.post;
            if (post.contentUrl) newState.contentUrl = post.contentUrl;
            if (post.title) newState.title = post.title;
            if (post.description) newState.description = post.description;
            if (post.previewImageUrl) newState.previewImageUrl = post.previewImageUrl;
            if (post.source) newState.source = post.source;
            if (post.sourceIconURL) newState.sourceIconURL = post.sourceIconURL;
            if (post.curatorScore) newState.curatorScore = post.curatorScore.toString();
            if (post.curatorComment) newState.curatorComment = post.curatorComment;
            if (post.publishDate) {
                newState.publishDate = convertDateToState(new Date(post.publishDate));
            }
            if (post.insertDate) {
                newState.insertDate = convertDateToState(new Date(post.insertDate));
            }
            if (post.format) {
                newState.format = convertEnumToState(ENUMS.POST.FORMAT, post.format);
            }
            if (post.category) {
                newState.category = convertEnumToState(ENUMS.POST.CATEGORY, post.category);
            }
            if (post.languageCode) {
                newState.languageCode = convertEnumToState(ENUMS.POST.LANGUAGE, post.languageCode);
            }
            if (post.state) {
                newState.state = convertEnumToState(ENUMS.POST.STATE, post.state);
            }
            if (response.links) {
                newState.links = {}
                response.links.forEach(link => newState.links[`${link.entityType}:${link.entityId}`] = link);
            }
            newState.counters = {
                openCount: post.openCount || 0,
                likeCount: post.likeCount || 0,
                dislikeCount: post.dislikeCount || 0,
                outdatedCount: post.outdatedCount || 0,
            };
        }
        this.setState(newState);
    }

    // --------------------------------------------------------------------------------
    // MAIN RENDERING
    // --------------------------------------------------------------------------------

    render() {
        const postId = this.getCurrentPostId();

        const onSave = ()=>this.savePost();
        const onDelete = ()=> {
            showDialogById(DELETE_POST_DIALOG);
        };
        return (
            <MainContent history={this.props.history}>
                <div className="container-fluid space-1 px-4">
                    <div className="d-flex border-bottom mb-6">
                        <div>
                            <h2 className="d-inline-block">Edit Post</h2>
                            <span className="text-secondary mx-2">/</span>
                            <Link to="/admin/posts">Back to Posts</Link>
                        </div>
                        <div className="ml-auto">
                            {this.state.permission_edit && postId >= 0 && 
                                <button className="btn btn-sm btn-danger mr-2" onClick={onDelete}>Delete</button>}
                            {this.state.permission_edit && <button className="btn btn-sm btn-primary" onClick={onSave}>Save</button>}
                        </div>
                    </div>
                    <ToastContainer/>
                    {/*{this.dumpState()}*/}
                    {this.renderForm()}
                    {this.renderAddContentLinkDialog()}
                    {this.renderDeletePostDialog()}
                </div>
            </MainContent>
            
        );
    }

    getCurrentPostId() {
        let postId = -1
        if (this.props.match && this.props.match.params && this.props.match.params.postId)
            postId = Number(this.props.match.params.postId);
        if (isNaN(postId))
            postId = -1;
        return postId;
    }

    dumpState() {
        return <pre>{JSON.stringify(this.state, null, 4)}</pre>;
        // return <pre>{JSON.stringify(this.state, Object.keys(this.state).sort(), 4)}</pre>;
    }

    savePost() {
        const postId = this.getCurrentPostId();

        // === VALIDATE ===
        if(!controlMandatoryFields(this.state,mandatory,this.setState({attemptedSave: true}))) 
            return
        if(!controlMaxLengthFields(this.state,maxLengths,this.setState({attemptedSave: true})))
            return

        // === SEND ===

        const body = {};
        body.post = {
            postId: postId,
            publishDate: convertStateToDate(this.state.publishDate),
            title: this.state.title,
            description: this.state.description,
            previewImageUrl: this.state.previewImageUrl,
            contentUrl: this.state.contentUrl,
            source: this.state.source,
            sourceIconURL: this.state.sourceIconURL,
            category: convertStateToEnum(this.state.category),
            format: convertStateToEnum(this.state.format),
            languageCode: convertStateToEnum(this.state.languageCode),
            state: convertStateToEnum(this.state.state),
            // curatorUserId: this.state.curatorUserId
            insertDate: convertStateToDate(this.state.insertDate),
            // keywords: this.state.keywords,
            curatorScore: this.state.curatorScore,
            curatorComment: this.state.curatorComment,
            // calculatedScore: this.state.calculatedScore
        }

        if (this.state.links) {
            body.links = Object.values(this.state.links).map(link => (
                {
                    entityId: link.entityId,
                    entityType: link.entityType,
                    entityName: link.entityName
                }
            ));
        }

        this.setState({loading: true, attemptedSave: false});
        put(`${REST_URL_POST}/${postId}`, body)
            .then((fullPost) => {
                if (fullPost) {
                    successToast("Post saved");
                    this.convertFullPostToState(fullPost);
                    if (postId === -1 && fullPost.post && fullPost.post.postId) {
                        this.props.history.replace({ pathname: `/admin/post/${fullPost.post.postId}`})
                    }
                }
            })
            .catch((err) => {
                failedToast("Unable to save post");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    deletePost() {
        const postId = this.getCurrentPostId();
        if (postId < 0)
            return;

        // === SEND ===

        this.setState({loading: true});
        remove(`${REST_URL_POST}/${postId}`)
            .then((result) => {
                successToast("Post removed");
                this.props.history.replace({pathname: `/admin/posts`});
            })
            .catch((err) => {
                failedToast("Unable to delete post");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    // -------------------------------------------------------------------------------------------------

    renderForm() {
        let fillFromUrl = () => {
            let contentUrl = this.state.contentUrl;
            if (!contentUrl)
                return;

            this.setState({loading: true});
            const url = createUrlWithParams("/rest/admin/content/meta", {url: contentUrl});
            get(url)
                .then((response) => {
                    if (response) {
                        successToast("Metadata obtained");

                        const newState = {}
                        if (response.title) newState.title = response.title;
                        if (response.description) newState.description = response.description;
                        if (response.image) newState.previewImageUrl = response.image;
                        if (response.siteName) newState.source = response.siteName;
                        if (response.siteIcon) newState.sourceIconURL = response.siteIcon;
                        this.setState(newState);
                    }
                })
                .catch((err) => {
                    failedToast("Unable to autofill");
                    handleApiError(err, this.context, () => this.setState({forbidden: true}));
                })
                .finally(() => this.setState({loading: false}));
        };

        return (
            <form>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-6 mb-3">
                        <div className="card card-bordered h-100">
                            <div className="card-header bg-light p-2">
                                <div className="mb-0 d-flex align-items-baseline w-100"><h3 className="mb-0">Content</h3>
                                    <button type="button" disabled={!this.state.contentUrl} className={`ml-auto btn btn-xs btn-primary`}
                                       onClick={fillFromUrl}>Fill from URL</button></div>
                            </div>
                            <div className="card-body p-2">
                                {this.renderTextField("contentUrl")}
                                {this.renderTextField("title")}
                                {this.renderTextField("description")}
                                {this.renderTextField("previewImageUrl")}
                                {this.renderDateField("publishDate")}
                                {this.renderTextField("source")}
                                {this.renderTextField("sourceIconURL")}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-xl-3 mb-3">
                        <div className="card card-bordered h-100">
                            <div className="card-header bg-light p-2">
                                <div className="mb-0"><h3 className="mb-0">Topic organization</h3></div>
                            </div>
                            <div className="card-body p-2">
                                {this.renderEnumField("category", ENUMS.POST.CATEGORY)}
                                {this.renderEnumField("format", ENUMS.POST.FORMAT)}
                                {this.renderEnumField("languageCode", ENUMS.POST.LANGUAGE)}
                                {this.renderEnumField("state", ENUMS.POST.STATE)}
                                {this.renderDateField("insertDate")}
                                {this.renderTextField("curatorScore")}
                                {this.renderAreaField("curatorComment")}
                                {this.renderCounters(this.state.counters)}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-xl-3 mb-3">
                        <div className="card card-bordered h-100">
                            <div className="card-header bg-light p-2">
                                <div className="mb-0 d-flex align-items-baseline w-100"><h3 className="mb-0">Catalog
                                    Links</h3>
                                    <button type="button" className="ml-auto btn btn-xs btn-primary" onClick={() => {
                                        showDialogById(ADD_LINK_DIALOG);
                                    }}>Add</button></div>
                            </div>
                            <div className="card-body px-0">
                                {this.renderLinks(this.state.links)}
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        );
    }

    renderLinks(links) {
        if (!links) return '';

        const renderKeyValue = (key) => <tr key="key">
            <td className="px-2">{links[key].entityType}</td>
            <td className="px-2">{links[key].entityId}</td>
            <td className="px-2">{links[key].entityName}</td>
            <td><button type="button" className="btn btn-xs btn-ghost-secondary" onClick={()=>{
                this.setState((prevState) => {
                    let newLinks = prevState.links || {};
                    delete newLinks[key];
                    return ({links: newLinks});
                });
            }}><i className="fa fa-fw fa-times"/></button></td>
        </tr>;
        return <table className="table table-sm table-striped">
            <thead>
            <tr>
                <th className="px-2">Entity</th>
                <th className="px-2">ID</th>
                <th className="px-2">Name</th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {Object
                .keys(links)
                .map(key => renderKeyValue(key))}
            </tbody>
        </table>;
    }

    renderTextField(id) {
        return <TextField
            id={id}
            className="mb-3"
            value={this.state[id] || ''}
            attemptedSave={this.state.attemptedSave}
            label={titles[id]}
            mandatory={mandatory[id] === true}
            maxLength={maxLengths[id] || 0}
            onChange={this.handleInputFieldChange}
            disabled={false}/>
    }

    renderAreaField(id) {
        return <AreaField
            attemptedSave={this.state.attemptedSave}
            value={this.state[id]}
            label={titles[id]}
            maxLength={maxLengths[id] || 0}
            disabled={false}
            onChange={this.handleInputFieldChange}
            id={id}
            mandatory={mandatory[id]=== true}/>
    }

    renderDateField(id) {
        return <DateField
            id={id} 
            value={this.state[id]} 
            label={titles[id]} 
            onChange={this.handleInputFieldChange}
            disabled={false}
            mandatory={mandatory[id] === true}
            attemptedSave={this.state.attemptedSave}/>
    }

    renderEnumField(id, enumValues) {
        return <EnumField
            id={id}
            mandatory={mandatory[id] === true}
            isMulti={false}
            attemptedSave={this.state.attemptedSave}
            value={this.state[id]}
            label={titles[id]}
            enumValues={enumValues}
            onChange={this.handleSelectFieldChange}/>
    }

    renderAddContentLinkDialog() {
        const onClose = () => hideDialogById(ADD_LINK_DIALOG);
        let onSearch = (string) => {
            if (!string) {
                this.setState({catalogResponse: null});
                return;
            }

            const url = createUrlWithParams("/rest/admin/content/search", {filter: string, limit: 25});
            get(url)
                .then((response) => {
                    if (response) {
                        this.setState({catalogResponse: response});
                    }
                })
                .catch((err) => {
                    console.error(err);
                    failedToast("Unable to fetch data");

                    if (err && err.code === 401) {
                        let newContext = handleErrorFetch(this.context);
                        if (this.context.userContextUpdater)
                            this.context.userContextUpdater(newContext);
                    }
                })
                .finally(() => this.setState({loading: false}));
        };

        return <ModalDialog id={ADD_LINK_DIALOG} title={"Link Content to Catalog entries"} onClose={onClose} size="modal-lg">
            <ModalDialogBody className="py-3">
                <SearchBar id="post_edit_search_bar" onSearch={onSearch}/>
                {
                    this.state.catalogResponse ?
                        this.renderCatalogResultTable(this.state.catalogResponse) :
                        this.renderCatalogResultEmptyState()
                }
            </ModalDialogBody>
            <ModalDialogFooter className="py-3">
                <span className="mr-auto">{this.state.links ? Object.keys(this.state.links).length : 0} links selected</span>
                <button className="btn btn-xs btn-primary" onClick={onClose}>Done</button>
            </ModalDialogFooter>
        </ModalDialog>;
    }

    renderCatalogResultEmptyState() {
        return <div className="my-6 text-center">
            Start typing in the search bar above to query the Catalog
        </div>;
    }

    renderCatalogResultTable(catalogResponse) {
        const makeMatches = catalogResponse.make_matches;
        const makeReferences = catalogResponse.make_references;
        const devicesMatches = catalogResponse.devices_matches;
        const devicesReferences = catalogResponse.devices_references;
        const devicesTypeMatches = catalogResponse.devices_type_matches;
        const devicesTypeReferences = catalogResponse.devices_type_references;
        const row = (type, id, name, match) => {
            const key = `${type.toUpperCase()}:${id}`;
            const hasLink = this.state.links && this.state.links[key];
            return <tr key={key}>
                <td>{match ? "✅" : ""}</td>
                <td>{type}</td>
                <td>{id}</td>
                <td>{name}</td>
                <td className="text-right">
                    <button type="button" className="btn btn-xs btn-link" onClick={(evt) => {
                        evt.preventDefault();
                        if (hasLink) {
                            this.setState((prevState) => {
                                let newLinks = prevState.links || {};
                                delete newLinks[key];
                                return ({links: newLinks});
                            });
                        } else {
                            this.setState((prevState) => {
                                let newLinks = prevState.links || {};
                                let entityType;
                                switch (type.toUpperCase()) {
                                    case "FAMILY":
                                        entityType = "DEVICE";
                                        break;
                                    case "DEVICE TYPE":
                                        entityType = "TYPE";
                                        break;
                                    default:
                                        entityType = type.toUpperCase();
                                        break;
                                }
                                newLinks[key] = {
                                    entityType: entityType,
                                    entityId: id,
                                    entityName: name
                                };
                                return ({links: newLinks});
                            });
                        }
                    }
                    }>{hasLink ? "Remove" : "Add"}</button>
                </td>
            </tr>;
        }
        return <table className="table w-100">
            <thead>
            <tr>
                <th style={{width: "2.5rem"}}>Match</th>
                <th style={{width: "3.5rem"}}>Entity</th>
                <th style={{width: "3.5rem"}}>ID</th>
                <th>Name</th>
                <th style={{width: "3.5rem"}}/>
            </tr>
            </thead>
            <tbody>
            {
                makeMatches && makeMatches.map(item => row("Make", item.real_id, item.make_name, true))
            }
            {
                makeReferences && makeReferences.map(item => row("Make", item.real_id, item.make_name, false))
            }
            {
                devicesTypeReferences && devicesTypeReferences.map(item => row("Device Type", item.real_id, item.device_type_caption, false))
            }
            {
                devicesMatches && devicesMatches.filter(item => item.family === true).map(dev => row("Family", dev.real_id, dev.device_model, true))
            }
            {
                devicesMatches && devicesMatches.filter(item => !item.family).map(dev => row("Device", dev.real_id, dev.device_model, true))
            }
            {
                devicesReferences && devicesReferences.filter(item => item.family === true).map(dev => row("Family", dev.real_id, dev.device_model, false))
            }
            {
                devicesReferences && devicesReferences.filter(item => !item.family).map(dev => row("Device", dev.real_id, dev.device_model, false))
            }
            </tbody>
        </table>;
    }

    renderCounters(counters) {
        if (!counters || Object.keys(counters).length === 0) return '';

        const counterFor = (icon, label) => <div className="text-center mx-2">
            <i className={"d-block fa " + icon}/>
            <small>{label}</small>
        </div>
        return <div className={formGroupCls}>
            <label className="input-label">Counters</label>
            <div className="d-flex">
                {counterFor("fa-eye", `${counters["openCount"]}`)}
                {counterFor("fa-thumbs-up", `${counters["likeCount"]}`)}
                {counterFor("fa-thumbs-down", `${counters["dislikeCount"]}`)}
                {counterFor("fa-clock", `${counters["outdatedCount"]}`)}
            </div>
        </div>
    }

    renderDeletePostDialog() {
        const onClose = () => hideDialogById(DELETE_POST_DIALOG);
        const onDelete = () => {
            hideDialogById(DELETE_POST_DIALOG);
            this.deletePost();
        };
        return <ModalDialog id={DELETE_POST_DIALOG} title={"Confirm Delete post"} onClose={onClose}>
            <ModalDialogBody className="py-3">
                Are you sure you want to delete this post? All its content, links and the <b className="text-primary">counters</b> that users
                entered will be removed.
            </ModalDialogBody>
            <ModalDialogFooter className="py-3">
                <button className="btn btn-xs btn-ghost-danger" onClick={onDelete}>Yes, delete it entirely</button>
                <button className="btn btn-xs btn-primary" onClick={onClose}>No, keep the post</button>
            </ModalDialogFooter>
        </ModalDialog>;
    }

}