import React from "react";

// labelSpan, switchSpan -> How many columns takes the cell?


export default class TableSwitchField extends React.Component {

    render() {
        const {label,value, onChange, disabled, id, switchSpan, labelSpan} = this.props;
        return <tr>
            <td style={{width:'170px'}} colspan={labelSpan || 1} className="p-2 text-dark">{label}</td>
            <td colspan={switchSpan || 1} className="p-2 text-dark font-weight-bold h-100 my-auto ">
                <input 
                    type="checkbox"
                    className="text-center"
                    style={{width:14, margin:0, padding:0}}
                    checked={value}
                    disabled={disabled[id]}
                    onChange={onChange}
                    id={id}/>
            </td>
        </tr>
    }

}