import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { SessionContext } from "../SessionContext";

import { linkForIsp } from "./LinkHelper";
import { getFieldOrNull } from "../helpers/JsHelper";

import NavbarLogo from '../components/navbar/NavbarLogo';
import NavbarBody from '../components/navbar/NavbarBody';

export default class AppNavbar extends Component {

    // --------------------------------------------------------------------------------
    // ANCILLARY RENDER FUNCTION
    // --------------------------------------------------------------------------------

    renderCurrentProvider(session) {
        const isp = getFieldOrNull(session, 'accountIsp');
        if (!isp)
            return;
        const ispLink = linkForIsp(isp);
        return (
            <div className="mr-2">
                <div className="navbar-nav-item">
                    <Link to={ispLink} className="nav-link">
                        <span className="mr-2">{isp.name}</span>
                        <img className="img-fluid rounded-square" width="24" height="24" src={isp.logoImageUrl} alt="Provider" />
                    </Link>
                </div>
            </div>
        )
    }

    renderNav(sessionContext) {
        const session = sessionContext.session;
        return [
            this.renderCurrentProvider(session),
            // <li key="outage_help" className="navbar-nav-item d-md-flex">
            //     <Link className="nav-link d-block text-primary d-md-inline pl-2" to={"/help"} aria-haspopup="true" aria-expanded="false">
            //         <i className="fa fa-fw fa-question-circle" />
            //     </Link>
            // </li>
        ];
    }

    // --------------------------------------------------------------------------------
    // MAIN RENDER FUNCTION
    // --------------------------------------------------------------------------------

    render() {
        // Don't go to home page when clicking on the logo, in device recognition pages
        return (
            <header id="header" className={`header ${this.props.fillBackground ? "" : "header-bg-transparent"} header--show-hide-md`}
                data-header-fix-moment="400" data-header-fix-effect="slide">
                <div className="header-section">
                    <div id="logoAndNav" className="container">
                        <nav className="navbar navbar-expand-md px-3 py-2">
                            <NavbarLogo />
                            <NavbarBody className="nav flex-column flex-md-row" full>
                                <SessionContext.Consumer>
                                    {sessionContext => this.renderNav(sessionContext)}
                                </SessionContext.Consumer>
                            </NavbarBody>
                        </nav>
                    </div>
                </div>
            </header>
        );
    }
}