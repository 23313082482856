/**
 * Created by marco on 2/6/21.
 *
 * (C) Copyright Fing
 */

import React, {Component} from 'react';

/**
 * A control that consists of multiple options, mutually exclusive.
 * The outcome of selecting an item in the OptionBar is to switch among options, like a Select element with all
 * the elements being visualized at the same time (and therefore, must be in a limited number)
 */
export default class OptionBar extends Component {

    constructor(props, context) {
        super(props, context);
        this.groupName = "option-bar-" + (this.props.id || '');
        this.state = { activeIndex: this.props.activeIndex !== undefined ? this.props.activeIndex : -1 }
    }

    render() {
        const {children, className} = this.props;
        if (!children || children.length === 0)
            return '';

        const cName = className || '';
        const flexClass = this.props.vertical === true ? '' : "d-md-inline-flex align-middle";
        return (
            <div className={`${flexClass} ${cName}`}>
                {children.map((item, idx) => {
                    const selected = this.state.activeIndex === idx;
                    return this.renderItem(React.cloneElement(item, {index: idx, selected: selected}), idx);
                })}
            </div>
        );
    }

    renderItem(item, idx) {
        const {itemClassName, onOptionSelected, checkboxIcon} = this.props;
        const inputId = this.groupName + "-input-" + idx;

        const checkboxIconClass = checkboxIcon === false ? "" : "checkbox-icon";
        const checkboxIconInputClass = checkboxIcon === false ? "" : "checkbox-icon-input";
        const checkboxIconLabelClass = checkboxIcon === false ? "" : "checkbox-icon-label";

        const onInputChange = (e) => {
            if (onOptionSelected) onOptionSelected(idx);
            this.setState({activeIndex: idx});
        };

        const selected = this.state.activeIndex === idx;
        const cName = itemClassName || 'w-100 mr-2';
        const selectedClass = selected ? "bg-soft-primary text-primary" : "";
        return (
            <div key={inputId} className={`custom-control custom-radio custom-control-inline checkbox-outline bg-white ${checkboxIconClass} ${cName} mb-0 mb-sm-4`}>
                <input type="radio" id={inputId} name={this.groupName}
                       className={`custom-control-input checkbox-outline-input ${checkboxIconInputClass}`}
                       checked={selected}
                       onChange={onInputChange}
                />
                <label className={`checkbox-outline-label ${checkboxIconLabelClass} w-100 rounded-lg p-3 m-0 ${selectedClass}`}
                       htmlFor={inputId}
                       onChange={onInputChange}
                >
                    {item}
                </label>
            </div>
        );
    }

}