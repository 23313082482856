export const DISABLED = {
    AGENT: {
        agentId: true,
        agentPlatform: true,
        agentVersion: true,    
        userId: false,
        customName: true,
        activationDate: true,
        shardAssignmentDate: true,
        firstConnectionDate: true,
        lastConnectionDate: true,
        shardId: true,
        activationCode: true,
        agentToken: true,
        networkSignature: true,
        state: true,
        connectionState:  true,
        networkId: true,
        masterUserId: true,
        hardwarePlatform: true,
        makeBatch: true,
        agentSerialNo: true,
        customerTag: true,
        lastActivationUserId: true,
        lastActivationDate:  true,
        lastDeactivationUserId: true,
        lastDeactivationDate: true,
        lastDisconnectionDate: true,
        fingboxType: true,
        adminState: true,
        notes: false,
        geoCountryCode: true,
        geoCountryCity: true,
        agentChannel: true,
        firstActivationDate: true
    },
    CONNECTION: {
        userId: true,
        clientId: true,
        clientName: false,
        clientType: true,
        clientVersion: true,
        clientPlatform: true,
        firstAccess: true,
        lastAccess: true,
        lastAccessAddress: true,
        loginProvidernotificationToken: true,
        apnsToken: true
    },
    OFFER: {
        description: false,
        promoText: false,
        startDate: false,
        endDate: false,
        enabled: false,
        discountPlatform: false,
        allowedPlans: false,
        allowedCountries: false,
        purchaseRules: false,
        discountId: false 
    },
    USER: {
        name: false,
        firstName: false,
        lastName: false, 
        email: false,
        timezone: false,
        googleUserId: true,
        facebookUserId: true,
        appleId: true,
        jobTitle: true,
        techAttitude: false,
        professionalField: true,
        registrationDate: true,
        verified: false,
        subscribedToNewsletter: false,
        subscribedToContent: false,
        enableDigitalPresenceStats: false,
        alertEmail: false,
        alertMailType: false,
        networkCount: true,
        alertMessageType: false,
        fadNodeId: true,
        countryCode: false,
        countryRegion: false,
        countryCity: false,
        isp: false,
        ispAlertType: false,
        languageCode: false,
        companyName: false,
        recogCount: true,
        failedLoginAttempts: true,
        stripeCustomerId: true,
        stripeActiveSubscriptionId: true
    },
    VALIDATION: {
        comment: false,
        validationState: false,
        blockingClientHwAddress: true,
        blockingClientId: true,
        creationDate: true,
        userId: true,
        gatewayHwAddress: true,
        blockedDevices: true,
        blockedDevicesCount: true,
        publicIpAddress: true,
        termsAcceptanceDate: true,
        itDevicesCount: true,
        protectionDevicesCount: true,
        networkingDevicesCount: true,
        smartHomeDevicesCount: true,
        digitalMediaDevicesCount: true,
        personalDevicesCount: true,
        devicesCount: true
    }
}