import React from 'react';
import intl from 'react-intl-universal';

import {getLinkForSignIn, hasUserConnectedDevices, isUserSignedIn, UserContext} from "../UserContext";

import Circles from "../assets/svg/components/circles.svg";
import AppStoreLogo from '../assets/svg/logos/app_store.svg';
import PlayStoreLogo from '../assets/svg/logos/play_store.svg';
import { APPLE_FING_URL, GOOGLE_FING_URL } from '../Constants';

export default class SignUpPromoSectionBlue extends React.Component {

    static contextType = UserContext;

    render() {
        if (hasUserConnectedDevices(this.context)) {
            return '';
        }

        if (isUserSignedIn(this.context)) {
            return this.renderInstallAppPromo();
        }

        return this.renderSignUpPromo(this.context);
    }

    renderSignUpPromo(context) {
        return (
            <div id="SIGNUP_CTA" className="svg-preloader position-relative">
                <div className="container space-2">
                    <div className="row justify-content-lg-between align-items-lg-center text-center text-lg-left">
                        <div className="col-lg-5 mb-5 mb-lg-0">
                            <h3 className="h2 text-indigo font-weight-semi-bold mb-0">{intl.get('promo_1_title')}</h3>
                            <h6 className="text-indigo-70">{intl.get('promo_1_subtitle')}</h6>
                        </div>

                        <div className="col-lg-5 text-lg-right">
                            <a className="btn btn-indigo btn-wide btn-pill transition-3d-hover mr-3"
                               href={getLinkForSignIn(context, window.location.href)}>
                                {intl.get('promo_1_action')} <span
                                className="fas fa-angle-right align-middle ml-2"/></a>
                        </div>
                    </div>

                </div>

{/*
                <div className="container">
                    <div className="w-75 w-md-65 w-lg-50 text-center mx-auto">
                        <img className="img-fluid" src={Illustration} alt="AI" />
                    </div>
                </div>
*/}

                <figure
                    className="gradient-overlay-half-primary-v3 position-absolute top-0 right-0 bottom-0 left-0 z-index-n1 overflow-hidden">
                    <img className="img-fluid d-none d-md-block" src={Circles} alt="Background filler"
                         data-parent="#SIGNUP_CTA"/>
                </figure>
            </div>
        );
    }

    renderInstallAppPromo() {
        return (
            <div id="SIGNUP_CTA" className="svg-preloader position-relative">
                <div className="container space-2">
                    <div className="row justify-content-lg-between align-items-lg-center text-center text-lg-left">
                        <div className="col-lg-5 mb-5 mb-lg-0">
                            <h3 className="h2 text-white font-weight-semi-bold mb-0">{intl.get('promo_2_title')}</h3>
                        </div>

                        <div className="col-lg-5 text-lg-right">
                            <div className="text-center">
                                <div>
                                    <a href={APPLE_FING_URL}
                                       className="btn btn-xs btn-wide transition-3d-hover mb-2">
                                    <span id="SVGAppStore" className="media align-items-center">
                                        <figure>
                                            <img className="img-fluid"
                                                 src={AppStoreLogo} alt="App Store Logo"
                                                 data-parent="#SVGAppStore" />
                                        </figure>
                                    </span>
                                    </a>

                                    <a href={GOOGLE_FING_URL}
                                       className="btn btn-xs btn-wide transition-3d-hover mb-2">
                                    <span id="SVGPlayStore" className="media align-items-center">
                                        <figure>
                                            <img className="img-fluid"
                                                 src={PlayStoreLogo} alt="Play Store Logo"
                                                 data-parent="#SVGPlayStore" />
                                        </figure>
                                    </span>
                                    </a>
                                </div>
                                <p className="text-white-70 mt-4">{intl.get('promo_2_subtitle')}
                                    <br/><ul className="text-center list-inline text-warning small d-inline-block">
                                        <li className="list-inline-item mx-0">
                                            <span className="fas fa-star"/>
                                        </li>
                                        <li className="list-inline-item mx-0">
                                            <span className="fas fa-star"/>
                                        </li>
                                        <li className="list-inline-item mx-0">
                                            <span className="fas fa-star"/>
                                        </li>
                                        <li className="list-inline-item mx-0">
                                            <span className="fas fa-star"/>
                                        </li>
                                        <li className="list-inline-item mx-0">
                                            <span className="fas fa-star-half"/>
                                        </li>
                                    </ul></p>
                            </div>
                        </div>
                    </div>
                </div>

                <figure
                    className="gradient-overlay-half-primary-v3 position-absolute top-0 right-0 bottom-0 left-0 z-index-n1 overflow-hidden">
                    <img className="img-fluid d-none d-md-block" src={Circles} alt="Background filler"
                         data-parent="#SIGNUP_CTA"/>
                </figure>
            </div>
        );
    }

}