import React from 'react';
import './index.css';
import ReactDOM from 'react-dom';

import App from './App';
//import { createRoot } from 'react-dom/client';

import * as serviceWorker from './serviceWorker';
// import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(<App />, document.getElementById('root')); 

// Changes for react 18.0.0
//const container = document.getElementById('root');
//const root = createRoot(container); // createRoot(container!) if you use TypeScript
//root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
