/**
 * Created by marco on 28/03/2020.
 *
 * (C) Copyright Fing
 */

import React, { Component } from 'react';

import { isUserSignedIn, UserContext, UserProvider } from "../UserContext";
import AppNavbar from "./../components/AppNavbar";
import AppFooter from "../components/AppFooter";

import AccountPageTitle from "./AccountPageTitle";
import AccountSignedOutEmptyState from "./AccountSignedOutEmptyState";

import { ToastContainer } from "react-toastify";
import { successToast, failedToast } from "../helpers/ToastHelper";

import 'react-toastify/dist/ReactToastify.css';

import { put, handleErrorFetch } from "../services/APIService";
import {withRouter} from "react-router-dom";

class AccountPasswordEdit extends Component {

    constructor(props) {
        super(props);

        this.pass1Input = React.createRef();
        this.pass2Input = React.createRef();

        this.state = {
            passwordError: false,
        };
    }

    componentDidMount() {
        document.title = "Fing - Change password";
    }


    render() {
        return (
            <div>
                <AppNavbar mode='account' />

                <main id="content" role="main">
                    {this.renderMainContent()}
                </main>

                <AppFooter />
            </div>
        );
    }

    renderMainContent() {
        return (
            <UserContext.Consumer>
                {userContext => this.renderMainContentInContext(userContext)}
            </UserContext.Consumer>
        )
    }

    renderMainContentInContext(userContext) {
        if (isUserSignedIn(userContext)) {
            return (<div className="bg-light">
                <div className="container space-2">
                    <AccountPageTitle
                        title="Change your password"
                        subtitle="Keep safe your information" />
                    {this.renderMainContentSignedIn(userContext)}
                    <ToastContainer />
                </div>
            </div>)
        } else {
            return this.renderMainContentPromo();
        }
    }

    renderMainContentSignedIn(userContext) {

        if (!userContext.userProfile) return "";

        const handleSubmit = (event) => {

            event.preventDefault();

            let pass1, pass2;

            if (this.pass1Input.current && this.pass1Input.current.value) {
                pass1 = this.pass1Input.current.value;
            }

            if (this.pass2Input.current && this.pass2Input.current.value) {
                pass2 = this.pass2Input.current.value;
            }

            if (pass1 !== pass2) {
                this.setState({ passwordError: true })
            } else {
                // submit endopoint

                put('/rest/users/password', pass1)
                    .then((res) => {

                        if (res) {
                            let newContext = Object.assign({}, userContext);
                            newContext.userProfile = res;
                            userContext.userContextUpdater(newContext);
                        }

                        successToast("Password changed.");

                    })
                    .catch((error) => {

                        failedToast("Something went wrong.");

                        if (error && error.code === 401) {
                            let newContext = handleErrorFetch(userContext);
                            userContext.userContextUpdater(newContext);
                        }

                        console.log('Error in edit profile: ', error)
                    })
                    .finally(() => this.setState({ passwordError: false }))
            }

        }

        return <div className="row flex-lg-nowrap">
            <div className="col mb-3">
                <div className="card card-bordered">
                    <div className="card-body">
                        <div className="e-profile">

                            <div className="tab-content pt-3">
                                <div className="tab-pane active">
                                    <form className="form" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <div className="row align-items-center">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>New Password</label>
                                                            <input className="form-control" type="password"
                                                                pattern="^^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$"
                                                                name="pass1" placeholder="••••••"
                                                                ref={this.pass1Input}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <p className="mt-3">
                                                            The password must be at least 6 characters long, and must contain a digit, a lowercase, an uppercase character and a special character
                                                                </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Confirm <span className="d-none d-xl-inline">Password</span></label>
                                                            <input className={"form-control" + (this.state.passwordError ? " is-invalid" : "")}
                                                                type="password" name="pass2" placeholder="••••••"
                                                                ref={this.pass2Input}
                                                            />
                                                            {
                                                                this.state.passwordError === true &&
                                                                <div id="password-error"
                                                                    className="invalid-feedback d-block">
                                                                    Confirm password is not matching.
                                                                    </div>
                                                            }
                                                        </div >
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-12 col-sm-5 offset-sm-1 mb-3">
                                                    <div className="mb-2"><b>Keeping in Touch</b></div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <label>Email Notifications</label>
                                                            <div className="custom-controls-stacked px-2">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" id="notifications-blog" checked="" />
                                                                    <label className="custom-control-label" for="notifications-blog">Subscribe to Fing newsletter</label>
                                                                    <p className="small muted"> We promise not to bother you.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                        </div>

                                        <div className="row">
                                            <div className="col d-flex justify-content-start">
                                                <button className="btn btn-primary btn-sm" type="submit">Change password</button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    }

    renderMainContentPromo() {
        return <AccountSignedOutEmptyState />;
    }

}

export default withRouter(AccountPasswordEdit)