/**
 * Created by marco on 17/11/2020.
 *
 * (C) Copyright Fing
 */

import React, {Component} from 'react';
import intl from 'react-intl-universal';
import {currencySymbol, INTERVAL_MONTH, planPrettyName, productPrettyName} from "../helpers/BillingHelper";

export default class PlanSummary extends Component {

    render() {
        const {plan} = this.props;
        if (plan)
            return this.renderPlanSummary(plan);
        else
            return '';
    }

    renderPlanSummary(plan) {

        return (
            <div>
                <div>
                    {this.renderProduct(plan)}
                    {this.renderBigPrice(plan)}
                    {this.renderAsMonthlyPrice(plan)}
                    {!this.props.hideDiscount && this.renderDiscount(plan)}
                </div>
            </div>
        );
    }

    renderProduct(plan) {
        return <span
            className="font-weight-bolder text-uppercase">{productPrettyName(plan.productId)}</span>;
    }

    renderBigPrice(plan) {
        const symbol = currencySymbol(plan.currency);
        return <div className="font-size-3 font-weight-bolder">
            {symbol && <span>{symbol}</span>}
            <span>{plan.offerAmount ? plan.offerAmount : plan.standardAmount}</span>&nbsp;
            <span>{plan.currency.toUpperCase()}</span>&nbsp;
        </div>;
    }

    renderAsMonthlyPrice(plan) {
        if (plan.billingCycle.toUpperCase() === INTERVAL_MONTH) {
            return <div>Billed monthly</div>
        }

        const amount = plan.offerAmount || plan.standardAmount;
        const monthlyAmount = Math.floor(amount / 12 * 100) / 100;
        return <div>
            Equals to&nbsp;
            <span>{plan.currencySymbol}</span>
            <span>{monthlyAmount}</span>/month
        </div>
    }

    renderDiscount(plan) {
        const discountDurationText = plan.offerDuration + " " + plan.billingCycle.toLowerCase() + (plan.offerDuration > 1 ? "s" : "");
        let discountPct = plan.discountPct || 0;
        return <>
            {plan.offerDuration ?
                <span> for {discountDurationText}</span> : <span> cancel anytime</span>}
            {discountPct ?
                <span className="d-block lead">
                    <span className="badge badge-success">
                        👍 You save {discountPct}%
                    </span>
                </span> :
                <span className="d-block lead ">
                    <span className="badge badge-soft-secondary">
                        😟 I don't like savings
                    </span>
                </span>
            }
            {plan.offerAmount > 0 &&
                <p className="lead small mt-2">
                    After {discountDurationText}, you will be charged&nbsp;
                    <span>{currencySymbol(plan.currency)}</span>
                    <span>{plan.standardAmount}</span>
                    <span> per {plan.billingCycle.toLowerCase()}.</span>
                </p>
            }
        </>;
    }
}