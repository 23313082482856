import { ENUMS } from "./EnumHelper";

// STATE -> ENUM
export function convertStateToMultiEnum(enumValues) {
    let values = [];
    if(enumValues?.length > 0){
        values = enumValues.map(val => val.value).filter(v => v !== '')
    }
    return values.join(",");
}

export function convertStateToArray(enumValues) {
    let values = [];
    if(enumValues?.length > 0){
        values = enumValues.map(val => val.value).filter(v => v !== '')
    }
    return values;
}

export function convertStateToEnum(enumValue) {
    if (enumValue && enumValue.value)
        return enumValue.value;
    return null;
}

// DATE -> TIMESTAMP
export function convertDateToTimestamp(dateValue) {
    if (dateValue) {
        return new Date(Date.parse(dateValue)).getTime();
    }
    return null;
}

// TIMESTAMP -> DATE
export function convertTimestampToDate(t) {
    return convertDateToState(new Date(t));
}

// STATE -> DATE
export function convertStateToDate(dateValue) {
    if (dateValue) {
        const parts = dateValue.split("-");
        return new Date(+parts[0], parts[1] - 1, +parts[2]).toISOString();
    }
    return null;
}


// DATE -> STATE
export function convertDateToState(d) {
    const year = d.getFullYear();
    const month = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1);
    const day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    return `${year}-${month}-${day}`;
}

// ENUM -> STATE
export function convertEnumToState(enumValues, enumString) {
    if(Array.isArray(enumString))
        enumString = enumString[0];
    for (const enumValue of enumValues) {
        if (enumValue.value === enumString)
            return enumValue;
    }
    return null;
}

export function convertMultiEnumToState(enumValues, str) {
    const values = str.includes(' ') ? str.join(',') : str;
    if(enumValues?.length > 0 && values){
        return enumValues.filter(val => values.includes(val.value));
    }
    return [];
}

// STATE ARRAY -> STRING
export function convertStateArrayToString(array, divider) {
    if(array && array.length > 0){
        return array.join(divider);
    }
    return '';
    
}

// STRING -> ARRAY
export function convertStringToArray(str, divider) {
    if(str){
        return str.split(divider);
    }
    return [];
    
}

export function getCountriesFromMessage(obj){
    const countries = Object.keys(obj);
    if(countries.length > 0){
        const enums = ENUMS.COUNTRIES;
        return enums.filter(c => countries.includes(c.value));
    }
    return [];
}