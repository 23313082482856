import React from "react";
import DataTable from "./DataTable";
import { getParamsFromUrl, HEADERS, EXTRA_SEARCH_FIELDS, ON_RENDER_CELL, QUERY_URL } from './helper/TableHelper';
import { PERMISSION } from "./helper/PermissionHelper";
import { REST_URL_CONNECTION } from "./helper/URLHelper";

import TitleTable from "./extra/TitleTable";
import MainContent from "./extra/MainContent";


export default class AdminConnectionListPage extends React.Component {

    render() {


        return (
            <MainContent 
                history={this.props.history}
                checkUrl={PERMISSION.CONNECTION.url + "view"}>
                <div className="container-fluid space-1 px-4">
                    <TitleTable
                        title="Connections"
                        info="Search will look into Users ID">
                        <div className="ml-auto">
                            <a 
                                href="/app#connection:"
                                className="btn btn-sm btn-soft-warning">Previous version</a>
                        </div>
                    </TitleTable>
                    <DataTable
                        search={true}
                        queryUrl={QUERY_URL.CONNECTION}
                        extraSearchFields={EXTRA_SEARCH_FIELDS.CONNECTION}
                        params={getParamsFromUrl(new URLSearchParams(this.props.location.search),QUERY_URL.CONNECTION)}
                        pagination={true}
                        autoBlockSearch={true}
                        excludePageNumbers={true}
                        resources={`${REST_URL_CONNECTION}/list`}
                        name="Connections"
                        headers={HEADERS.CONNECTION}
                        onRenderCell={ON_RENDER_CELL.CONNECTION}
                        onForbidden={() => this.setState({forbidden: true})}
                        entries={true}/>
                </div>
            </MainContent>
        );
    }
}