import React from "react";
import { Link } from "react-router-dom";
import intl from 'react-intl-universal';


import AppNavbar from "../InternetNavbar";
import AppFooter from "../../components/AppFooter";

import { renderMetaTagsWithDefImg } from "../../helpers/MetaTagsHelper";

import { getCountryName } from "../place/GeoCountries";
import OutageMiniCard from "./OutageMiniCard";
import OutageMap from "./OutageMap";

export default class OutageLiveMap extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            recentOutages: [],
        };
    }

    validateRestResponse(response) {
        if (response.status !== 200) {
            throw Error(response.statusText);
        }
        return response;
    }

    catchRestError(response) {
        this.setState({ recentOutages: [], isLoading: false, isFound: false });
    }

    componentDidMount() {
        this.mainFetchSequence();
        // autorefresh each 5 minutes
        setTimeout(function () {
            window.location.reload();
        }, 300000);
    }

    mainFetchSequence() {
        return fetch('/rest/internet/outages/all?top=64')
            .then(response => this.validateRestResponse(response))
            .then(response => response.json())
            .then(data => {
                if (data.reports && data.reports.length > 0) {
                    this.setState({ recentOutages: data.reports });
                } else {
                    this.setState({ recentOutages: [] });
                }
            })
            .catch(e => this.catchRestError(e));
    }

    // --------------------------------------------------------------------------------
    // MAIN RENDERING
    // --------------------------------------------------------------------------------

    render() {
        return (
            <div>
                {this.renderMetaTags()}

                <AppNavbar />

                <main id="content" role="main">
                    <div>
                        <div className="container space-2">
                            {this.renderRecentOutageSection()}
                        </div>
                    </div>

                </main>

                <AppFooter />
            </div>
        );
    }

    // --------------------------------------------------------------------------------
    // Render Outage carousel
    // --------------------------------------------------------------------------------

    renderMetaTags() {
        const { recentOutages } = this.state;

        let ongoingOutagesDescr = "";
        let ongoingOutages = 0;
        for (let i = 0; i < recentOutages.length; i++) {
            const outage = recentOutages[i];
            if (!outage.active) continue;
            ++ongoingOutages;
            if (!outage.mainDimensionIspValue) continue;
            if (ongoingOutagesDescr.length > 0) ongoingOutagesDescr += ", ";
            ongoingOutagesDescr += outage.mainDimensionIspBestName + " in " +
                (outage.mainDimensionRegionValue ? outage.mainDimensionRegionValue : getCountryName(outage.mainDimensionCountryValue));
        }

        if (ongoingOutages === 0) {
            return renderMetaTagsWithDefImg(intl.get('metatag_outage_info'), intl.get('metatag_outage_info_descr'));
        }

        return renderMetaTagsWithDefImg(intl.get('metatag_outage_info'), intl.get('metatag_outage_status2', { outage: ongoingOutages, outageDescr: ongoingOutagesDescr }));
    }

    renderRecentOutageSection() {
        if (this.state.recentOutages.length === 0) {
            return <div />
        }

        return (
            <div>
                <div className="row mb-5">
                    <div className="col-lg-12 text-center">
                        <h2 className="text-primary">{intl.get('livemap_title')}</h2>
                    </div>
                </div>

                <div className="row text-center">
                    <div className="col-lg-12 text-center">
                        {this.renderWorldMap()}
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 w-100 text-center">
                        {this.renderRecentOutageCards()}
                    </div>
                </div>
            </div>
        );
    }

    renderRecentOutageCards() {
        let outages = this.state.recentOutages.slice(0);    // Clone
        let emptyActive = true;
        for (let i = 0; i < this.state.recentOutages.length; i++) {
            if (this.state.recentOutages[i].active) { emptyActive = false; break; }
        }

        if (emptyActive) return (
            <div className="w-md-80 w-lg-50 text-center mx-md-auto ma-9 mb-">
                <Link className="btn btn-soft-primary btn-wide transition-3d-hover"
                    to={'/outages'}>{intl.get('livemap_recent_outages_action')}</Link>
            </div>
        );

        return outages.map(outage => this.renderOutageCard(outage));
    }

    renderOutageCard(outage) {
        if (!outage.active)
            return (
                <div />
            );
        return (
            <div className="card card-frame border-1 mb-3">
                <Link to={'/outage/' + outage.reportId}>
                    <OutageMiniCard outage={outage} showIsp="true" />
                </Link>
            </div>
        );
    }

    renderWorldMap() {

        if (!this.state.recentOutages || !this.state.recentOutages.length) return (<div />);

        return (
            <div className="card h-100 w-80 d-inline-block border-1 p-0">
                <OutageMap
                    live={true}
                    height={500}
                    zoom={1}
                    outages={this.state.recentOutages} />
            </div>
        );

    }

}