/**
 * Created by marco on 28/03/2020.
 *
 * (C) Copyright Fing
 */

import React, {Component} from 'react';
import AgentImg from "../assets/svg/devices/agent_48.svg";
import BoxImg from "../assets/svg/devices/fingbox_48.svg";
import DesktopImg from "../assets/svg/devices/desktop_48.svg";
import MobileImg from "../assets/svg/devices/mobile_48.svg";
import Snapcraft from "../assets/svg/devices/snapcraft.svg";
import {Link} from "react-router-dom";
import {APPLE_FING_URL, GOOGLE_FING_URL, MACOS_FING_URL, SNAP_FING_URL, WINDOWS_FING_URL} from "../Constants";

export default class AccountConnectionSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    renderItem(img, title, subtitle, actions) {
        return <li className="list-group-item">
            <div className="row align-items-md-center">
                <div className="col-md-8 mb-5 mb-md-0">
                    <div className="media align-items-center">
                        <div className="avatar">
                            <img src={img} className="mx-auto"/>
                        </div>
                        <div className="media-body font-size-1 ml-3">
                            <h4 className="mb-0">{title}</h4>
                            <span className="d-block text-muted">{subtitle}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 text-md-right">
                    {actions}
                </div>
            </div>
        </li>;
    }

    render() {
        const {userProfile} = this.props;
        const mobileCount = userProfile ? userProfile.mobileCount : 0;
        const desktopCount = userProfile ? userProfile.desktopCount : 0;
        const agentCount = userProfile ? userProfile.agentCount : 0;
        const fingboxCount = userProfile ? userProfile.fingboxCount : 0;

        const SmallAction = ({url, children}) => <a className="btn btn-xs btn-icon btn-soft-secondary rounded-circle ml-2"
                                                  href={url}>{children}</a>;

        return <div className="card card-bordered">
            <ul className="list-group list-group-flush mt-3">
                {this.renderItem(MobileImg, "Fing Mobile App", mobileCount > 0 ?
                        <span>You connected <strong className="text-dark">{mobileCount} mobile devices</strong> to your account</span> :
                        <span>You haven't connected any mobile device to your account yet<span className="badge badge-warning ml-2">Activate now</span></span>,
                    <>
                        <SmallAction url={GOOGLE_FING_URL}><i
                            className="fab fa-google-play"/></SmallAction>
                        <SmallAction icon="fab fa-apple"
                                     url={APPLE_FING_URL}><i
                            className="fab fa-apple"/></SmallAction>
                    </>
                )}
                {this.renderItem(DesktopImg, "Fing Desktop App", desktopCount > 0 ?
                        <span>You connected <strong className="text-dark">{desktopCount} desktop devices</strong> to your account</span> :
                        <span>You haven't connected any desktop device to your account yet<span className="badge badge-warning ml-2">Activate now</span></span>,
                    <>
                        <SmallAction url={WINDOWS_FING_URL}><i className="fab fa-windows"/></SmallAction>
                        <SmallAction url={MACOS_FING_URL}><i className="fab fa-apple"/></SmallAction>
                    </>
                )}
                {this.renderItem(AgentImg, "Fing Agent", agentCount > 0 ?
                        <span>You connected <strong className="text-dark">{agentCount} agent devices</strong> to your account</span> :
                        <span>You haven't connected any Fing agent to your account yet<span className="badge badge-warning ml-2">Activate now</span></span>,
                    <>
                        <SmallAction url={SNAP_FING_URL}>
                            <figure className="d-inline">
                                <img src={Snapcraft} alt="Fing Agent" style={{height: "1em"}}/>
                            </figure>
                        </SmallAction>
                        <SmallAction url="https://www.fing.com/fing-agent"><i className="fa fa-info"/></SmallAction>
                    </>
                )}
                {fingboxCount > 0 && this.renderItem(BoxImg, "Fingbox", <span>You connected <strong
                    className="text-dark">{fingboxCount} Fingbox devices</strong> to your account</span>,
                    <>
                        <span>Production Ended</span>
                    </>
                )}
            </ul>
            <div className="card-body">
                <Link className="btn btn-xs btn-primary mb-1" to="/account/connections">
                    See all connections</Link>
            </div>
        </div>;
    }

}