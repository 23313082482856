import React from "react";
import { isNotEmptyString } from "../../../helpers/JsHelper";

// labelSpan, textSpan -> How many columns takes the cell?


export default class TableTextField extends React.Component {

    render() {
        const {id, label, mandatory, maxLength, value, attemptedSave, disabled, onChange, labelSpan, textSpan} = this.props;
        const valueLength = value ? value.length : 0;

        const isMissing = attemptedSave && mandatory && !isNotEmptyString(value, true);
        const isTooLong = attemptedSave && maxLength > 0 && valueLength > maxLength;
        const colorText = isMissing || isTooLong ? "text-danger" : "text-dark";

        return (
            <tr className="w-100">
                <td className="p-2" colspan={labelSpan || 1} style={{width:"50%"}}><span className={colorText}>{label}</span></td>
                <td className="p-2" colspan={textSpan || 1} style={{width:"50%"}}>
                    <span>
                        <input type="text" id={id}
                            className={`form-control form-control-sm my-0 ${isMissing || isTooLong ? "border-danger" : ""}`}
                            placeholder={disabled ? '' : `${label}`}
                            value={value}
                            disabled={disabled}
                            onChange={onChange}
                        />
                    </span>
                    
                </td>
            </tr>
        );
    }

}