/**
 * Created by marco on 28/03/2020.
 *
 * (C) Copyright Fing
 */

import React, {Component} from 'react';
// import intl from 'react-intl-universal';
import MobileDevice from "../assets/svg/devices/phone_48.svg";
import DesktopDevice from "../assets/svg/devices/desktop_48.svg";
import AgentDevice from "../assets/svg/devices/agent_48.svg";
import Snapcraft from "../assets/svg/devices/snapcraft.svg";
import {APPLE_FING_URL, GOOGLE_FING_URL, MACOS_FING_URL, SNAP_FING_URL, WINDOWS_FING_URL} from "../Constants";

export default class AccountConnectionColumnSummary extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const {userProfile} = this.props;
        const mobileCount = userProfile ? userProfile.mobileCount : 0;
        const desktopCount = userProfile ? userProfile.desktopCount : 0;
        const agentCount = userProfile ? userProfile.agentCount : 0;
        const fingboxCount = userProfile ? userProfile.fingboxCount : 0;

        const activeCardCls = "border border-gray20 bg-white text-dark text-center rounded p-5";
        const inactiveCardCls = "bg-soft-warning text-dark text-center rounded p-5";

        return (
            <div className="row">
                <div id="Fing_Mobile_Slide" className="col-md-4">
                    <div className={mobileCount > 0 ? activeCardCls : inactiveCardCls}>
                        <div className="text-right m-n3">
                            {mobileCount > 0 ?
                                <span className="fa fa-fw fa-2x fa-check-circle mr-2 text-success"/> :
                                <span className="fa fa-fw fa-2x fa-exclamation-circle mr-2 text-warning"/>
                            }
                        </div>
                        <figure style={{height: "3em"}}>
                            <img src={MobileDevice} alt="Mobile Device" />
                        </figure>
                        <h4 className="font-weight-semi-bold">Mobile</h4>
                        <p className="mb-4">
                            {mobileCount > 0 ?
                                <span>You connected <strong className="text-dark">{mobileCount} mobile devices</strong> to your account</span> :
                                <span>You haven't connected any mobile device to your account yet</span>
                            }
                        </p>

                        <div className="small text-secondary mb-1">
                            {mobileCount > 0 ?
                                "Need it for another mobile or tablet?" :
                                "Start using it today on your mobile!"
                            }
                        </div>
                        <a className="btn btn-sm btn-block btn-outline-dark transition-3d-hover" 
                            href={GOOGLE_FING_URL} target="_blank" rel="noopener noreferrer"
                           tabIndex="0"><small className="fab fa-google-play mr-2"/>Get it on the Play Store </a>
                        <a className="btn btn-sm btn-block btn-outline-dark transition-3d-hover"
                            href={APPLE_FING_URL} target="_blank" rel="noopener noreferrer"
                           tabIndex="0"><small className="fab fa-apple mr-2"/>Get it on the App Store</a>
                    </div>
                </div>

                <div id="Fing_Desktop_Slide" className="col-md-4">
                    <div className={desktopCount > 0 ? activeCardCls : inactiveCardCls}>
                        <div className="text-right m-n3">
                            {desktopCount > 0 ?
                                <span className="fa fa-fw fa-2x fa-check-circle mr-2 text-success"/> :
                                <span className="fa fa-fw fa-2x fa-exclamation-circle mr-2 text-warning"/>
                            }
                        </div>
                        <figure style={{height: "3em"}}>
                            <img src={DesktopDevice} alt="Desktop Device" />
                        </figure>
                        <h4 className="font-weight-semi-bold">Desktop</h4>
                        <p className="mb-4">
                            {desktopCount > 0 ?
                                <span>You connected <strong className="text-dark">{desktopCount} desktop devices</strong> to your account</span> :
                                <span>You haven't connected any desktop device to your account yet</span>
                            }
                        </p>

                        <div className="small text-secondary mb-1">
                            {desktopCount > 0 ?
                                "Need it for another PC?" :
                                "Start using it today on your PC!"
                            }
                        </div>
                        <a className="btn btn-sm btn-block btn-outline-dark transition-3d-hover" 
                            href={WINDOWS_FING_URL} target="_blank" rel="noopener noreferrer"
                            tabIndex="0"><small className="fab fa-windows mr-2"/>Get it for Windows</a>
                        <a className="btn btn-sm btn-block btn-outline-dark transition-3d-hover"
                            href={MACOS_FING_URL} target="_blank" rel="noopener noreferrer"
                           tabIndex="0"><small className="fab fa-apple mr-2"/>Get it for macOS</a>
                    </div>
                </div>

                <div id="Agent_Slide" className="col-md-4">
                    <div className={agentCount > 0 ? activeCardCls : inactiveCardCls}>
                        <div className="text-right m-n3">
                            {agentCount > 0 ?
                                <span className="fa fa-fw fa-2x fa-check-circle mr-2 text-success"/> :
                                <span className="fa fa-fw fa-2x fa-exclamation-circle mr-2 text-warning"/>
                            }
                        </div>
                        <figure style={{height: "3em"}}>
                            <img src={AgentDevice} alt="Fing Agent" />
                        </figure>
                        <h4 className="font-weight-semi-bold">Agent</h4>
                        <p className="mb-4">
                            {fingboxCount > 0 ?
                                <span>You connected <strong className="text-dark">{agentCount} agent devices</strong> to your account</span> :
                                <span>You haven't connected any Fing agent to your account yet</span>
                            }
                        </p>

                        <div className="small text-secondary mb-1">
                            {agentCount > 0 ?
                                "Need one more? Get it today!" :
                                "Get your plug and play network helper here."
                            }
                        </div>
                        <a className="btn btn-sm btn-block btn-outline-dark transition-3d-hover" 
                            href={SNAP_FING_URL} target="_blank" rel="noopener noreferrer"
                           tabIndex="0">
                            <figure className="d-inline mr-2">
                                <img src={Snapcraft} alt="Fing Agent" style={{height: "1em"}} />
                            </figure>
                            Get if from Snap Store</a>
                        <a className="btn btn-sm btn-block btn-outline-dark transition-3d-hover"
                            href="https://www.fing.com/fing-agent/" target="_blank" rel="noopener noreferrer"
                           tabIndex="0"><small className="fa fa-info-circle mr-2"/>Learn more</a>
                    </div>
                </div>

                {/*<div id="Fingbox_Slide" className="col-md-4">*/}
                {/*    <div className={fingboxCount > 0 ? activeCardCls : inactiveCardCls}>*/}
                {/*        <div className="text-right m-n3">*/}
                {/*            {fingboxCount > 0 ?*/}
                {/*                <span className="fa fa-fw fa-2x fa-check-circle mr-2 text-success"/> :*/}
                {/*                <span className="fa fa-fw fa-2x fa-exclamation-circle mr-2 text-warning"/>*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*        <figure style={{height: "3em"}}>*/}
                {/*            <img src={FingboxDevice} alt="Fingbox Device" />*/}
                {/*        </figure>*/}
                {/*        <h4 className="font-weight-semi-bold">Fingbox</h4>*/}
                {/*        <p className="mb-4">*/}
                {/*            {fingboxCount > 0 ?*/}
                {/*                <span>You connected <strong className="text-dark">{fingboxCount} Fingbox devices</strong> to your account</span> :*/}
                {/*                <span>You haven't connected any Fingbox device to your account yet</span>*/}
                {/*            }*/}
                {/*        </p>*/}

                {/*        <div className="small text-secondary mb-1">*/}
                {/*            {fingboxCount > 0 ?*/}
                {/*                "Need one more? Get it today!" :*/}
                {/*                "Get your plug and play network helper here."*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*        <a className="btn btn-sm btn-block btn-outline-dark transition-3d-hover" */}
                {/*            href={APP_FING_URL + "/purchase/fingbox"} target="_blank" rel="noopener noreferrer"*/}
                {/*           tabIndex="0"><small className="fab fa-amazon mr-2"/>Buy Fingbox</a>*/}
                {/*        <a className="btn btn-sm btn-block btn-outline-dark transition-3d-hover" */}
                {/*            href="https://www.fing.com/products/fingbox" target="_blank" rel="noopener noreferrer"*/}
                {/*           tabIndex="0"><small className="fa fa-info-circle mr-2"/>Learn more</a>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        )
    }

}