import React from "react";

import { toast } from "react-toastify";
import CheckedIcon from "../assets/svg/components/checked-icon.svg";


export function successToast(title) {

    toast(<div className="d-flex align-items-center">
        <img className="my-2" src={CheckedIcon} height={30} width={30} alt="SVG" id="iconChecked" />
        <span className="ml-2">{title}</span>
    </div>, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export function failedToast(title) {
    toast(<div className="d-flex align-items-center">
        <i className="fas fa-exclamation-triangle fa-lg text-danger"></i>
        <span className="font-weight-medium ml-2">{title}</span>
    </div>, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}