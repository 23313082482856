import React from "react";

import DataTable from "./DataTable";
import {Link} from "react-router-dom";
import { getParamsFromUrl, HEADERS, EXTRA_SEARCH_FIELDS, ON_RENDER_CELL, QUERY_URL } from './helper/TableHelper';
import { PERMISSION } from "./helper/PermissionHelper";
import { REST_URL_OFFER } from "./helper/URLHelper";

import TitleTable from "./extra/TitleTable";
import MainContent from "./extra/MainContent";

export default class AdminDiscountOfferListPage extends React.Component {

    render() {
        return (
            <MainContent 
                history={this.props.history}
                checkUrl={PERMISSION.OFFER.url + "view"}>
                <div className="container-fluid space-1 px-4">
                    <TitleTable
                        title="Discount offers"
                        info="Search will look into Promo Text, Description, Plans, Countries, Rules, Discount ID">
                        <div className="ml-auto">
                            <Link to="/admin/offer/new" className="btn btn-sm btn-primary"><i className="fa fa-fw fa-plus mr-1"/>Add</Link>
                        </div>
                    </TitleTable>
                    <DataTable
                        params={getParamsFromUrl(new URLSearchParams(this.props.location.search),QUERY_URL.DISCOUNT_OFFER)}
                        search={true}
                        queryUrl={QUERY_URL.DISCOUNT_OFFER}
                        autoBlockSearch={true}
                        extraSearchFields={EXTRA_SEARCH_FIELDS.DISCOUNT_OFFER}
                        pagination={true}
                        resources={`${REST_URL_OFFER}s`}
                        name="Discount Offers"
                        headers={HEADERS.DISCOUNT_OFFER}
                        onRenderCell={ON_RENDER_CELL.DISCOUNT_OFFER}
                        onForbidden={() => this.setState({forbidden: true})}
                        entries={true}/>
                </div>
            </MainContent>
        );
    }

}