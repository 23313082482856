/**
 * Created by setefano on 31/03/2020.
 *
 * (C) Copyright Fing
 */

import React, { Component } from 'react';
import { Link } from "react-router-dom";

export default class BackButton extends Component {

    render() {

        return (
            <div>
                <Link to={{
                    pathname: this.props.to || "/",
                    state: { backParam: this.props.backParam || null }
                }} ><h6 className="text-primary mb-0"><span className="fa fa-arrow-left fa-fw mr-2" />{this.props.caption || 'Back'}</h6></Link>
            </div>
        )
    }

}