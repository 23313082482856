/**
 * Created by marco on 28/03/2020.
 *
 * (C) Copyright Fing
 */

import React, {Component} from 'react';

import {isUserSignedIn, UserContext, UserProvider} from "../UserContext";
import AppNavbar from "../components/AppNavbar";
import AppFooter from "../components/AppFooter";
import AccountPageTitle from "./AccountPageTitle";
import AccountSignedOutEmptyState from "./AccountSignedOutEmptyState";

import {get, put, handleErrorFetch} from "../services/APIService";
import {onKeyDown} from "../helpers/JsHelper";
import {alertMailType, alertMessageType, ispAlertType, timezones, languageCodes} from '../helpers/AccountHelper';

import {ToastContainer} from "react-toastify";
import {successToast, failedToast} from "../helpers/ToastHelper";

import 'react-toastify/dist/ReactToastify.css';
import {withRouter} from "react-router-dom";

const langCodes = languageCodes();
const tZones = timezones();

class AccountNotificationsEdit extends Component {

    constructor(props) {
        super(props);

        this.emailInput = React.createRef();
        this.languageSelect = React.createRef();
        this.timezoneSelect = React.createRef();
        this.alertMailSelect = React.createRef();
        this.alertMessageSelect = React.createRef();
        this.ispAlertSelect = React.createRef();
        this.newsletterSwitch = React.createRef();
        this.contentSwitch = React.createRef();
        this.digitalPresenceSwitch = React.createRef();

        this.state = {
            userNotificationsSettings: null,
            userPrivacySettings: null,
        };
    }

    componentDidMount() {
        get('/rest/users/settings')
            .then((res) => {
                if (res && res.userNotifications && res.userPrivacySettings)
                    this.setState({
                        userNotificationsSettings: res.userNotifications,
                        userPrivacySettings: res.userPrivacySettings
                    });
            })
            .catch((e) => {
                console.log('Error in get auth apps: ', e);
                this.setState({userNotificationsSettings: null, userPrivacySettings: null})
            });

        window.scrollTo(0, 0);
        document.title = "Fing - Edit notification settings";
    }


    render() {
        return (
            <div>
                <AppNavbar mode='account'/>

                <main id="content" role="main">
                    {this.renderMainContent()}
                </main>

                <AppFooter/>
            </div>
        );
    }

    renderMainContent() {
        return (
            <UserContext.Consumer>
                {userContext => this.renderMainContentInContext(userContext)}
            </UserContext.Consumer>
        )
    }

    renderMainContentInContext(userContext) {
        if (isUserSignedIn(userContext)) {
            return (<div className="bg-light">
                <div className="container space-2">
                    <AccountPageTitle
                        title="Edit your communication preferences"
                        subtitle="Choose how you prefer to be reached"/>
                    {this.renderMainContentSignedIn(userContext)}
                    <ToastContainer/>
                </div>
            </div>)
        } else {
            return this.renderEmptyState();
        }
    }

    renderMainContentSignedIn(userContext) {
        const {userNotificationsSettings, userPrivacySettings} = this.state;
        if (!userNotificationsSettings || !userPrivacySettings) return "";

        const validateEmail = function (email) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        };

        const handleSubmit = (event) => {
            event.preventDefault();

            let alertMail = this.emailInput.current.value ? this.emailInput.current.value.trim() : '';
            if (alertMail.length === 0) {
                alertMail = null;
            } else {
                const emails = alertMail.split(",");
                for (let em of emails) {
                    let email = em.trim();
                    if (!validateEmail(email)) {
                        if (email.length > 30) {
                            email = email.substring(0, 20);
                        }

                        failedToast("Email '" + email + "' is not a valid email");
                        return;
                    }
                }

                if (emails.length > 5) {
                    failedToast("You can specify up to 5 email here");
                    return;
                }
            }


            const payload = {
                userNotifications: {
                    ispAlertType: this.ispAlertSelect.current.value,
                    alertMailType: this.alertMailSelect.current.value,
                    alertMessageType: this.alertMessageSelect.current.value,
                    alertEmail: alertMail,
                    languageCode: this.languageSelect.current.value,
                    timezone: this.timezoneSelect.current.value,
                    subscribedToNewsletter: this.newsletterSwitch.current.checked,
                    subscribedToContent: this.contentSwitch.current.checked
                },
                userPrivacySettings: {
                    enableDigitalPresenceStats: this.digitalPresenceSwitch.current.checked
                }
            };

            put('/rest/users/settings', payload)
                .then((res) => {
                    successToast("Notification settings updated.");
                    this.setState({userNotificationsSettings: res.userNotifications});
                })
                .catch((error) => {
                    failedToast("Something went wrong.");

                    if (error && error.code === 401) {
                        let newContext = handleErrorFetch(userContext);
                        userContext.userContextUpdater(newContext);
                    }

                    console.log('Error in edit profile: ', error)
                })
        };

        return <div className="div">
            <form className="form" onSubmit={handleSubmit}>

                <div className="row my-4">
                    <div className="col-sm-12 col-md-3 py-2">
                        <h4>Region preferences</h4>
                    </div>
                    <div className="col-sm-12 col-md-9">
                        <div className="card card-bordered h-100">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col-lg-4">
                                        <label>Language</label>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="form-group pt-2">
                                            <select className="form-control custom-select" id="languageSelect"
                                                    ref={this.languageSelect}
                                                    defaultValue={userNotificationsSettings.languageCode || "en"}>
                                                {langCodes.map((langCode, idx) =>
                                                    <option key={"opt_lang_" + idx}
                                                            value={langCode.value}>{langCode.label}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center">
                                    <div className="col-lg-4">
                                        <label>Timezone</label>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="form-group pt-2">
                                            <select className="form-control custom-select" id="timezoneSelect"
                                                    ref={this.timezoneSelect}
                                                    defaultValue={userNotificationsSettings.timezone || "GMT"}>
                                                {tZones.map((tz, idx) =>
                                                    <option key={"opt_tz_" + idx} value={tz}>{tz}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center">
                                    <div className="col-lg-4">
                                        <label>Email address</label>
                                        <small className="d-block">Up to 5 email separated by commas</small>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="form-group pt-2">
                                            <input className="form-control" onKeyDown={onKeyDown}
                                                   name="name" placeholder="e.g. john.smith@fing.com"
                                                   ref={this.emailInput}
                                                   defaultValue={userNotificationsSettings.alertEmail}/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row my-4">
                    <div className="col-sm-12 col-md-3 py-2">
                        <h4>Notifications</h4>
                    </div>
                    <div className="col-sm-12 col-md-9">
                        <div className="card card-bordered h-100">
                            <div className="card-body">
                                <label className="toggle-switch d-flex align-items-start"
                                       forHtml="newsletterSwitch">
                                    <input type="checkbox" className="toggle-switch-input" id="newsletterSwitch"
                                           ref={this.newsletterSwitch}
                                           defaultChecked={userNotificationsSettings.subscribedToNewsletter}/>
                                    <span className="toggle-switch-label">
                                                <span className="toggle-switch-indicator"/>
                                            </span>
                                    <span className="toggle-switch-content">
                                                <span className="d-block">Newsletter</span>
                                                <small className="d-block text-muted">Emails about every news from Fing world</small>
                                            </span>
                                </label>

                                <label className="toggle-switch d-flex" forHtml="contentSwitch">
                                    <input type="checkbox" className="toggle-switch-input" id="contentSwitch"
                                           ref={this.contentSwitch}
                                           defaultChecked={userNotificationsSettings.subscribedToContent}/>
                                    <span className="toggle-switch-label">
                                                <span className="toggle-switch-indicator"/>
                                            </span>
                                    <span className="toggle-switch-content">
                                                <span className="d-block">Content Updates</span>
                                                <small className="d-block text-muted">Personalized tips, tricks, alerts and updates about your devices, brands and more</small>
                                            </span>
                                </label>

                            </div>
                        </div>
                    </div>

                </div>

                <div className="row my-4">
                    <div className="col-sm-12 col-md-3 py-2">
                        <h4>Internet provider events</h4>
                    </div>
                    <div className="col-sm-12 col-md-9">
                        <div className="card card-bordered h-100">
                            <div className="card-body">
                                <div className="row align-items-center">

                                    <div className="col-lg-12">
                                        <label>Send me notifications about ISP outages.</label>
                                        <div className="form-group pt-2">
                                            <select className="form-control custom-select" id="ispAlertSelect"
                                                    ref={this.ispAlertSelect}
                                                    defaultValue={userNotificationsSettings.ispAlertType}>
                                                {ispAlertType.map((type, idx) =>
                                                    <option key={"opt_ispAlert_" + idx}
                                                            value={type.value}>{type.text}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row my-4">
                    <div className="col-sm-12 col-md-3 py-2">
                        <h4>Network events</h4>
                    </div>
                    <div className="col-sm-12 col-md-9">
                        <div className="card card-bordered h-100">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <label>Send me emails about network events.</label>
                                        <div className="form-group pt-2">
                                            <select className="form-control custom-select" id="alertMailSelect"
                                                    ref={this.alertMailSelect}
                                                    defaultValue={userNotificationsSettings.alertMailType}>
                                                {alertMailType.map((type, idx) =>
                                                    <option key={"opt_alertMail_" + idx}
                                                            value={type.value}>{type.text}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center">

                                    <div className="col-lg-12">
                                        <label>Send me mobile notifications about network events.</label>
                                        <div className="form-group pt-2">
                                            <select className="form-control custom-select" id="alertMessageSelect"
                                                    ref={this.alertMessageSelect}
                                                    defaultValue={userNotificationsSettings.alertMessageType}>
                                                {alertMessageType.map((type, idx) =>
                                                    <option key={"opt_alertMessage_" + idx}
                                                            value={type.value}>{type.text}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <div className="row my-4">
                    <div className="col-sm-12 col-md-3 py-2">
                        <h4>Privacy preferences</h4>
                    </div>
                    <div className="col-sm-12 col-md-9">
                        <div className="card card-bordered h-100">
                            <div className="card-body">
                                <label className="toggle-switch d-flex" forHtml="digitalPresenceSwitch">
                                    <input type="checkbox" className="toggle-switch-input"
                                           id="digitalPresenceSwitch" ref={this.digitalPresenceSwitch}
                                           defaultChecked={userPrivacySettings.enableDigitalPresenceStats}/>
                                    <span className="toggle-switch-label">
                                        <span className="toggle-switch-indicator"/>
                                    </span>
                                    <span className="toggle-switch-content">
                                        <span className="d-block">Enable Digital Presence stats</span>
                                        <small className="d-block text-muted">Allow Fing to send you statistics based on users' digital presence</small>
                                    </span>
                                </label>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row my-5">
                    <div className="col-sm-12 col-md-9 offset-md-3">
                        <button className="btn btn-primary btn-sm" type="submit">Save Changes</button>
                    </div>
                </div>
            </form>
        </div>


    }

    renderEmptyState() {
        return <AccountSignedOutEmptyState/>;
    }

}

export default withRouter(AccountNotificationsEdit)