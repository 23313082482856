import React from "react";
import CopyToClipboard from '../extra/CopyToClipboard';

// labelSpan, valueSpan, copySpan -> How many columns takes the cell?

export default class TableDataField extends React.Component {

    render() {
        const {label,value,copyable, labelSpan, copySpan, valueSpan} = this.props;
        return (
            <tr>
                <td style={{width:"170px"}} colspan={labelSpan || 1} className="p-2">{label}</td>
                <td style={{wordBreak: "break-all"}} colspan={valueSpan || 1} className="p-2 text-dark font-weight-bold overflow-hidden">
                    {value}
                </td>
                <td style={{width:"50px"}} colspan={copySpan || 1} className="p-2">
                    {copyable && value !== '' && <CopyToClipboard text={value}/>}
                </td>
            </tr>
        );
    }

}