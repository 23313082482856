import React from 'react';
import { APP_FING_URL } from './Constants';

export const initUserContext = {
    userProfileLoaded: false,
    userProfile: {},
    ssoDomain: APP_FING_URL
};

export const fakeUserContext = {
    userProfileLoaded: true,
    userProfile: { id: 'somebody@email.com', name: 'John Applewood', email: 'somebody@email.com', firstName: 'John', lastName: 'Applewood'},
    ssoDomain: APP_FING_URL
};

function buildUrl(context, type, backurl) {
    const {ssoDomain} = context;
    return ssoDomain + '/' + type + (backurl ? '?backurl=' + encodeURIComponent(backurl) : '');
}

export function getLinkForSignIn(context, backurl) {
    return buildUrl(context, 'login', backurl);
}

export function getLinkForSignUp(context, backurl) {
    return buildUrl(context, 'register', backurl);
}

export function getLinkForSignOut(context, backurl) {
    return buildUrl(context, 'logout', backurl);
}

export function isUserProfileLoaded(context) {
    const {userProfileLoaded} = context;
    return userProfileLoaded;
}

export function isUserSignedIn(context) {
    const {userProfile, userProfileLoaded} = context;
    return userProfileLoaded && userProfile && userProfile.id && userProfile.name;
}

/**
 * Check whether the user has special permission
 *
 * N.B. The server MUST NOT trust the client. This flag should be used only to change
 *      behavior on client side. Server APIs will continue to check user and her permissions first.
 */
export function isGod(context) {
    const {userProfile, userProfileLoaded} = context;
    return userProfileLoaded && userProfile && userProfile.accountType === 'GOD';
}

export function hasUserConnectedDevices(context) {
    const {userProfile, userProfileLoaded} = context;
    return userProfileLoaded && userProfile && userProfile.id && userProfile.name && userProfile.mobileCount > 0;
}

export function getUserFirstName(context) {
    const {userProfile, userProfileLoaded} = context;
    if (userProfileLoaded && userProfile && userProfile.firstName) {
        return userProfile.firstName
    } else if (userProfileLoaded && userProfile && userProfile.name) {
        return userProfile.name
    }
    return null;
}

export function getUserPicture(context) {
    const {userProfile, userProfileLoaded} = context;
    if (userProfileLoaded && userProfile && userProfile.pictureUrl) {
        if (userProfile.pictureUrl.startsWith('images/')) {
            return APP_FING_URL + '/' + userProfile.pictureUrl;
        } else {
            return userProfile.pictureUrl;
        }
    }
    return APP_FING_URL+'/images/avatar/avatar-4.png';
}

export const UserContext = React.createContext(initUserContext);
export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;

