import React from "react";

import Tooltip from './Tooltip';


export default class TitleTable extends React.Component {

    render() {
        return (
            <div className="d-flex border-bottom mb-4">
                <div className="d-flex align-items-center justify-content-start">
                    <h2>{this.props.title}</h2>
                    {this.props.info && <Tooltip text={this.props.info} className="fa fa-info-circle"/>}
                </div>
                {this.props.children}
            </div>
        );
    }

}