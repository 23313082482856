import React from 'react';
import intl from 'react-intl-universal';

import {getLinkForSignIn, getUserFirstName, hasUserConnectedDevices, isUserSignedIn, UserContext} from "../UserContext";

import SignUpIllustration from "../assets/svg/illustrations/subscribe-3.svg";
import InstallAppIllustration from "../assets/svg/illustrations/server-woman.svg";
import AppStoreLogo from '../assets/svg/logos/app_store.svg';
import PlayStoreLogo from '../assets/svg/logos/play_store.svg';
import { APPLE_FING_URL, GOOGLE_FING_URL } from '../Constants';

export default class SignUpPromoSectionWhite extends React.Component {

    static contextType = UserContext;

    render() {
        if (hasUserConnectedDevices(this.context)) {
            return '';
        }

        if (isUserSignedIn(this.context)) {
            return (
                <div className="container space-2 border-top border-bottom mb-2">
                    <div className="row justify-content-lg-between align-items-lg-center">
                        {this.renderInstallAppIllustration()}
                        {this.renderInstallAppPromo()}
                    </div>
                </div>
            );
        }

        return (
            <div className="container space-2 border-top border-bottom mb-2">
                <div className="row justify-content-lg-between align-items-lg-center">
                    {this.renderSignUpIllustration()}
                    {this.renderSignUpPromo()}
                </div>
            </div>
        );
    }

    renderSignUpIllustration() {
        return (
            <div className="col-lg-6 mb-7 mb-lg-0">
                <figure id="SVGIllustration1" className="svg-preloader ie-subscribe-3">
                    <img className="img-fluid" src={SignUpIllustration} alt="Subscribe now"
                         data-parent="#SVGIllustration1"/>
                </figure>
            </div>
        );
    }

    renderInstallAppIllustration() {
        return (
            <div className="col-lg-6 mb-7 mb-lg-0">
                <figure id="SVGIllustration2" className="svg-preloader ie-subscribe-3">
                    <img className="img-fluid" src={InstallAppIllustration} alt="Install App"
                         data-parent="#SVGIllustration2"/>
                </figure>
            </div>
        );
    }

    renderSignUpPromo() {
        return (
            <div className="col-lg-5">
                <div className="mb-5">
                    <h2 className="text-primary">{intl.getHTML('promo_3_title')}</h2>
                    <p>{intl.get('promo_3_subtitle')}</p>
                </div>

                <a className="btn btn-primary btn-pill btn-wide transition-3d-hover font-size-1"
                   href={getLinkForSignIn(this.context, window.location.href)}>{intl.get('promo_3_action')} <span
                    className="fas fa-angle-right align-middle ml-2"/></a>
            </div>
        );
    }

    renderInstallAppPromo() {
        const name = getUserFirstName(this.context);
        return (
            <div className="col-lg-5">
                <div className="mb-5">
                    <h2 className="text-primary">{intl.getHTML('promo_4_title')}</h2>
                    <ul className="list-inline text-warning small">
                        <li className="list-inline-item mx-0">
                            <span className="fas fa-star"/>
                        </li>
                        <li className="list-inline-item mx-0">
                            <span className="fas fa-star"/>
                        </li>
                        <li className="list-inline-item mx-0">
                            <span className="fas fa-star"/>
                        </li>
                        <li className="list-inline-item mx-0">
                            <span className="fas fa-star"/>
                        </li>
                        <li className="list-inline-item mx-0">
                            <span className="fas fa-star"/>
                        </li>
                    </ul>
                    <p>{intl.getHTML('promo_4_subtitle', {name: name})}</p>
                </div>

                <div>
                    <a href={APPLE_FING_URL}
                       className="btn btn-xs btn-wide transition-3d-hover mb-2">
                                    <span id="SVGAppStore" className="media align-items-center">
                                        <figure>
                                            <img className="img-fluid"
                                                 src={AppStoreLogo} alt="App Store Logo"
                                                 data-parent="#SVGAppStore" />
                                        </figure>
                                    </span>
                    </a>

                    <a href={GOOGLE_FING_URL}
                       className="btn btn-xs btn-wide transition-3d-hover mb-2">
                                    <span id="SVGPlayStore" className="media align-items-center">
                                        <figure>
                                            <img className="img-fluid"
                                                 src={PlayStoreLogo} alt="Play Store Logo"
                                                 data-parent="#SVGPlayStore" />
                                        </figure>
                                    </span>
                    </a>
                </div>
            </div>
        );
    }

}