import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
// Standard Pages
import ThankYouPage from './components/ThankYouPage';
import NotFound from "./components/NotFound";
// Test Pages

import {initSessionContext, SessionProvider} from "./SessionContext";
import {initUserContext, UserProvider,} from "./UserContext";
// Locale
// TODO: Import only the current locale?
import intl from 'react-intl-universal';
import messages_en from './assets/locale/en-US.json'
// Internet
import Home from './internet/Home';
import IspList from './internet/isp/IspList';
import IspView from './internet/isp/IspView';
import OutageView from "./internet/outage/OutageView";
import MainHelpArticle from "./internet/outage/help/MainHelpArticle";
import OutageHelpArticle from "./internet/outage/help/OutageHelpArticle";
import OutageTrends from "./internet/outage/help/OutageTrends";
import PlaceOverview from "./internet/place/PlaceOverview";
import TopOutageReport from "./internet/outage/TopOutageReport";
import OutageList from "./internet/outage/OutageList";
import OutageLiveMap from "./internet/outage/OutageLiveMap";
// Query / Desktop
import NewHelpTicketView from "./query/NewHelpTicketView";
import OpenHelpTicketView from "./query/OpenHelpTicketView";
import ViewHelpTicketView from "./query/ViewHelpTicketView";
import ListHelpTicketView from "./query/ListHelpTicketView";
// Account
import AccountDashboard from "./account/AccountDashboard";
import WorkspaceDashboard from "./account/WorkspaceDashboard";
import AccountConnections from "./account/AccountConnections";
import AccountApps from "./account/AccountApps";
import AccountProfileEdit from './account/AccountProfileEdit';
import AccountPasswordEdit from './account/AccountPasswordEdit';
import AccountNotificationsEdit from './account/AccountNotificationsEdit';
import AccountRootPage from "./account/AccountRootPage";
import SubscribeRedeem from "./account/SubscribeRedeem";
import AdminPostListPage from "./components/table/AdminPostListPage";
import AdminPostEditPage from "./components/table/AdminPostEditPage";
import AccountProfileDelete from "./account/AccountProfileDelete";
import AdminValidationListPage from './components/table/AdminValidationListPage';
import AdminValidationEditPage from './components/table/AdminValidationEditPage';
import AdminDiscountOfferListPage from './components/table/AdminDiscountOfferListPage';
import AdminDiscountOfferEditPage from './components/table/AdminDiscountOfferEditPage';
import AdminAgentListPage from './components/table/AdminAgentListPage';
import AdminAgentEditPage from './components/table/AdminAgentEditPage';
// import messages_it from './assets/locale/it-IT.json'
import AdminConnectionListPage from './components/table/AdminConnectionListPage';
import AdminConnectionEditPage from './components/table/AdminConnectionEditPage';
import AdminUserListPage from './components/table/AdminUserListPage';
import AdminUserEditPage from './components/table/AdminUserEditPage';
import AdminIAMListPage from './components/table/AdminIAMListPage';
import AdminIABListPage from './components/table/AdminIABListPage';
import AdminIAMEditPage from './components/table/AdminIAMEditPage';
import AdminIABEditPage from './components/table/AdminIABEditPage';
import { APP_FING_URL } from './Constants';
import SubscribeEmbeddedCheckout from "./account/SubscribeEmbeddedCheckout";
import SubscribeEmbeddedComplete from "./account/SubscribeEmbeddedComplete";
import SubscribeCart from "./account/SubscribeCart";
import SubscribeRedirectToDashboard from "./account/SubscribeRedirectToDashboard";
import SubscribeRedirectToCart from "./account/SubscribeRedirectToCart";
import WorkspaceManage from "./account/WorkspaceManage";
import WorkspaceNotifications from "./account/WorkspaceNotifications";

const SUPPORTED_LOCALES = [
    {
        name: "English",
        value: "en-US",
        messages: messages_en
    },
    /*
        {
            name: "Italiano",
            value: "it-IT",
            messages: messages_it
        },
    */
];

class App extends Component {

    constructor(props) {
        super(props);


        // Create global fn to update the context in this Component's state
        this.userContextUpdater = (newUserContext) => {
            this.setState({ userContext: newUserContext });
        };

        // Start with init context and implement the updater function to modify
        // this component's state. That will trigger render() when profile gets changed app-wise.
        const initContext = Object.assign({}, initUserContext);
        initContext.userContextUpdater = this.userContextUpdater;


        this.state = {
            sessionContext: initSessionContext,
            userContext: initContext,
            localeLoaded: false,
        };
    }

    componentDidMount() {
        this.loadLocales();
        this.sessionFetchSequence();
        this.profileFetchSequence();
    }

    loadLocales() {
        let currentLocale = intl.determineLocale({
            urlLocaleKey: "lang",
            cookieLocaleKey: "lang"
        });

        // console.log("Current locale (deduced): " + currentLocale);
        if (SUPPORTED_LOCALES.filter(l => l.value === currentLocale).length === 0) {
            currentLocale = "en-US";
            // currentLocale = "it-IT";
        }

        // console.log("Current locale (in use): " + currentLocale);

        let locales = {};
        SUPPORTED_LOCALES.forEach(l => locales[l.value] = l.messages);

        intl.init({
            currentLocale,
            locales: locales
        });
        this.setState({ localeLoaded: true });
    }

    sessionFetchSequence() {
        return fetch('/rest/internet/me')
            .then(response => this.validateRestResponse(response))
            .then(response => response.json())
            .then(data => {
                if (data && data.currentIsp) {
                    this.setState({ sessionContext: { sessionLoaded: true, session: data } });
                } else {
                    this.setState({ sessionContext: { sessionLoaded: true, session: {} } });
                }
            })
            .catch(error => {
                this.setState({ sessionContext: { sessionLoaded: true, session: {} } });
            });
    }

    profileFetchSequence() {
        return fetch('/rest/users/sso')
            .then(response => this.validateRestResponse(response))
            .then(response => response.json())
            .then(data => {
                let sso = data && data.ssoDomain ? data.ssoDomain : APP_FING_URL;
                if (data && data.userProfile && data.userProfile.id) {
                    this.setState({
                        userContext: {
                            userProfileLoaded: true,
                            userProfile: data.userProfile,
                            stripePublicApiKey: data.stripePublicApiKey,
                            ssoDomain: sso,
                            userContextUpdater: this.userContextUpdater
                        }
                    });
                } else {
                    this.setState({
                        userContext: {
                            userProfileLoaded: true,
                            userProfile: {},
                            ssoDomain: sso,
                            userContextUpdater: this.userContextUpdater
                        }
                    });
                }
            })
            .catch(error => {
                this.setState({ userContext: { userProfileLoaded: true, userProfile: {}, ssoDomain: APP_FING_URL } });
            });
    }

    validateRestResponse(response) {
        if (response.status !== 200) {
            throw Error(response.statusText);
        }
        return response;
    }

    render() {
        return (
            this.state.localeLoaded &&
            <SessionProvider value={this.state.sessionContext}>
                <UserProvider value={this.state.userContext}>
                    <BrowserRouter basename={'/internet'}>
                        <Switch>

                            {/* HELPME / QUERY (for Desktop) */}
                            <Route path='/helpme/new/:datablob' exact={true} component={NewHelpTicketView} />
                            <Route path='/helpme/open/:ticketid' exact={true} component={OpenHelpTicketView} />
                            <Route path='/helpme/view/:ticketid' exact={true} component={ViewHelpTicketView} />
                            <Route path='/helpme/view' exact={true} component={ListHelpTicketView} />
                            <Route path='/helpme/list' exact={true} component={ListHelpTicketView} />

                            {/* ACCOUNT */}
                            <Route path='/account/dashboard' exact={true} component={AccountDashboard} />
                            <Route path='/account/connections' exact={true} component={AccountConnections} />
                            <Route path='/account/apps' exact={true} component={AccountApps} />
                            <Route path='/account/profile/edit' exact={true} component={AccountProfileEdit} />
                            <Route path='/account/profile/delete' exact={true} component={AccountProfileDelete} />
                            <Route path='/account/password/edit' exact={true} component={AccountPasswordEdit} />
                            <Route path='/account/notifications/edit' exact={true} component={AccountNotificationsEdit} />
                            <Route path='/account/payment/methods' exact={true} component={SubscribeRedirectToDashboard} />
                            <Route path='/account/payment/invoices' exact={true} component={SubscribeRedirectToDashboard} />
                            <Route path='/account/subscribe' exact={true} component={SubscribeRedirectToCart} />
                            <Route path='/account/subscribe/cart' exact={true} component={SubscribeCart} />
                            <Route path='/account/subscribe/setup' exact={true} component={SubscribeRedirectToCart} />
                            <Route path='/account/subscribe/redeem' exact={true} component={SubscribeRedeem} />
                            <Route path='/account/subscribe/checkout' exact={true} component={SubscribeRedirectToCart} />
                            <Route path='/account/subscribe/embedded-checkout' exact={true} component={SubscribeEmbeddedCheckout} />
                            <Route path='/account/subscribe/complete' exact={true} component={SubscribeRedirectToDashboard} />
                            <Route path='/account/subscribe/embedded-complete' exact={true} component={SubscribeEmbeddedComplete} />
                            <Route path='/account/subscribe/change' exact={true} component={SubscribeRedirectToCart} />
                            <Route path='/account' exact={true} component={AccountRootPage} />

                            <Route path='/account/workspace' exact={true} component={WorkspaceDashboard} />
                            <Route path='/account/workspace/manage' exact={true} component={WorkspaceManage} />
                            <Route path='/account/workspace/notifications' exact={true} component={WorkspaceNotifications} />

                            {/* UTILS PAGE */}
                            <Route path='/thank-you/:event' exact={true} component={ThankYouPage}/>
                            {/*<Route path='/test-table' exact={true} component={AdminTableTestPage}/>*/}
                            <Route path='/admin/posts' exact={true} component={AdminPostListPage}/>
                            <Route path='/admin/post/:postId' exact={true} component={AdminPostEditPage}/>
                            <Route path='/admin/validations' exact={true} component={AdminValidationListPage}/>
                            <Route path='/admin/validation/:validationId' exact={true} component={AdminValidationEditPage}/>
                            <Route path='/admin/offers' exact={true} component={AdminDiscountOfferListPage}/>
                            <Route path='/admin/offer/:discountOfferId' exact={true} component={AdminDiscountOfferEditPage}/>
                            <Route path='/admin/agents' exact={true} component={AdminAgentListPage}/>
                            <Route path='/admin/agent/:agentId' exact={true} component={AdminAgentEditPage}/>
                            <Route path='/admin/connections' exact={true} component={AdminConnectionListPage}/>
                            <Route path='/admin/connection/:connId' exact={true} component={AdminConnectionEditPage}/>
                            <Route path='/admin/users' exact={true} component={AdminUserListPage}/>
                            <Route path='/admin/user/:userId' exact={true} component={AdminUserEditPage}/>
                            
                            <Route path='/admin/list-iam' exact={true} component={AdminIAMListPage}/>
                            <Route path='/admin/iam/:iamId' exact={true} component={AdminIAMEditPage}/>
                            <Route path='/admin/list-iab' exact={true} component={AdminIABListPage}/>
                            <Route path='/admin/iab/:iabId' exact={true} component={AdminIABEditPage}/>
                            
                            

                            {/* INTERNET */}
                            <Route path='/place/:country/:region/:city' exact={true} component={PlaceOverview} />
                            <Route path='/place/:country/:region' exact={true} component={PlaceOverview} />
                            <Route path='/place/:country' exact={true} component={PlaceOverview} />
                            <Route path='/list/:country' exact={true} component={IspList} />
                            <Route path='/provider/:ispId/:country/:region/:city' exact={true} component={IspView} />
                            <Route path='/provider/:ispId/:country/:region/' exact={true} component={IspView} />
                            <Route path='/provider/:ispId/:country/' exact={true} component={IspView} />
                            <Route path='/outage/:reportId/:tz?' component={OutageView} />
                            <Route path='/outages' exact={true} component={OutageList} />
                            <Route path='/outages/live' exact={true} component={OutageLiveMap} />
                            <Route path='/outages/top-report/:continent/:days' exact={true} component={TopOutageReport} />
                            <Route path='/outages/top-report/:continent' exact={true} component={TopOutageReport} />
                            <Route path='/outages/top-report' exact={true} component={TopOutageReport} />
                            <Route path='/help' exact={true} component={MainHelpArticle} />
                            <Route path='/help/what-s-an-outage' exact={true} component={OutageHelpArticle} />
                            <Route path='/help/outage-trends' exact={true} component={OutageTrends} />
                            <Route path='/outage-detection-for-business' exact={true} component={OutageHelpArticle} />
                            <Route path='/' exact={true} component={Home} />

                            {/* NOT FOUND */}
                            <Route component={NotFound} />
                        </Switch>
                    </BrowserRouter>
                </UserProvider>
            </SessionProvider>
        )
    }
}

export default App;