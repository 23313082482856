const languages = require('../internet/place/languages.json');
const all_timezones = require('../internet/place/timezones.json');

export function flatObjectPayload(obj) {
    let objToReturn = {};

    Object.keys(obj).forEach((key) => {

        if (key.startsWith('__') && Object.keys(obj[key])) {

            // case where into __key we have other keys to copy 
            Object.keys(obj[key]).forEach((nestedKey) => {
                objToReturn[nestedKey] = obj[key][nestedKey];
            })
        } else if (!key.startsWith('__')) {

            // if __key are values we don't copy them, but we copy the others 
            objToReturn[key] = obj[key];
        }
    });

    return objToReturn;
} 

export function isMissingProfileValues(userProfile) {

    if (!userProfile.firstName) {
        return true;
    } 
    if (!userProfile.lastName) {
        return true;
    } 
    if (!userProfile.name) {
        return true;
    } 
    if (!userProfile.companyName) {
        return true;
    } 
    // if (!userProfile.professionalField) {
    //     return true;
    // }
    // if (!userProfile.jobTitle) {
    //     return true;
    // }
    if (!userProfile.pictureUrl) {
        return true;
    } 
    if (!userProfile.techLevel) {
        return true;
    } else if (userProfile.techLevel === techLevels[0].value) {
        return !userProfile.jobRole || !userProfile.companyDomain;
    }
    return false;
}

export function languageCodes() {

    const toReturn = [];

    for (let langCode of Object.keys(languages)) {
        const language = languages[langCode];
        toReturn.push({ value: langCode, label: language.name + (language.localizedName ?
                " (" + language.localizedName + ")" : "") })
    }
    toReturn.sort(function (a, b) {
        if (a && a.label && b && b.label) {
            return a.label.localeCompare(b.label);
        }
        return 0;
    });

    return toReturn;
}

export function getPrettyProfession(currentProfession) {

    for (let section of Object.keys(professions)) {
        for (let profession of professions[section]) {
            if (profession.value === currentProfession) {
                return profession.label;
            }
        }
    }

    return null;
}

export function getPrettyJobRole(currentJobRole) {

    for (let jobRole of jobRoles) {
        if (jobRole.value === currentJobRole) {
            return jobRole.label;
        }
    }

    return null;
}

export function timezones() {

    const toReturn = [];

    for (let timeZone of TZ_SET) {
        toReturn.push(timeZone.replace(/_/g, ' '));
    }

    return toReturn.sort();

}

export function getPrettyLanguage(code) {
    if (languages[code]) return languages[code].localizedName;
    return null;
}

export const techLevels = [
    { value: "TECH_EXPERT", label: "I am an IT professional", text: "I work in IT in developing and setting up systems based on technology" },
    { value: "TECH_PROFICIENT", label: "I am tech confident, not professional", text: "I like to manage technology at home and at the office, although it's not an official role." },
    { value: "TECH_NOVICE", label: "I am not confident with technology", text: "I rely on somebody else when there's a tech issue." }
];


export const alertMailType = [
    { value: "DISABLED", text: "Do not send" },
    { value: "SUMMARY", text: "A summary e-mail with multiple events" },
    { value: "SUMMARY_PLAIN", text: "A summary e-mail with multiple events - Plain style" },
    { value: "SUBJECT", text: "A separate email for each event" },
    { value: "SUBJECT_PLAIN", text: "A separate email for each event - Plain style" }
];

export const ispAlertType = [
    { value: "DISABLED", text: "Do not send" },
    { value: "ISP_IN_GEOLOC", text: "Outages of my ISP in my area" }
];

export const alertMessageType = [
    { value: "DISABLED", text: "Do not send" },
    { value: "SINGLE", text: "A message for each event" }
];

export const professions = {
    production: [
        { value: "IT_INFRASTRUCTURE", label: "IT Infrastructure" },
        { value: "DEVELOPMENT", label: "Development" },
        { value: "DESIGN", label: "Design" },
        { value: "QUALITY_ASSURANCE", label: "Quality Assurance" },
        { value: "DATA_MANAGEMENT", label: "Data management" }
    ],
    operations: [
        { value: "FINANCE", label: "Finance" },
        { value: "MARKETING", label: "Marketing" },
        { value: "OPERATIONS", label: "Operations" },
        { value: "SALES", label: "Sales" },
        { value: "HUMAN_RESOURCES", label: "Human Resources" },
        { value: "SECURITY", label: "Security" },
        { value: "CUSTOMER_SERVICE", label: "Customer Service" },
        { value: "ADMINISTRATION", label: "Administration" },
        { value: "LEGAL_N_COMPLIANCE", label: "Legal & Compliance" }
    ],
    management: [
        { value: "MANAGEMENT_N_EXECUTIVE", label: "Management & Executive" },
        { value: "BUSINESS_OWNERSHIP", label: "Business Ownership" },
        { value: "FREELANCE", label: "Freelance" },
        { value: "OTHER", label: "Other" }
    ]
};

export const jobRoles = [
    { value: "IT_ADMINISTRATOR", label: "IT Administrator" },
    { value: "IT_DIRECTOR", label: "IT Director" },
    { value: "SECURITY_ADMINISTRATOR", label: "Security Administrator" },
    { value: "SOFTWARE_DEVELOPER", label: "Software Developer" },
    { value: "SUPPORT_SPECIALIST", label: "Support Specialist" },
    { value: "DEV_OPS", label: "DavOps" },
    { value: "OTHER_IT_PRO", label: "Other IT Professional" }
];

export function getOrganizedProfessionalFields() {

    let toReturn = [];

    for (let section of Object.keys(professions)) {
        toReturn.push({
            label: section,
            options: professions[section]
        })
    }

    return toReturn;
}

export function getJobRoles() {
    return jobRoles;
}


export const AVATAR_SET = [
    "images/avatar/avatar-1.png",
    "images/avatar/avatar-2.png",
    "images/avatar/avatar-3.png",
    "images/avatar/avatar-4.png",
    "images/avatar/avatar-5.png",
    "images/avatar/avatar-6.png",
    "images/avatar/avatar-7.png",
    "images/avatar/avatar-8.png",
    "images/avatar/avatar-9.png",
    "images/avatar/avatar-10.png",
    "images/avatar/avatar-11.png",
    "images/avatar/avatar-12.png",
    "images/avatar/avatar-13.png",
    "images/avatar/avatar-14.png",
    "images/avatar/avatar-15.png",
    "images/avatar/avatar-16.png",
    "images/avatar/avatar-17.png",
    "images/avatar/avatar-18.png",
    "images/avatar/avatar-19.png",
    "images/avatar/avatar-20.png",
    "images/avatar/avatar-21.png",
    "images/avatar/avatar-22.png",
    "images/avatar/avatar-23.png",
    "images/avatar/avatar-24.png"
];


export const TZ_SET = all_timezones;

export function isPermissionAllowed(userProfile, permissionId) {
    if (!userProfile) return false;
    const permissions = userProfile.permissions;
    if (!permissions || permissions.length === 0) return false;
    const permission = permissions.find(p => p.id === permissionId);
    if (!permission) return false;

    return permission.allowed === true;
}


export function isPermissionAllowedForWorkspace(userProfile, workspaceId, permissionId) {
    if (!userProfile) return false;
    const permissionSets = userProfile.workspacePermissionSets;
    if (!permissionSets || permissionSets.length === 0) return false;

    const permissionsSet = permissionSets.find(ps => ps.workspaceId === workspaceId);
    const permissions = permissionsSet ? permissionsSet.permissions : null;
    if (!permissions || permissions.length === 0) return false;
    const permission = permissions.find(p => p.id === permissionId);
    if (!permission) return false;

    return permission.allowed === true;
}