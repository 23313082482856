import React,{Component} from "react";
import ActionButton, { BTN_SIZE_BIG, BTN_TINT_LIGHT } from "./ActionButton";
import ModalDialog, { ModalDialogBody } from "./ModalDialog";
import SegmentedBar from "../SegmentedBar";
export default class IABDialog extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedIndex: 0,
        }
    }

    render() {

        let style = null;
        let isMobile = false;
        let isDesktop = false;
        const onItemSelected = (index) => this.setState({selectedIndex: index});

        const idx = this.state.selectedIndex;

        const msg = this.props.message;
        if(msg?.style){
            style = msg.style;
        } 
        if(msg?.target?.platforms){
            const platform = msg.target.platforms;
            isMobile = platform.includes('ios') || platform.includes("android");
            isDesktop = platform.includes('windows') || platform.includes("macos");
        }

        if(!style) return '';


        return <ModalDialog 
            id={this.props.id} 
            onClose={this.props.onClose}
        >
            <ModalDialogBody>
                <div className="my-2 d-flex justify-content-between">
                    <SegmentedBar 
                        className="nav-tabs" 
                        onItemSelected={onItemSelected}
                        activeIndex={idx}
                        items={[
                            {label: 'Desktop'},
                            {label: 'Mobile', badge: "Light"},
                            {label: 'Mobile', badge: "Dark"},
                        ]}/>
                </div>
                {idx === 0 && 
                    (isDesktop ? this.renderDesktopMode(style) : 
                        <div className="alert alert-soft-warning">Not available</div>)}
                {idx === 1 && 
                    (isMobile ? this.renderMobileModeLight(style) :
                        <div className="alert alert-soft-warning">Not available</div>)}
                {idx === 2 && 
                    (isMobile ? this.renderMobileModeDark(style) :
                        <div className="alert alert-soft-warning">Not available</div>)}
                
            </ModalDialogBody>
        </ModalDialog>;
    }

    renderMobileModeLight(message){

        const promoSectionHeight = 185;

        let title = '';
        let body = '';
        let extra = '';
        let borderColor = '';
        let extraColor = '';
        if(message?.bodies?.en){
            body = message.bodies.en;
        }
        if(message?.titles?.en){
            title = message.titles.en;
        } 
        if(message?.extras?.en){
            extra = message.extras.en;
        }
        if(message?.colors?.light){
            borderColor = message.colors.light.border;
            extraColor = message.colors.light.extra;
        }
        
        
        return (
            <div className="card border-3" style={{ height: promoSectionHeight, borderColor: borderColor }}>
                <div className="card-title text-center" style={{backgroundColor:borderColor, color:extraColor}}>
                    {extra}
                </div>
                <div className="card-body py-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className="modal-title mb-1">{title}</h5>
                            <p className="h6">{body}</p>
                        </div>
                        <img src={this.getImages(message)} style={{ heoght:130, width:130, objectFit: "cover"}}/>

                    </div>
                </div>
                
            </div>
        );
    }

    renderMobileModeDark(message){
        const promoSectionHeight = 185;

        let title = '';
        let body = '';
        let extra = '';
        let borderColor = '';
        let extraColor = '';
        if(message?.bodies?.en){
            body = message.bodies.en;
        }
        if(message?.titles?.en){
            title = message.titles.en;
        }
        if(message?.extras?.en){
            extra = message.extras.en;
        }
        if(message?.colors?.dark){
            borderColor = message.colors.dark.border;
            extraColor = message.colors.dark.extra;
        }
        return (
            <div>
                <div className="card border-3" style={{ height: promoSectionHeight, borderColor: borderColor }}>
                    <div className="card-title text-center" style={{backgroundColor:borderColor, color:extraColor}}>
                        {extra}
                    </div>
                    <div className="card-body py-0" style={{backgroundColor: "#272829"}}>
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <h5 className="modal-title mb-1" style={{color:extraColor}}>{title}</h5>
                                <p className="h6" style={{color:extraColor}}>{body}</p>
                            </div>
                            <img src={this.getImages(message)} style={{ heoght:130, width:130, objectFit: "cover"}}/>
                        </div>
                    </div>
                </div>
            </div>
            
        );
    }

    getImages(messageStyle){

        if (messageStyle?.images?.animated) {
            return messageStyle.images.animated;
        }

        if (messageStyle?.images?.landscape) {
            return messageStyle.images.landscape;
        }

        return messageStyle?.images?.default;
    }

    renderDesktopMode(message) {
    
        const promoSectionHeight = 185;

        let text = '';
        let actionText = '';
        if(message?.extras?.en){
            text = message.extras.en;
        }
        if(message?.primary_button?.texts?.en){
            actionText = message.primary_button.texts.en;
        }
        
    
        return (
            <div className="bg-primary" style={{ height: promoSectionHeight }}>
                <li className="d-block px-3 h-75 position-relative">
                    <DiscountRightArrow text={text} 
                        className="position-absolute bottom-0 left-0 right-0 pl-2 pb-2" 
                    />
                </li>
                <li className="d-block px-3">
                    <ActionButton className="d-block w-100 text-primary"
                        tint={BTN_TINT_LIGHT} 
                        size={BTN_SIZE_BIG} 
                        title={actionText} 
                    />
                </li>
            </div>
        );
    }
}
class DiscountRightArrow extends React.Component {
    render() {
        return <figure className={this.props.className}>
            <span className="badge text-nowrap badge-warning badge-pill text-dark p-2 ml-2 text-truncate" 
                style={{ maxWidth: "70%" }}
            >
                {this.props.text}
            </span>
            <RightArrow className="position-absolute mt-2 ml-1" />
        </figure>;
    }
}

const RightArrow = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 99.3 57"
        width="38" transform="scale(-1, 1)"
    >
        <path fill="none" stroke="#bdc5d1" strokeWidth="4" strokeLinecap="round"
            strokeMiterlimit="10"
            d="M2,39.5l7.7,14.8c0.4,0.7,1.3,0.9,2,0.4L27.9,42" 
        />
        <path fill="none" stroke="#bdc5d1" strokeWidth="4" strokeLinecap="round"
            strokeMiterlimit="10" d="M11,54.3c0,0,10.3-65.2,86.3-50" 
        />
    </svg>
);