export const MANDATORY = {
    CONNECTION: {
        clientName: true
    },
    OFFER: {
        description: false,
        promoText: false,
        startDate: false,
        endDate: false,
        enabled: false,
        discountPlatform: false,
        allowedPlans: false,
        allowedCountries: false,
        purchaseRules: false,
        discountId: false
    },
    IAB: {
        iabId: true,
        layout: true,
        locationId: true,
        "action": true,
        "image-default": true,
        "conversion": true,
        "platforms": true
    },
    IAM: {
        iamId: true,
        layout: true,
        "action-button": true,
        "image-default": true,
        "conversion-display": true,
        "conversion-click": true,
        "platforms": true
    },
    POST: {
        contentUrl: true,
        title: true,
        description: true,
        category: true,
        format: true,
        languageCode: true,
        state: true
    },
    VALIDATION: {
        creationDate: false,
        userId: false,
        comment: false,
        gatewayHwAddress: false,
        blockingClientHwAddress: false,
        blockingClientId: false,
        blockedDevices: false,
        blockedDevicesCount: false,
        publicIpAddress: false,
        validationState: true,
        termsAcceptanceDate: false,
    }
}