import React from "react";

const badge = (text,color) => <span className={"badge badge-soft-" + color}>{text}</span>;

export const ENUMS = {
    COUNTRIES: [
        { value: "en", label: "EN"},
        { value: "ja", label: "JA"},
        { value: "ko", label: "KO"},
        { value: "ru", label: "RU"},
        { value: "ar", label: "AR"},
        { value: "de", label: "DE"},
        { value: "es", label: "ES"},
        { value: "fr", label: "FR"},
        { value: "id", label: "ID"},
        { value: "nl", label: "NL"},
        { value: "pt", label: "PT"},
        { value: "tr", label: "TR"},
        { value: "vi", label: "VI"},
        { value: "zh", label: "ZH"},
        { value: "zh-tw", label: "TW"},
        { value: "it", label: "IT"}
    ],
    MESSAGE:{
        HAS_HARDWARE:[
            { value: "all", label: "-"},
            { value: "true", label: "True"}, 
            { value: "false", label: "False"}
        ],
        LOCATION: [
            { value: "DASHBOARD_BANNER", label: "Dashboard", badge: badge('Dashboard', 'primary')}, 
            { value: "ACCOUNT_BANNER", label: "Account", badge: badge('Account', 'primary')},
            { value: "SIDEBAR_PROMOTION", label: "Sidebar", badge: badge('Sidebar', 'primary')}
        ],
        LAYOUT: {
            IAM: [
                { value: "CARD", label: "Card", badge: badge('Card', 'primary')}, 
                { value: "IMAGE", label: "Image", badge: badge('Image', 'primary')}, 
                { value: "MODAL", label: "Modal", badge: badge('Modal', 'primary')},
                { value: "TOP_BANNER", label: "Top banner", badge: badge('Top banner', 'primary')}, 
            ],
            IAB:[
                { value: "CARD", label: "Card", badge: badge('Card', 'primary')},
                { value: "SIDE_BANNER", label: "Side banner", badge: badge('Side banner', 'primary')},
            ],
        },
        PLATFORM: [
            { value: "android", label: "Android", badge: badge('Android', 'primary')}, 
            { value: "ios", label: "IOS", badge: badge('IOS', 'primary')}, 
            { value: "macos", label: "MacOS", badge: badge('MacOS', 'primary')},
            { value: "windows", label: "Windows", badge: badge('Windows', 'primary')},
        ],
        PURCHASE_STATE: [
            { value: "NEVER", label: "Never", badge: badge('Never', 'dark')}, 
            { value: "TRIAL_ACTIVE", label: "Trial active", badge: badge('Trial active', 'success')}, 
            { value: "TRIAL_CANCELLED", label: "Trial cancelled", badge: badge('Trial cancelled', 'danger')},
            { value: "TRIAL_EXPIRED", label: "Trial expired", badge: badge('Trial expired', 'warning')},
            { value: "PAYMENT_ACTIVE", label: "Payment active", badge: badge('Payment active', 'success')}, 
            { value: "PAYMENT_CANCELLED", label: "Payment cancelled", badge: badge('Payment cancelled', 'primary')}, 
            { value: "PAYMENT_EXPIRED", label: "Payment expired", badge: badge('Payment expired', 'warning')},
            { value: 'PAYMENT_SUSPENDED', label: "Payment suspended", badge: badge('Payment suspended', 'danger')}
        ],
        ACCOUNT_TYPE: [
            { value: "NONE", label: "None",badge: badge('None', 'dark')}, 
            { value: "FREE", label: "Free",badge: badge('Free', 'primary')}, 
            { value: "STARTER", label: "Starter",badge: badge('Starter', 'warning')}, 
            { value: "PREMIUM", label: "Premium",badge: badge('Premium', 'success')},
        ],
        TECH_ATTITUDE: [
            { value: "PRO_OF_TECH", label: "Professional" },
            { value: "CONFIDENT_WITH_TECH", label: "Confident" },
            { value: "HANDLES_TECH", label: "Handler" },
            { value: "FEARS_TECH", label: "Feared" }
        ],
    },
    USER:{
        ROLES: [
            { value: "USER_ADMIN", label: "User Admin"}, 
            { value: "USER_SUPPORT", label: "User Support"}, 
            { value: "FINGBOX_ADMIN", label: "Fingbox Admin"},
            { value: "FINGBOX_SUPPORT", label: "Fingbox Support"},
            { value: "DASHBOARD_USER", label: "Dashboard User"}, 
            { value: "FENCE_DEMO_USER", label: "Digital Fence Demo User"}, 
            { value: "FINGKIT_ADMIN", label: "FingKit Admin"}, 
            { value: "CONTENT_CURATOR", label: "Content Curator"},
        ],
        ISP_ALERT_TYPE: [
            { value: "DISABLED", label: "Do not send" },
            { value: "ISP_IN_GEOLOC", label: "Outages of my ISP in my area" }
        ],
        TECH_ATTITUDE: [
            { value: "PRO_OF_TECH", label: "Professional" },
            { value: "CONFIDENT_WITH_TECH", label: "Confident" },
            { value: "HANDLES_TECH", label: "Handler" },
            { value: "FEARS_TECH", label: "Feared" }
        ],
        ACCOUNT_TYPE: [
            { value: "FREE", label: "Free",badge: <span className="badge badge-success mr-1">Free</span>},
            { value: "STARTER", label: "Starter",badge: <span className="badge badge-primary mr-1">Starter</span>},
            { value: "PREMIUM", label: "Premium",badge: <span className="badge badge-primary mr-1">Premium</span>},
            { value: "PROFESSIONAL", label: "Professional",badge: <span className="badge badge-primary mr-1">Professional</span>},
            { value: "GOD", label: "God",badge: <span className="badge badge-dark mr-1">God</span>},
        ],
        ALERT_MAIL_TYPE: [
            { value: "DISABLED", label: "Do not send" },
            { value: "SUMMARY", label: "A summary e-mail with multiple events" },
            { value: "SUMMARY_PLAIN", label: "A summary e-mail with multiple events - Plain style" },
            { value: "SUBJECT", label: "A separate email for each event" },
            { value: "SUBJECT_PLAIN", label: "A separate email for each event - Plain style" }
        ],
        ALERT_MESSAGE_TYPE: [
            { value: "DISABLED", label: "Do not send" },
            { value: "SINGLE", label: "Outages of my ISP in my area" }
        ]
    },
    AGENT:{
        STATE:[
            {value: "", label: "Any"},
            {value: "UNASSIGNED", label: "Unassigned", badge: badge('Unassigned', 'dark')},
            {value: "ASSIGNED", label: "Assigned", badge: badge('Assigned', 'primary')},
            {value: "ACTIVATED", label: "Activated", badge: badge('Activated', 'success')},
        ],
        CONNECTION:[
            {value: "", label: "Any"},
            {value: "CONNECTED", label: "Connected", badge: badge('Connected', 'success')},
            {value: "DISCONNECTED", label: "Disconnected", badge: badge('Disconnected', 'danger')},
        ]
    },
    CONNECTION:{
        TYPE:[
            { value: "", label: "ANY" },
            { value: "MOBILE", label: "MOBILE" },
            { value: "DESKTOP", label: "DESKTOP" },
            { value: "SENTINEL", label: "SENTINEL" },
            { value: "BROWSER", label: "BROWSER" },
            { value: "EMBEDDED", label: "EMBEDDED" },
        ]
    },
    VALIDATION:{
        STATE:[
            { value: "", label: "Any"},
            { value: "APPROVED", label: "Approved" },
            { value: "PENDING", label: "Pending" },
            { value: "REJECTED", label: "Rejected" },
        ]
    },
    DISCOUNT_OFFER:{
        PLATFORM:[
            { value: "", label: "Any"},
            { value: "APP_STORE", label: "APP STORE" },
            { value: "PLAY_STORE", label: "PLAY STORE" },
            { value: "STRIPE", label: "STRIPE" },
        ]
    },
    POST:{
        CATEGORY:[
            {value: "REVIEW", label: "Review"},
            {value: "COMPARISON", label: "Comparison"},
            {value: "HOW_TO", label: "How-to"},
            {value: "TIP_N_TRICK", label: "TIP & Trick"},
            {value: "NEWS", label: "News"},
            {value: "SECURITY_NOTICE", label: "Security Notice"},
        ],
        FORMAT:[
            {value: "ARTICLE", label: "Article"},
            {value: "PODCAST", label: "Podcast"},
            {value: "VIDEO", label: "Video"},
            {value: "DISCUSSION", label: "Discussion"},
        ],
        LANGUAGE:[
            {value: "en", label: "English"},
            {value: "es", label: "Spanish"},
            {value: "it", label: "Italian"},
            {value: "de", label: "German"},
            {value: "nl", label: "Dutch"},
        ],
        STATE:[
            {value: "PUBLISHED", label: "Published"},
            {value: "BROKEN", label: "Broken"},
            {value: "SUSPENDED", label: "Suspended"},
            {value: "REMOVED", label: "Removed"},
        ]
    },
    BOOLEAN:[
        { value: false, label: "NO" },
        { value: true, label: "YES" },
    ]
}