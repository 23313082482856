import { failedToast } from "../../../helpers/ToastHelper";

export function controlMandatoryFields(state,mandatory, callback){
    const field = Object.keys(mandatory);
    for (const mandatoryField of field) {
        if (mandatory[mandatoryField] && !state[mandatoryField]) {
            callback();
            failedToast(`Please fill the value of ${mandatoryField}`);
            return false
        }
    }
    return true
}

export function controlMaxLengthFields(state, maxLengths, callback){
    const limitedFields = Object.keys(maxLengths);
    for (const field of limitedFields) {
        if (state[field] && state[field].length > maxLengths[field]) {
            callback()
            failedToast(`Please check length of ${field}`);
            return false;
        }
    }
    return true;
}

export function controlRegexFields(state, regex, callback){
    const limitedFields = Object.keys(regex);
    for (const field of limitedFields) {
        if (state[field] && state[field] !== '' && !regex[field].test(state[field])) {
            callback()
            failedToast(`Please check content of ${field}`);
            return false;
        }
    }
    return true;
}
