/**
 * Created by marco on 28/03/2020.
 *
 * (C) Copyright Fing
 */

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { isMissingProfileValues } from '../helpers/AccountHelper';
import {FING_PLAN_FREE, accountTypePrettyName} from "../helpers/BillingHelper";

export default class AccountPersonalInfoSummary extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { userProfile } = this.props;

        const Row = ({title, value}) => <div className="row align-items-baseline">
            <div className="col-lg-4">
                <label className="input-label mt-0">{title}</label>
            </div>
            <div className="col-lg-8">
                {value}
            </div>
        </div>

        const AccountTypeBadge = ({accountType}) => {
            if (!accountType) return "";
            switch (accountType) {
                case FING_PLAN_FREE :
                    return <span className="badge badge-secondary">{accountTypePrettyName(accountType)}</span>
                default :
                    return <span className="badge badge-charcoal">{accountTypePrettyName(accountType)}</span>
            }
        };

        return <div className="card card-bordered">
            <div className="card-body">
                {userProfile.firstName && userProfile.lastName ?
                    <>
                        <Row title="First name" value={userProfile.firstName}/>
                        <Row title="Last name" value={userProfile.lastName}/>
                    </> :
                    <>
                        <Row title="Name" value={userProfile.name}/>
                    </>
                }

                <Row title="Email" value={userProfile.email}/>
                <Row title="Account Type" value={<AccountTypeBadge accountType={userProfile.accountType} />}/>

                <div className="row mt-3">
                    <div className="col-sm-12">
                        <Link className="btn btn-xs btn-primary" to="/account/profile/edit">Edit Profile</Link>
                        <Link className="btn btn-xs btn-outline-secondary ml-3" to="/account/password/edit">Change Password</Link>
                    </div>
                </div>
            </div>
            {isMissingProfileValues(userProfile) ? <div className="card-footer bg-soft-warning">
                <div className="row">
                    <div className="col-12 text-center">
                        <span className="font-size-1"><Link to="/account/profile/edit" className="link">Complete your profile</Link> to let people know about you</span>
                    </div>
                </div>
            </div> : ""}

        </div>;
    }

}
