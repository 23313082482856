import { getNoJson } from "../../../services/APIService";


export async function checkAllPermissions(url,types){
    const result = {};
    for(const t of types){
        await getNoJson(`${url}${t}`)
            .then((val) => result[t] = val.status === 200)
            .catch((err) => result[t] = !(err && (err.code === 403 || err.code === 401 || err.code === 500)));
    }
    return result
} 

export const PERMISSION = {
    AGENT: {
        types:['view','edit','deactivate','watch','assign'],
        url: "/rest/admin/agent/check/"
    },
    CONNECTION: {
        types:['view','edit','delete'],
        url: "/rest/admin/connection/check/"
    },
    OFFER: {
        types:['view','edit'],
        url: "/rest/admin/offer/check/"
    },
    POST: {
        types:['view','edit'],
        url: "/rest/admin/content/check/"
    },
    USER: {
        types:['view','edit','delete'],
        url: "/rest/admin/user/check/"
    },
    VALIDATION: {
        types:['view','edit'],
        url: "/rest/admin/network/check/"
    },
    IN_APP: {
        types:['view','edit'],
        url: "/rest/admin/notification/check/"
    },
}