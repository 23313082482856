/**
 * Created by marco on 17/11/2020.
 *
 * (C) Copyright Fing
 */

import React, {Component} from 'react';
import {
    currencySymbol,
    productFeatureList,
    productFeatureListTitle,
    productPrettyDescription,
    productPrettyName
} from "../helpers/BillingHelper";

export default class PricingTier extends Component {

    render() {
        const {plan, subscription} = this.props;
        if (subscription)
            return this.renderSubscriptionSummary(subscription);
        if (plan)
            return this.renderPlanSummary(plan);
        else
            return '';
    }

    renderSubscriptionSummary(subscription) {
        return (
            <div className="text-dark">
                <div>
                    {this.renderProduct(subscription)}
                    {this.renderProductDescription(subscription)}
                    {this.renderSubscriptionCost(subscription)}
                    {this.renderStatus(subscription)}
                    {this.renderFeatureListTitle(subscription)}
                    {this.renderFeatureList(subscription)}
                    {this.renderFullComparison(subscription)}
                </div>
            </div>
        );
    }

    renderPlanSummary(plan) {
        return (
            <div className="text-dark">
                <div>
                    {this.renderProduct(plan)}
                    {this.renderProductDescription(plan)}
                    {this.renderBigPrice(plan)}
                    {/*{this.renderAsMonthlyPrice(plan)}*/}
                    {this.renderDiscount(plan)}
                    {this.renderFeatureListTitle(plan)}
                    {this.renderFeatureList(plan)}
                    {this.renderFullComparison(plan)}
                </div>
            </div>
        );
    }

    renderProduct(plan) {
        return <div
            className="font-weight-bold h2 text-dark mb-0">{productPrettyName(plan.productId)}</div>;
    }

    renderProductDescription(plan) {
        return <div
            className="font-weight-bold h5 text-gray mb-4" style={{minHeight: "3rem"}}>{productPrettyDescription(plan.productId)}</div>;
    }

    renderFeatureListTitle(plan) {
        return <div
            className={"font-weight-bold h5 text-dark mt-4 pt-3 border-top " + (this.props.selected ? " border-primary" : "")}>{productFeatureListTitle(plan.productId)}</div>;
    }

    renderFeatureList(plan) {
        let features = productFeatureList(plan.productId);
        return <ul className="list-unstyled text-gray-dark mb-1">
            {features.map(feature => <li className="pb-1"><i className="fa fa-fw fa-check text-success mr-3" />{feature}</li>)}
        </ul>;
    }

    renderFullComparison(plan) {
        return <div className="mb-2">
                <span className="fa fa-fw "/>
               <a className="ml-3 link-underline" href={"https://www.fing.com/pricing#pricing-details"}
                  rel="noopener noreferrer" target="_blank" >See the full list of features</a>
            </div>
        ;
    }

    renderBigPrice(plan) {
        const symbol = currencySymbol(plan.currency);
        return <div className="h3 font-weight-bold text-dark d-flex">
            {plan.offerAmount || plan.projectedAmount ?
                <span>
                    <span>{symbol && <span>{symbol}</span>}{plan.offerAmount || plan.standardAmount}</span>
                    <del className="h4 text-gray mx-2">{symbol && <span>{symbol}</span>}{plan.projectedAmount || plan.standardAmount}</del>
                </span> :
                <span>{symbol && <span>{symbol}</span>}{plan.standardAmount}</span>
            }
            {/*{plan.projectedAmount && <span className="ml-auto small">Just {symbol && <span>{symbol}</span>}{plan.normalizedAmount} / month</span>}*/}
        </div>;
    }

    renderDiscount(plan) {
        const discountDurationText = plan.offerDuration + " " + plan.billingCycle.toLowerCase() + (plan.offerDuration > 1 ? "s" : "");
        let discountPct = plan.discountPct || 0;
        return <>
            {discountPct ?
                <span className="d-block d-flex">
                    <span className="badge badge-warning">
                        Save {discountPct}%
                    </span>
                    <span className="small ml-auto">{plan.offerDuration ? "For " + discountDurationText : ""}</span>
                </span> :
                <span className="d-block d-flex">
                    <span className="badge badge-soft-secondary">
                        I don't like savings 😟
                    </span>
                </span>
            }
        </>;
    }

    renderSubscriptionCost(subscription) {
        const symbol = currencySymbol(subscription.currency);
        return <div className="h3 font-weight-bold text-dark d-flex">
            {subscription.discountAmount ?
                <span>
                    <span>{symbol && <span>{symbol}</span>}{subscription.discountAmount || subscription.standardAmount}</span>
                    <del className="h4 text-gray mx-2">{symbol && <span>{symbol}</span>}{subscription.standardAmount}</del>
                </span> :
                <span>{symbol && <span>{symbol}</span>}{subscription.standardAmount}</span>
            }
        </div>;
    }

    renderStatus(subscription) {
        if (['TRIALING', 'ACTIVE'].includes(subscription.status) && !subscription.canceledAt) { // Cancellation never requested
            return <span className="d-block d-flex">
                    <span className="badge badge-success">Active</span>
                    <span className="small ml-auto">{subscription.currentPeriodEnd && `Renews on ${new Date(subscription.currentPeriodEnd * 1000).toLocaleDateString()}`}</span>
                </span>;
        }

        if (['TRIALING', 'ACTIVE'].includes(subscription.status) && subscription.canceledAt) { // Cancellation requested
            return <span className="d-block d-flex">
                    <span className="badge badge-danger">Cancelled</span>
                    <span
                        className="small ml-auto">{
                            subscription.cancelAt ? `Expires on ${new Date(subscription.cancelAt * 1000).toLocaleDateString()}` :
                            subscription.cancelAtPeriodEnd ? `Expires on ${new Date(subscription.currentPeriodEnd * 1000).toLocaleDateString()}` :
                            ""}</span>
                </span>;
        }

        if (['INCOMPLETE', 'PAST_DUE'].includes(subscription.status)) {
            return <span className="d-block d-flex">
                    <span className="badge badge-danger">Incomplete</span>
                    <span className="small ml-auto">Payment not finalized</span>
                </span>;
        }

        return "";
    }

}