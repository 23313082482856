import React from "react";

import AppNavbar from "../components/AppNavbar";
import AppFooter from "../components/AppFooter";

import AccountPageTitle from "./AccountPageTitle";
import AccountSignedOutEmptyState from "./AccountSignedOutEmptyState";

import { isUserSignedIn, UserContext } from "../UserContext";

import DataTable from "../components/table/DataTable";

import { remove, handleErrorFetch } from "../services/APIService";

import { formatLongAbsoluteDate } from "../helpers/DateHelper";

import { ToastContainer } from "react-toastify";
import { successToast, failedToast } from "../helpers/ToastHelper";
import {withRouter} from "react-router-dom";
import IftttImg from "../assets/svg/other/ifttt_48.svg";
import ZapierImg from "../assets/svg/other/zapier_48.svg";
import GoogleAssistantImg from "../assets/svg/other/google_assistant_48.svg";

class AccountApps extends React.Component {

    constructor(props) {
        super(props);

        this.tableRef = React.createRef();

        this.state = {
            showDeleteModal: false,
            selectedRow: null
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "Fing - Connected Apps";
    }

    // --------------------------------------------------------------------------------
    // MAIN RENDERING
    // --------------------------------------------------------------------------------


    render() {
        return (
            <div>
                <AppNavbar mode='account' />

                <main id="content" role="main">

                    <UserContext.Consumer>
                        {userContext => this.renderMainContent(userContext)}
                    </UserContext.Consumer>

                </main>

                <AppFooter />
            </div>
        );
    }


    renderMainContent(userContext) {
        if (isUserSignedIn(userContext)) {
            return (
                <div>
                    <div className="container space-2">
                        <AccountPageTitle
                            title="Your apps"
                            subtitle="Review the Apps that have access to your data" />
                        {this.renderTable()}
                    </div>
                    {this.renderDeleteModal(userContext)}
                    <ToastContainer />
                </div>
            );
        } else {
            return this.renderEmptyState();
        }
    }

    renderEmptyState() {
        return <AccountSignedOutEmptyState />;
    }

    renderTable() {

        const headers = [
            { name: "clientImageUrl", displayField: "", sortable: false },
            { name: "clientName", displayField: "Name", sortable: true },
            { name: "activeSessionCount", displayField: "Active sessions", sortable: true },
            { name: "sessionCount", displayField: "Sessions", sortable: true },
            { name: "authorizationDate", displayField: "First activity", sortable: true },
            { name: "actions", displayField: "", sortable: false }
        ]

        // Temporary fix until we update the core libs with latest image URL
        const imageForApp = (app) => {
            switch (app.clientName) {
                case "IFTTT":
                    return IftttImg;
                case "Zapier":
                    return ZapierImg;
                case "Actions on Google":
                    return GoogleAssistantImg;
                default:
                    return app.clientImageUrl;
            }
        }

        const onRenderCell = (header, row) => {

            switch (header.name) {
                case "clientImageUrl":
                    return <td>
                        <div className="avatar avatar-xs">
                            <img className="img-fluid" src={imageForApp(row)} alt="app icon" />
                        </div>
                    </td>;
                case "authorizationDate":
                    // return <td>{intl.get('generic_date', { date: new Date(row[header.name]) })}</td>
                    return <td>{formatLongAbsoluteDate(new Date(row[header.name]).getTime())}</td>

                case "clientName":
                    return <td className="min-w-33rem">{row[header.name] || ""}</td>;

                case "actions":
                    return <td className="d-flex justify-content-end">
                        <button className="btn btn-danger btn-xs"
                            onClick={() => this.setState({ showDeleteModal: true, selectedRow: row })}>Revoke
                        </button>
                    </td>
                default:
                    return <td>
                        {row[header.name] || row[header.name] === 0 ? row[header.name] : ""}
                    </td>;
            }
        }

        return <DataTable
            ref={this.tableRef}
            search={false}
            pagination={false}
            resources="/rest/users/apps"
            name="Apps"
            headers={headers}
            onRenderCell={onRenderCell}
        />

    }

    renderDeleteModal(userContext) {

        const { showDeleteModal, selectedRow } = this.state;

        const handleClose = () => this.setState({ showDeleteModal: false, selectedRow: null });

        const handleSubmit = () => {

            const { selectedRow } = this.state;

            if (selectedRow && selectedRow.__rowId) {

                const url = "/rest/users/apps/" + selectedRow.__rowId['clientId'];

                return remove(url)
                    .then(() => {
                        successToast("App un-linked.");
                        this.tableRef.current.parentUpdateData({ total: -1 });
                    })
                    .catch((err) => {

                        failedToast("Something went wrong.");

                        if (err && err.code === 401) {
                            let newContext = handleErrorFetch(userContext);
                            userContext.userContextUpdater(newContext);
                        }
                        console.log('Error in unlink app: ', err)
                    })
                    .finally(() => this.setState({ showDeleteModal: false, selectedRow: null }))

            }

        }

        if (!selectedRow) return "";

        return (
            <div>
                <div className={`modal-backdrop fade ${showDeleteModal ? "show d-block" : "d-none"}`}></div>
                <div className={`modal fade ${showDeleteModal ? "show d-block" : "d-none"}`} role="dialog" tabIndex="-1"
                    aria-labelledby="Delete_Modal_label" aria-hidden={!showDeleteModal} aria-modal={showDeleteModal} >
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div id="Delete_Modal_label" className="modal-header bg-danger p-3">
                                <h4 className="modal-title text-white">Dismiss {selectedRow["clientName"]} ?</h4>
                                <button type="button" className="close text-white" onClick={handleClose} data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span><span className="sr-only">Close</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container">
                                    <p>
                                        The App <span className="text-primary">{selectedRow["clientName"]}</span> will be un-linked from your Fing account.
                                        You will need to re-enter your email and password on that device to connect it again.
                                    </p>
                                    <p>
                                        Do you want to proceed?
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-danger btn-xs mr-2"
                                        onClick={handleSubmit}>Unlink
                                    </button>
                                    <button className="btn btn-outline-secondary btn-xs"
                                        onClick={handleClose}>Dismiss
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

}

export default withRouter(AccountApps)