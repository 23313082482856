import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import intl from 'react-intl-universal';

import {scoreDecimals, formatIspTrend, formatIspTrendTextKey} from "../../helpers/IspScoreHelper";

import OutageMiniCard from "../outage/OutageMiniCard";
import DropChart from "../outage/DropChart";
import IspRankSummary from "./IspRankSummary";

import Medal from '../../assets/svg/illustrations/medal.svg';
import MostLoved from '../../assets/svg/illustrations/most-loved.svg';
import Growth from '../../assets/svg/illustrations/growth.svg';

class IspMainView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wikipediaText: ''
        };
    }

    renderAboutSection() {
        if (!this.props.isp.wikipediaText)
            return;

        let wikiText = this.props.isp.wikipediaText;
        // if (wikiText.length>128) wikiText = wikiText.substring(0,128)+"…";

        return (
            <section className="border-bottom pb-5 mb-5">
                <div className="mb-3">
                    <h4 className="h5 mb-0">{intl.get('isp_main_about', {isp: this.props.isp.name})}</h4>
                </div>

                <div>
                    <p>
                        {wikiText}
                    </p>
                    <p className="text-right">
                        <a className="link-underline" href={this.props.isp.wikipediaPageLink}
                           rel="noopener noreferrer"
                           target="_blank">{intl.get('isp_main_wikipedia')}</a>
                    </p>
                </div>
            </section>
        );
    }

    // --------------------------------------------------------------------------------
    // Render chart
    // --------------------------------------------------------------------------------

    renderDropChart(points) {
        if (!points || points.length === 0) {
            return <section/>
        }

        let totProbes = points[points.length-1].total;
        if (totProbes === 0) {
            return <section/>
        }

        if (points.filter(p => (p.value > 0)).length === 0) {
            return <section/>
        }

        let geoArea = this.props.isp.countryRegion?this.props.isp.countryRegion:this.props.isp.countryName;

        return (
            <section className="border-bottom pb-5 mb-5">
                <div className="mb-3">
                    <h4 className="h5 mb-0">{intl.get('drop_rate_title')}</h4>
                    <p>{intl.get('drop_rate_samples_isp', {probeCount:totProbes, geoArea: geoArea, isp:this.props.isp.name})}</p>
                </div>
                <div className="col-lg-12 mb-2">
                    <DropChart points={points}/>
                </div>
            </section>
        );
    }

    // --------------------------------------------------------------------------------
    // Render Outage carousel
    // --------------------------------------------------------------------------------

    renderRecentOutageSection() {
        if (!this.props.recentOutages || this.props.recentOutages.length === 0) {
            return <section/>
        }

        let ongoingOutages=0;
        for (let i = 0; i < this.props.recentOutages.length; i++) {
            const outage = this.props.recentOutages[i];
            if (!outage.active) continue;
            ++ongoingOutages;
        }

        let title;
        if (ongoingOutages>0) {
            title=intl.get('isp_main_outages')+": "+ongoingOutages+" "+intl.get('isp_main_outages_ongoing');
        } else {
            title=intl.get('isp_main_outages_recent');
        }

        let titleClass = ongoingOutages>0 ? "h5 mb-0 text-danger" : "h5 mb-0";

        return (
            <section className="border-bottom pb-5 mb-5">
                <div className="mb-3">
                    <h4 className={titleClass}>{title}</h4>
                </div>

                <div className="mb-6">
                    {this.renderRecentOutageCards()}
                </div>
            </section>
        );
    }

    renderRecentOutageCards() {
        return this.props.recentOutages.map((outage,i) => this.renderOutageCard(outage,i));
    }

    renderOutageCard(outage, i) {
        return (
            <div className="card card-frame border-1 mb-3">
                <Link to={'/outage/' + outage.reportId}>
                    {this.renderOutageCardHeader(outage, i === 0)}
                    <OutageMiniCard
                        outage={outage} curCountry={this.props.isp.countryName} curRegion={this.props.isp.countryRegion}
                    />
                </Link>
            </div>
        );
    }

    renderOutageCardHeader(outage, first) {

        if (outage.active) {
            return (
                    <div className="card-header text-white bg-danger">
                       {intl.getHTML('isp_main_outage_header_ongoing')}
                    </div>
                    );
        } else {
            if (first && outage.recent) {
                return (
                        <div className="card-header text-white bg-warning">
                           {intl.getHTML('isp_main_outage_header_recent')}
                        </div>
                        );
            }
            return '';
        }
    }

    // --------------------------------------------------------------------------------
    // Render badges
    // --------------------------------------------------------------------------------

    renderBadge(badgeId) {
        switch (badgeId) {
            case 'top-global-5-percentile':
                return (
                    <div className="media">
                        <figure id="badge-top-global-5-percentile" className="svg-preloader ie-height-56 w-100 max-w-8rem mr-4">
                            <img className="img-fluid" src={Medal} alt="SVG"
                                 data-parent="#badge-top-global-5-percentile" />
                        </figure>
                        <div className="media-body">
                            <h3 className="h6 mb-1">{intl.get('isp_badge_top_5')}</h3>
                            <p>{intl.get('isp_badge_top_5_sub')}</p>
                        </div>
                    </div>
                );
            case 'top-global-10-percentile':
                return (
                    <div className="media">
                        <figure id="badge-top-global-10-percentile" className="svg-preloader ie-height-56 w-100 max-w-8rem mr-4">
                            <img className="img-fluid" src={Medal} alt="SVG"
                                 data-parent="#badge-top-global-10-percentile" />
                        </figure>
                        <div className="media-body">
                            <h3 className="h6 mb-1">{intl.get('isp_badge_top_10')}</h3>
                            <p>{intl.get('isp_badge_top_10_sub')}</p>
                        </div>
                    </div>
                );
            case 'top-loved-in-country':
                return (
                    <div className="media">
                        <figure id="badge-top-loved-in-country" className="svg-preloader ie-height-56 w-100 max-w-8rem mr-4">
                            <img className="img-fluid" src={MostLoved} alt="SVG"
                                 data-parent="#badge-top-loved-in-country" />
                        </figure>
                        <div className="media-body">
                            <h3 className="h6 mb-1">{intl.get('isp_badge_love')}</h3>
                            <p>{intl.get('isp_badge_love_sub')}</p>
                        </div>
                    </div>
                );
            case 'top-uptime':
                return (
                    <div className="media">
                        <figure id="badge-top-uptime" className="svg-preloader ie-height-56 w-100 max-w-8rem mr-4">
                            <img className="img-fluid" src={Growth} alt="SVG"
                                 data-parent="#badge-top-uptime" />
                        </figure>
                        <div className="media-body">
                            <h3 className="h6 mb-1">{intl.get('isp_badge_stability')}</h3>
                            <p>{intl.get('isp_badge_stability_sub')}</p>
                        </div>
                    </div>
                );
            default:
                return '';
        }
    }

    renderBadgeSection() {
        if (!this.props.isp.badges || this.props.isp.badges.length === 0)
            return '';


        return (
            <div className="border-bottom pb-5 mb-5">
                <div className="mb-4">
                    <h2 className="h5">{intl.get('isp_badge_achievements')}</h2>
                </div>

                <div className="row">
                    {this.props.isp.badges.map((badgeId,i) => {
                        return [
                            <div className="col-sm-6 mb-5">{this.renderBadge(badgeId)}</div>,
                            i % 2 === 1 ? <div className="w-100"/> : ''
                        ];
                    })}
                </div>
            </div>
        );
    }

    renderPerformanceSection() {
        if (!this.props.isp.ispScore ||
            !this.props.isp.ispScore.averageDownloadSpeed ||
            !this.props.isp.ispScore.averageUploadSpeed)
            return '';

        let score = scoreDecimals(this.props.isp.ispScore.scoreValue / (100.0 / 5), 1);

        const download = this.props.isp.ispScore.averageDownloadSpeed.toFixed(1);
        const upload = this.props.isp.ispScore.averageUploadSpeed.toFixed(1);

        return (
            <div className="border-bottom pb-5 mb-5">
                <div className="mb-4">
                    <h2 className="h5 mb-0">{intl.get('isp_main_speed_performance')}</h2>
                    <p><IspRankSummary className="text-normal" isp={this.props.isp}
                                score={this.props.isp.ispScore.scoreValue}
                                trend={this.props.isp.ispScore.scoreTrend} /></p>
                </div>

                <div className="mb-4">
                    <div className="media align-items-center mb-3">
                        <div className="mr-4">
                            <div className="font-size-5 font-weight-semi-bold" style={{lineHeight: "1"}}>{score}
                            <span className={"font-size-4 fas "+formatIspTrend(this.props.isp.ispScore.scoreTrend)} />
                            </div>
                            <div>{intl.get('isp_main_out_of')} <span className="far fa-star fa-fw text-normal small"/></div>
                            <div className="small text-muted">{intl.get(formatIspTrendTextKey(this.props.isp.ispScore.scoreTrend), {value: scoreDecimals(this.props.isp.ispScore.scoreTrend*100, 1)})}</div>
                        </div>

                        <div className="media mr-4 column-divider column-divider--none-md py-4 pl-4">
                            <span className="btn btn-icon btn-soft-success rounded-circle mr-3">
                              <span className="fas fa-download btn-icon__inner"/>
                            </span>
                            <div className="media-body">
                                <h4 className="h5 font-weight-normal mb-0">{download} <span className="text-secondary">Mbps</span></h4>
                                <span className="d-block text-secondary font-size-1">{intl.get('generic_download_speed')}</span>
                            </div>
                        </div>

                        <div className="media">
                            <span className="btn btn-icon btn-soft-primary rounded-circle mr-3">
                              <span className="fas fa-upload btn-icon__inner"/>
                            </span>
                            <div className="media-body">
                                <h4 className="h5 font-weight-normal mb-0">{upload} <span className="text-secondary">Mbps</span></h4>
                                <span className="d-block text-secondary font-size-1">{intl.get('generic_upload_speed')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSentimentSection() {
        if (!this.props.isp.ispSentiment ||
            !this.props.isp.ispSentiment.sentimentValue ||
            !this.props.isp.ispSentiment.sentimentDistribution)
            return '';

        let score = scoreDecimals(this.props.isp.ispSentiment.sentimentValue / (100.0 / 5), 1);
        const distro = this.props.isp.ispSentiment.sentimentDistribution;
        // const total = distro.reduce((a,b) => a + b, 0);
        const total = Math.max(...distro);

        return (
            <div className="border-bottom pb-5 mb-5">
                <div className="mb-4">
                    <h2 className="h5 mb-0">{intl.get('isp_main_rating_reviews')}</h2>
                    <p><IspRankSummary className="text-normal" isp={this.props.isp}
                                score={this.props.isp.ispSentiment.sentimentValue}
                                trend={this.props.isp.ispSentiment.sentimentTrend} /></p>
                </div>

                <div className="mb-4">
                    <div className="media align-items-center mb-3">
                        <div className="mr-4">
                            <div className="font-size-5 font-weight-semi-bold" style={{lineHeight: "1"}}>{score}
                            <span className={"font-size-4 fas "+formatIspTrend(this.props.isp.ispSentiment.sentimentTrend)} />
                            </div>
                            <div>{intl.get('isp_main_out_of')} <span className="far fa-heart fa-fw text-normal small"/></div>
                            <div className="small text-muted">{intl.get(formatIspTrendTextKey(this.props.isp.ispSentiment.sentimentTrend), {value: scoreDecimals(this.props.isp.ispSentiment.sentimentTrend*100, 1)})}</div>
                        </div>
                        <div className="media-body column-divider column-divider--none-md pl-4">
                            <div>
                                {this.renderProgressBar(5, distro[4], total)}
                                {this.renderProgressBar(4, distro[3], total)}
                                {this.renderProgressBar(3, distro[2], total)}
                                {this.renderProgressBar(2, distro[1], total)}
                                {this.renderProgressBar(1, distro[0], total)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderProgressBar(starCount, value, max) {
        const pct = max === 0 ? 0 : (value / max * 100);

        return (
            <div className="mb-2">
                <div className="text-warning small center-it-vertically float-left mr-2">
                    {new Array(5-starCount).fill('').map(o => <span className="fas fa-heart-empty fa-fw"/>)}
                    {new Array(starCount).fill('').map(o => <span className="fas fa-heart fa-fw"/>)}
                </div>
                <div className="text-secondary small center-it-vertically float-right ml-4">
                    {value}
                </div>
                <div className="progress">
                    <div className="progress-bar bg-primary"
                         role="progressbar"
                         style={{width: pct + "%"}}
                         aria-valuenow={pct}
                         aria-valuemin="0"
                         aria-valuemax="100"/>
                </div>
            </div>
        );
    }

    renderCommentSection() {
        return (
            <section>
                <div className="d-sm-flex justify-content-sm-between align-items-sm-center border-bottom pb-5 mb-5">
                    <div className="d-flex align-items-center mb-2 mb-sm-0">
                        <h4 className="h5 mb-0">Reviews</h4>

                        <div className="text-warning ml-3">
                            <small className="fas fa-fw fa-heart"/>
                            <span className="font-weight-semi-bold align-middle">4.7</span>
                            <span className="text-muted align-middle">(39 reviews)</span>
                        </div>
                    </div>


                    <select className="dropdown-select"
                            data-width="fit"
                            data-style="btn-soft-secondary btn-xs"
                            defaultValue="showCategoryByFilterSelect1">
                        <option value="showCategoryByFilterSelect1">Newest first</option>
                        <option value="showCategoryByFilterSelect2">Highest rated</option>
                        <option value="showCategoryByFilterSelect3">Lowest rated</option>
                    </select>
                </div>

                <div className="border-bottom pb-5 mb-5">
                    <div className="mb-4">
                        <p>Most people genuinely care about the product and what they are working on. Many projects are
                            bottom up instead of top down, it's a great place to learn and grow.</p>
                    </div>

                    <div className="media">
                        <div className="avatar mr-3">
                            <img className="img-fluid rounded-circle" src="https://graph.facebook.com/10210125796441118/picture?type=large"
                                 alt="Fake Description"/>
                        </div>
                        <div className="media-body">
                            <h4 className="h6 mb-1">Chulk Solo</h4>
                            <div className="small mb-3">
                    <span className="text-warning">
                      <span className="fas fa-fw fa-heart"/>
                      <span className="fas fa-fw fa-heart"/>
                      <span className="fas fa-fw fa-heart"/>
                      <span className="fas fa-fw fa-heart"/>
                      <span className="fas fa-fw fa-heart"/>
                    </span>
                                <span className="font-weight-semi-bold ml-2">5.0</span>
                                <span className="text-muted">3 weeks ago</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border-bottom pb-5 mb-5">
                    <div className="mb-4">
                        <p>There is constant discussion among all employees about how we ‘do that right thing’. That
                            looks different for everyone, but I appreciate that there is always an open dialogue around
                            the decisions we make across the whole company.</p>
                    </div>

                    <div className="media">
                        <div className="avatar mr-3">
                            <img className="img-fluid rounded-circle" src="https://lh5.googleusercontent.com/-zJSQ2fmBpcc/AAAAAAAAAAI/AAAAAAAABSw/v6G0p7Fozjo/photo.jpg?sz=200"
                                 alt="Fake Description"/>
                        </div>
                        <div className="media-body">
                            <h4 className="h6 mb-1">Citizen Kane</h4>
                            <div className="small mb-3">
                    <span className="text-warning">
                      <span className="fas fa-fw fa-heart"/>
                      <span className="fas fa-fw fa-heart"/>
                      <span className="fas fa-fw fa-heart"/>
                      <span className="far fa-fw fa-heart"/>
                      <span className="far fa-fw fa-heart"/>
                    </span>
                                <span className="font-weight-semi-bold ml-2">5.0</span>
                                <span className="text-muted">2 months ago</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border-bottom pb-5 mb-5">
                    <div className="mb-3">
                        <p>Everyone is very friendly. I feel welcome and informed throughout a working day.</p>
                    </div>

                    <div className="media">
                        <div className="avatar mr-3">
                            <img className="img-fluid rounded-circle" src="https://lh5.googleusercontent.com/-fgTJ5cIZ_6M/AAAAAAAAAAI/AAAAAAAAABI/syYgyro7MAE/photo.jpg?sz=200"
                                 alt="Fake Description"/>
                        </div>
                        <div className="media-body">
                            <h4 className="h6 mb-1">Brian Walters</h4>
                            <div className="small mb-3">
                    <span className="text-warning">
                      <span className="fas fa-fw fa-heart"/>
                      <span className="fas fa-fw fa-heart"/>
                      <span className="fas fa-fw fa-heart"/>
                      <span className="fas fa-fw fa-heart"/>
                      <span className="far fa-fw fa-heart"/>
                    </span>
                                <span className="font-weight-semi-bold ml-2">5.0</span>
                                <span className="text-muted">5 months ago</span>
                            </div>
                        </div>
                    </div>
                </div>

                <button type="button" className="btn btn-block btn-soft-primary transition-3d-hover">Load More</button>

            </section>
        );
    }

    // --------------------------------------------------------------------------------
    // Main Render function
    // --------------------------------------------------------------------------------


    render() {
        return (
            <div className="pl-lg-4">
                {this.renderRecentOutageSection()}

                {this.renderDropChart(this.props.dropKpiChart)}

                {this.renderBadgeSection()}

                {this.renderPerformanceSection()}

                {this.renderSentimentSection()}

                {this.renderAboutSection()}

            </div>
        );
    }
}

export default IspMainView;