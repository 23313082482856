import React from "react";

import {get, put, remove} from "../../services/APIService";
import {ToastContainer} from "react-toastify";
import {failedToast, successToast} from "../../helpers/ToastHelper";
import ModalDialog, {hideDialogById, ModalDialogBody, ModalDialogFooter, showDialogById} from "../dialog/ModalDialog";

import {Link} from "react-router-dom";
import {
    convertDateToState,
    convertEnumToState,
    convertStateToDate,
    convertStateToEnum,
    convertStringToArray,
    convertStateArrayToString
} from './helper/ConversionHelper';
import { controlMandatoryFields, controlMaxLengthFields } from "./helper/ControlHelper";
import { handleApiError } from "./helper/ErrorHelper";
import { ENUMS } from "./helper/EnumHelper";
import { TITLES } from './helper/TitlesHelper';
import { MANDATORY } from './helper/MandatoryHelper';
import { DISABLED } from './helper/DisableHelper';
import { MAX_LENGTH } from './helper/MaxLengthHelper';
import { checkAllPermissions, PERMISSION } from "./helper/PermissionHelper";
import { REST_URL_OFFER } from "./helper/URLHelper";
import TextField from "./fields/TextField";
import EnumField from "./fields/EnumField";
import DateField from './fields/DateField';

import MainContent from "./extra/MainContent";
import EventComponent from "./extra/EventComponent";

const maxLengths = MAX_LENGTH.OFFER;

const titles = TITLES.OFFER;

const mandatory = MANDATORY.OFFER;

const disabled = DISABLED.OFFER;

const multiple = {}


const DELETE_DISCOUNT_OFFER_DIALOG = "DELETE_DISCOUNT_OFFER_DIALOG";

const permissionTypes = PERMISSION.OFFER.types;
const permissionUrl = PERMISSION.OFFER.url;

export default class AdminDiscountOfferEditPage extends EventComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            attemptedSave: false,
            forbidden: false,
            loading: true,
            purchaseRules: [''],
            allowedPlans: [
                { label: "Premium 1M", id: "premium_1mo", value: false},
                { label: "Premium 1Y", id: "premium_1yr", value: false},
                { label: "Starter 1M", id: "starter_1mo", value: false},
                { label: "Starter 1Y", id: "starter_1yr" , value: false}
            ]
        };
    }

    componentDidMount() {
        checkAllPermissions(permissionUrl,permissionTypes).then(result=> {
            const state = this.state;
            for(const key in result){
                state['permission_'+key] = result[key];
            }
            this.setState({...state},this.initWithDiscountOfferId())
        }).catch((err) => {
            this.props.history.replace({ pathname: `/`});
            handleApiError(err, this.context, () => this.setState({forbidden: true}));
        });
        
        
    }

    initWithEmptyReactState() {
        const d = new Date();
        const newState = this.state;
        newState.description = '';
        newState.discountId = '';
        newState.promoText = '';
        newState.allowedCountries = '';
        newState.startDate = d;
        newState.endDate = d;
        newState.discountPlatform = '';
        newState.enabled = '';
        newState.purchaseRules = [''];
        newState.allowedPlans = [
            { label: "Premium 1M", id: "premium_1mo", value: false},
            { label: "Premium 1Y", id: "premium_1yr", value: false},
            { label: "Starter 1M", id: "starter_1mo", value: false},
            { label: "Starter 1Y", id: "starter_1yr" , value: false}
        ]
        this.setState(newState);
    }

    initWithDiscountOfferId() {
        if(this.state.permission_view !== null && this.state.permission_view === false) 
            this.props.history.replace({ pathname: `/`});
        const discountOfferId = this.props.match.params.discountOfferId;
        if (discountOfferId && discountOfferId !== "new") {
            get(`${REST_URL_OFFER}/${discountOfferId}`)
                .then((offer) => {
                    if (offer) {
                        this.convertFullDiscountOfferToState(offer);
                    }
                })
                .catch((err) => {
                    failedToast("Unable to read offer");
                    handleApiError(err, this.context, () => this.setState({forbidden: true}));
                })
                .finally(() => this.setState({loading: false}));
        } else {
            this.initWithEmptyReactState();
        }
        
    }

    convertFullDiscountOfferToState(response) {
        const newState = this.state;
        if (response) {
            const offer = response;
            newState.description = offer.description;
            newState.discountId = offer.discountId;
            newState.promoText = offer.promoText;
            newState.allowedCountries = offer.allowedCountries;
            if (offer.startDate) newState.startDate = convertDateToState(new Date(offer.startDate));
            if (offer.endDate) newState.endDate = convertDateToState(new Date(offer.endDate));
            if (offer.discountPlatform) newState.discountPlatform = convertEnumToState(ENUMS.DISCOUNT_OFFER.PLATFORM, offer.discountPlatform);
            if (offer.enabled) newState.enabled = convertEnumToState(ENUMS.BOOLEAN, offer.enabled);
            if (offer.purchaseRules) newState.purchaseRules = convertStringToArray(offer.purchaseRules, ',');
            newState.allowedPlans = [
                { label: "Premium 1M", id: "premium_1mo", value: offer.allowedPlansList.includes("premium_1mo")},
                { label: "Premium 1Y", id: "premium_1yr", value: offer.allowedPlansList.includes("premium_1yr") },
                { label: "Starter 1M", id: "starter_1mo", value: offer.allowedPlansList.includes("starter_1mo")},
                { label: "Starter 1Y", id: "starter_1yr" , value: offer.allowedPlansList.includes("starter_1yr")}
            ]
        }
        this.setState(newState);
    }


    // --------------------------------------------------------------------------------
    // MAIN RENDERING
    // --------------------------------------------------------------------------------

    render() {
        const onSave = ()=>this.saveDiscountOffer();
        const onDelete = () => showDialogById(DELETE_DISCOUNT_OFFER_DIALOG);
        return (
            <MainContent history={this.props.history}>
                <div className="container-fluid space-1 px-4">
                    <div className="d-flex border-bottom mb-6">
                        <div>
                            <h2 className="d-inline-block">Edit Discount Offer</h2>
                            <span className="text-secondary mx-2">/</span>
                            <Link to="/admin/offers">Back to Discount Offers</Link>
                        </div>
                        <div className="ml-auto">
                            {this.state.permission_edit && this.props.match.params.discountOfferId !== "new" && 
                                <button className="btn btn-sm btn-danger mr-2" onClick={onDelete}>Delete</button>}
                            {this.state.permission_edit && <button className="btn btn-sm btn-primary" onClick={onSave}>Save</button>}
                        </div>
                    </div>
                    <ToastContainer/>
                    {this.renderForm()}
                    {this.renderDeleteDiscountOfferDialog()}
                </div>
            </MainContent>
            
        );
    }

    convertAllowedPlans(allowedPlans){
        const plans = allowedPlans.map(row => {
            if(row.value){
                return row.id;
            }
            return '';
        }).filter(val=>val !== '').join(',')

        return plans !== '' ? plans : null;
    }

    saveDiscountOffer() {

        // === VALIDATE ===
        if(!controlMandatoryFields(this.state,mandatory,this.setState({attemptedSave: true}))) 
            return
        if(!controlMaxLengthFields(this.state,maxLengths,this.setState({attemptedSave: true})))
            return

        
        // === SEND ===

        let body = {};

        const urlIdParam = this.props.match.params.discountOfferId || "new";

        body = {
            discountOfferId: urlIdParam,
            description: this.state.description,
            promoText: this.state.promoText,
            discountId: this.state.discountId,
            allowedCountries: this.state.allowedCountries,
            startDate: convertStateToDate(this.state.startDate),
            discountPlatform: convertStateToEnum(this.state.discountPlatform),
            endDate: convertStateToDate(this.state.endDate),
            enabled: convertStateToEnum(this.state.enabled),
            allowedPlans: this.convertAllowedPlans(this.state.allowedPlans),
            purchaseRules: convertStateArrayToString(this.state.purchaseRules, ',')
        }

        

        this.setState({loading: true, attemptedSave: false});
        put(`${REST_URL_OFFER}/${urlIdParam}`, body)
            .then((offer) => {
                if (offer) {
                    successToast("Offer saved");
                    this.convertFullDiscountOfferToState(offer);
                    if (urlIdParam) {
                        this.props.history.replace({ pathname: `/admin/offer/${urlIdParam}`})
                    }
                }
            })
            .catch((err) => {
                failedToast("Unable to save discount offer");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    deletePost() {
        const urlIdParam = this.props.match.params.discountOfferId;


        // === SEND ===

        this.setState({loading: true});
        remove(`${REST_URL_OFFER}/${urlIdParam}`) 
            .then((result) => {
                successToast("Discount offer removed");
                this.props.history.replace({pathname: `/admin/offers`});
            })
            .catch((err) => {
                failedToast("Unable to delete discount offer");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    // -------------------------------------------------------------------------------------------------

    renderForm() {
        
        
        return (
            <form>
                <div className="row">
                    <div className="col-6">
                        
                        {this.renderTextField("promoText")}
                        {this.renderTextField("description")}
                        {this.renderDateField("startDate")}
                        {this.renderDateField("endDate")}
                        {this.renderEnumField("enabled", ENUMS.BOOLEAN)}
                        {this.renderTextField("allowedCountries")}
                        <small>Example: US,IT,UK </small>
                            
                    </div>
                    <div className="col-6">
                        {this.renderEnumField("discountPlatform", ENUMS.DISCOUNT_OFFER.PLATFORM)}
                        {this.renderTextField("discountId")}
                        {this.renderMultiChecklist("allowedPlans")}
                        {this.renderPurchaseRulesField("purchaseRules")}
                            
                    </div>
                </div>
            </form>
        );
    }

    renderPurchaseRulesField(id){
        const rules = this.state[id];

        const onClick = (idx) => {
            if(idx === 0){
                rules.push('');
                this.setState({purchaseRules: rules});
            } else {
                const newRules = rules;
                newRules.splice(newRules.length - idx, 1);
                this.setState({purchaseRules: newRules});
            }
        }

        return React.Children.toArray(rules.map((val,idx) => 
            <div className="d-flex align-items-center justify-content-between">
                <TextField
                    id={id}
                    className={"w-80"}
                    value={rules[idx]}
                    attemptedSave={this.state.attemptedSave}
                    label={"Rule #"+(idx + 1)}
                    mandatory={mandatory[id] === true}
                    maxLength={maxLengths[id] || 0}
                    onChange={evt=>this.handleMultiInputFieldChange(evt,id, idx)}
                    disabled={disabled[id]}/>
                <button
                    type="button"
                    className={"btn btn-xs btn-soft-primary mt-3"} 
                    onClick={()=>onClick(idx)}>
                    {idx === 0 ? "Add" : "Delete"}
                </button>
            </div>
        ))
    }

    
    renderTextField(id) {
        return <TextField
            id={id}
            className="mb-3"
            value={this.state[id] || ''}
            attemptedSave={this.state.attemptedSave}
            label={titles[id]}
            mandatory={mandatory[id] === true}
            maxLength={maxLengths[id] || 0}
            onChange={this.handleInputFieldChange}
            disabled={disabled[id]}/>
    }

    renderDateField(id) {
        return <DateField 
            id={id} 
            value={this.state[id]} 
            label={titles[id]} 
            onChange={this.handleInputFieldChange}
            disabled={disabled[id]}
            mandatory={mandatory[id] === true}
            attemptedSave={this.state.attemptedSave}/>
    }

    renderEnumField(id, enumValues) {

        return <EnumField
            id={id}
            mandatory={mandatory[id] === true}
            isMulti={multiple[id] || false}
            attemptedSave={this.state.attemptedSave}
            value={this.state[id]}
            label={titles[id]}
            enumValues={enumValues}
            onChange={this.handleSelectFieldChange}/>
    }

    renderMultiChecklist(id) {
        const label = titles[id];
        const values = this.state[id] || [];
        return (
            <>
                <label className="input-label m-0">{label}</label>
                <div className="row">
                    {React.Children.toArray(values.map(val=>
                        <div className="form-group col-6">
                            <div className="custom-checkbox mt-2 d-flex">
                                <div className="w-15 mt-auto mb-auto">
                                <input 
                                    type="checkbox"
                                    className="text-center"
                                    style={{width:14, margin:0, padding:0}}
                                    checked={val.value}
                                    disabled={disabled[id]}
                                    onChange={(event)=>this.handleCheckListFieldChange(event,this.state)}
                                    id={val.id}/>
                                
                                </div>
                                <label className="my-0">{val.label}</label>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        )
    }

    renderDeleteDiscountOfferDialog() {
        const onClose = () => hideDialogById(DELETE_DISCOUNT_OFFER_DIALOG);
        const onDelete = () => {
            hideDialogById(DELETE_DISCOUNT_OFFER_DIALOG);
            this.deletePost();
        };
        return <ModalDialog id={DELETE_DISCOUNT_OFFER_DIALOG} title={"Confirm Delete post"} onClose={onClose}>
            <ModalDialogBody className="py-3">
                Are you sure you want to delete this discount offer? All its content that users entered will be removed.
            </ModalDialogBody>
            <ModalDialogFooter className="py-3">
                <button className="btn btn-xs btn-ghost-danger" onClick={onDelete}>Yes, delete it entirely</button>
                <button className="btn btn-xs btn-primary" onClick={onClose}>No, keep the discount offer</button>
            </ModalDialogFooter>
        </ModalDialog>;
    }

}