import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {UserContext} from "../UserContext";

import NavbarLogo from '../components/navbar/NavbarLogo';
import NavbarBody from '../components/navbar/NavbarBody';
import {isPermissionAllowed} from "../helpers/AccountHelper";

export default class AccountNavbar extends Component {

    // --------------------------------------------------------------------------------
    // ANCILLARY RENDER FUNCTION
    // --------------------------------------------------------------------------------

    renderNav(userContext) {
        if (userContext.userProfileLoaded !== true || !userContext.userProfile) return "";
        const userProfile = userContext.userProfile;
        const invitedToWorkspaces = userProfile.workspaces && userProfile.workspaces.length > 0;
        const creatorOfWorkspaces = isPermissionAllowed(userProfile, "create_workspace");
        if (!creatorOfWorkspaces && !invitedToWorkspaces) return "";

        return (
            <>
                <div className="navbar-nav-item">
                    <Link to={"/account/dashboard"} className="nav-link d-md-inline">
                        <span className="mr-2">Profile</span>
                    </Link>
                </div>
                <div className="navbar-nav-item">
                    <Link to={"/account/workspace"} className="nav-link d-md-inline">
                        <span className="mr-2">Workspace</span>
                    </Link>
                </div>
            </>
        )
    }

    // --------------------------------------------------------------------------------
    // MAIN RENDER FUNCTION
    // --------------------------------------------------------------------------------

    render() {
        // Don't go to home page when clicking on the logo, in device recognition pages
        return (
            <header id="header" className={`header ${this.props.fillBackground ? "" : "header-bg-transparent"} header--show-hide-md`}
                data-header-fix-moment="400" data-header-fix-effect="slide">
                <div className="header-section">
                    <div id="logoAndNav" className="container">
                        <nav className="navbar navbar-expand-md px-3 py-2">
                            <NavbarLogo />
                            <NavbarBody className="nav flex-column flex-md-row" full>
                                <UserContext.Consumer>
                                    {context => this.renderNav(context)}
                                </UserContext.Consumer>
                            </NavbarBody>
                        </nav>
                    </div>
                </div>
            </header>
        );
    }
}