import { ENUMS } from "./EnumHelper";

export const DEFAULT = {
    IAM: {
        start_ms: "2024-01-01",
        "account-has-desktop": ENUMS.MESSAGE.HAS_HARDWARE[0],
        "account-has-fingbox": ENUMS.MESSAGE.HAS_HARDWARE[0],
        "account-has-mobile": ENUMS.MESSAGE.HAS_HARDWARE[0]
    },
    IAB: {
        start_ms: "2024-01-01",
        "color-dark-border":"#FDC949",
        "color-dark-extra":"#FFFFFF",
        "color-light-border":"#FDC949",
        "color-light-extra":"#000000",
        "account-has-desktop": ENUMS.MESSAGE.HAS_HARDWARE[0],
        "account-has-fingbox": ENUMS.MESSAGE.HAS_HARDWARE[0],
        "account-has-mobile": ENUMS.MESSAGE.HAS_HARDWARE[0]
    }
}