import React from "react";
import { isNotEmptyString } from "../../../helpers/JsHelper";
import { reactSelectStyles } from "../helper/SelectHelper";
import Select from "react-select";



export default class EnumField extends React.Component {

    render() {
        const {label,value,attemptedSave, isMulti, mandatory, id, enumValues, onChange} = this.props;

        const isMissing = attemptedSave && mandatory && (!value || !isNotEmptyString(value.value, true));
        return <div className={"form-group mb-3"}>
            <label className="input-label my-0" htmlFor={id}>{label}</label>
            <Select
                name={id}
                isMulti={isMulti || false}
                isSearchable={false}
                styles={reactSelectStyles}
                placeholder={`Select ${label}`}
                value={value}
                onChange={onChange}
                options={enumValues}
            />
            {isMissing && <div className="text-danger">Please enter the {label}</div>}
        </div>;
    }

}