/**
 * Created by marco on 28/03/2020.
 *
 * (C) Copyright Fing
 */

import React, {Component} from 'react';

import {isUserSignedIn, UserContext} from "../UserContext";
import AppNavbar from "./../components/AppNavbar";
import AppFooter from "../components/AppFooter";
import {onKeyDown} from "../helpers/JsHelper";
import {
    AVATAR_SET,
    getJobRoles, getPrettyJobRole,
    techLevels
} from "../helpers/AccountHelper";
import AccountPageTitle from "./AccountPageTitle";
import AccountSignedOutEmptyState from "./AccountSignedOutEmptyState";

import {put, handleErrorFetch, remove} from "../services/APIService";
import Select from 'react-select'

import {ToastContainer} from "react-toastify";
import {successToast, failedToast} from "../helpers/ToastHelper";

import 'react-toastify/dist/ReactToastify.css';
import {withRouter} from "react-router-dom";
import {APP_FING_URL} from '../Constants';

import ModalDialog, {
    hideDialogById,
    ModalDialogBody,
    ModalDialogFooter,
    showDialogById
} from "../components/dialog/ModalDialog";
import {handleApiError} from "../components/table/helper/ErrorHelper";

const DELETE_ACCOUNT_DIALOG = "DELETE_ACCOUNT_DIALOG";
const DOMAIN_PATTERN = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}$/;

class AccountProfileEdit extends Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.firstNameInput = React.createRef();
        this.lastNameInput = React.createRef();
        this.displayNameInput = React.createRef();
        this.companyNameInput = React.createRef();
        this.companyDomainInput = React.createRef();
        this.pass1Input = React.createRef();
        this.pass2Input = React.createRef();

        this.state = {
            passwordError: false,
            responseEditProfile: null,
            selectedAvatar: null,
            clickedAvatar: null,
            selectedTechLevel: null,
            selectedJobRole: null,
            showEditProfileComplete: false,
            showChangeAvatarModal: false
        };
    }

    componentDidMount() {
        document.title = "Fing - Edit profile";
    }

    render() {
        return (
            <div>
                <AppNavbar mode='account'/>

                <main id="content" role="main">
                    {this.renderMainContentInContext()}
                </main>

                <AppFooter/>
            </div>
        );
    }

    renderMainContentInContext() {
        if (isUserSignedIn(this.context)) {
            return (<div className="bg-light">
                <div className="container space-2">
                    <AccountPageTitle
                        title="Edit your personal info"
                        subtitle="Tell us about yourself"/>
                    {this.renderMainContentSignedIn(this.context)}
                    {this.renderChangeAvatarModal()}
                    <ToastContainer/>
                </div>
            </div>)
        } else {
            return this.renderEmptyState();
        }
    }

    renderMainContentSignedIn(userContext) {
        if (!userContext.userProfile) return "";

        const {selectedAvatar} = this.state;
        let userProfile = userContext.userProfile;
        let isFingAvatar = true;

        let picUrl = APP_FING_URL + '/images/avatar/avatar-4.png';

        if (userProfile.pictureUrl) {
            if (userProfile.pictureUrl.startsWith('images/')) {
                picUrl = APP_FING_URL + '/' + userProfile.pictureUrl;
            } else {
                isFingAvatar = false;
                picUrl = userProfile.pictureUrl;
            }
        }

        const handleFormSubmit = (event) => {
            event.preventDefault();

            const {selectedAvatar} = this.state;
            let pass1, pass2;

            if (this.pass1Input.current && this.pass1Input.current.value) {
                pass1 = this.pass1Input.current.value;
            }

            if (this.pass2Input.current && this.pass2Input.current.value) {
                pass2 = this.pass2Input.current.value;
            }

            if (pass1 !== pass2) {
                this.setState({passwordError: true})
            } else {
                // submit endopoint

                let payload = Object.assign({}, userContext.userProfile);
                payload.name = this.displayNameInput.current.value;
                payload.firstName = this.firstNameInput.current.value;
                payload.lastName = this.lastNameInput.current.value;
                payload.companyName = this.companyNameInput.current.value;
                payload.techLevel = this.state.selectedTechLevel ? this.state.selectedTechLevel.value : userContext.userProfile.techLevel;
                // Only if it's TECH_EXPERT, send also the additional data
                if (payload.techLevel === techLevels[0].value) {
                    payload.companyDomain = this.companyDomainInput.current.value;
                    payload.jobRole = this.state.selectedJobRole ? this.state.selectedJobRole.value : userContext.userProfile.jobRole;

                    if (!payload.jobRole) {
                        failedToast("Please select a job role.");
                        return;
                    }
                } else {
                    payload.companyDomain = null;
                    payload.jobRole = null;
                }

                if (selectedAvatar) {
                    payload.pictureUrl = selectedAvatar;
                }

                if (payload.companyDomain && !DOMAIN_PATTERN.test(payload.companyDomain)) {
                    payload.companyDomain = null;
                    failedToast("Please enter a valid web domain.");
                    return;
                }


                put('/rest/users/profile', payload)
                    .then((res) => {

                        if (res) {
                            let newContext = Object.assign({}, userContext);
                            newContext.userProfile = res;
                            userContext.userContextUpdater(newContext);
                        }

                        successToast("Profile updated.");
                    })
                    .catch((err) => {

                        failedToast("Something went wrong.");

                        if (err && err.code === 401) {
                            let newContext = handleErrorFetch(userContext);
                            userContext.userContextUpdater(newContext);
                        }
                        console.log('Error in edit profile: ', err)
                    })
                    .finally(() => this.setState({passwordError: false}))
            }

        };

        const isTechLevelSelected = (techLevel) => {
            const {selectedTechLevel} = this.state;
            return (selectedTechLevel && selectedTechLevel.value === techLevel.value) ||
                (!selectedTechLevel && techLevel.value === this.context.userProfile.techLevel);
        };

        const isNoneTechLevelSelected = () => {
            return (this.state.selectedTechLevel === null && this.context.userProfile.techLevel === null);
        };

        const handleSelectTechLevel = (event, techLevel) => {
            this.setState({selectedTechLevel: techLevel})
        };

        // --------------------------------------------------------------------------------
        /** Start Select job role section */

        const options = getJobRoles();

        const formatGroupLabel = (data) => <p>{data.label}</p>;

        const reactSelectStyles = () => {

            // let background = this.state.selectedJobRole || userProfile.jobRole ? "#ffff" : "#fff8ea !important";
            let background = userProfile.jobRole ? "#ffff" : "#fff8ea !important";

            return {
                indicatorSeparator: (styles) => ({display: 'none'}),
                control: (base, state) => ({
                    ...base,
                    height: "51px",
                    // '&:hover': { background: 'yellow' }, // border style on hover
                    backgroundColor: background,
                    backdrop: "warning",
                    border: '1px solid #e7eaf3', // default border color
                    fontSize: '1rem',
                    fontWeight: '500',
                    background: 'transparent',
                }),
                container: (base, state) => ({
                    ...base,
                    opacity: state.isDisabled ? ".5" : "1",
                    backgroundColor: "transparent",
                    zIndex: "999",
                    height: "51px",
                }),
                placeholder: (provided, state) => ({
                    ...provided,
                    color: '#677788 !important',
                    opacity: ".8"
                }),
                valueContainer: (provided, state) => ({
                    ...provided,
                    'flexWrap': 'nowrap',
                    height: "51px",
                })
            }
        };

        let defaultValueJobRole = userProfile.jobRole ? {
            value: userProfile.jobRole,
            label: getPrettyJobRole(userProfile.jobRole)
        } : null;

        /** End Select job role section */

        const inputClasses = (ref, value) => {

            if (!this.context.userProfile[value]) return "form-control bg-soft-warning mt-0 shadow-none";
            // if (!this.context.userProfile[value] && (!ref.current || !ref.current.value)) return "form-control bg-soft-warning";

            return "form-control mt-0 shadow-none";
        };

        const onAvatarChange = () => this.setState({
            showChangeAvatarModal: true,
            clickedAvatar: (selectedAvatar || (isFingAvatar ? userProfile.pictureUrl : ""))
        });

        return <form className="form" onSubmit={handleFormSubmit}>
            <div className="row my-4">
                <div className="col-sm-12 col-md-3 py-2">
                    <h4>Your profile</h4>
                </div>
                <div className="col-sm-12 col-md-9">
                    <div className="card card-bordered">
                        <div className="card-body">

                            <div className="row form-group">
                                <label className="col-sm-3 input-label">Profile image</label>
                                <div className="col-sm-9 flex">
                                    <div className="avatar avatar-lg avatar-circle border">
                                        <img className="avatar-img"
                                             src={selectedAvatar ? APP_FING_URL + "/" + selectedAvatar : picUrl}
                                             alt="profile-img"/>
                                    </div>
                                    <button className="btn btn-sm btn-link" type="button" onClick={onAvatarChange}>
                                        Change avatar
                                    </button>
                                </div>
                            </div>

                            <div className="row form-group">
                                <label className="col-sm-3 input-label">First name</label>
                                <div className="col-sm-9">
                                    <div className="input-group">
                                        <input className={inputClasses(this.firstNameInput, "firstName")} type="text"
                                               onKeyDown={onKeyDown}
                                               name="firstName" placeholder="" ref={this.firstNameInput} maxLength="255"
                                               defaultValue={userProfile.firstName}/>
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group">
                                <label className="col-sm-3 input-label">Last name</label>
                                <div className="col-sm-9">
                                    <div className="input-group">
                                        <input className={inputClasses(this.lastNameInput, "lastName")} type="text"
                                               onKeyDown={onKeyDown}
                                               name="lastName" placeholder="" ref={this.lastNameInput} maxLength="255"
                                               defaultValue={userProfile.lastName}/>
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group">
                                <label className="col-sm-3 input-label">Display name</label>
                                <div className="col-sm-9">
                                    <div className="input-group">
                                        <input className={inputClasses(this.displayNameInput, "name")} type="text"
                                               required onKeyDown={onKeyDown}
                                               name="name" placeholder="e.g. John Smith" ref={this.displayNameInput}
                                               maxLength="255"
                                               defaultValue={userProfile.name}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row my-4">
                <div className="col-sm-12 col-md-3 py-2">
                    <h4>About technology</h4>
                </div>
                <div className="col-sm-12 col-md-9">
                    <div className="card card-bordered">
                        <div className="card-body">
                            <div className="row form-group">
                                <label className="col-sm-3 input-label">Tech Level</label>
                                <div className="col-sm-9">
                                    <div>
                                        {techLevels.map((techAt, idx) =>
                                            <div key={"tech_" + idx} className="w-100">
                                                <div
                                                    className="custom-control custom-radio custom-control-inline checkbox-outline checkbox-icon w-100">
                                                    <input type="radio" id={"techLevelRadioButton_" + idx}
                                                           name={"techLevelRadioGroup_" + idx}
                                                           className="custom-control-input checkbox-outline-input checkbox-icon-input"
                                                           checked={isTechLevelSelected(techAt)}
                                                           onClick={(event) => handleSelectTechLevel(event, techAt)}
                                                    />

                                                    <label
                                                        className={"checkbox-outline-label checkbox-icon-label card card-bordered w-100 p-4 mb-0" + (isNoneTechLevelSelected() ? " bg-soft-warning" : "")}
                                                        htmlFor={"techLevelRadioButton_" + idx}>
                                                        <h5 className="mb-0">{techAt.label}</h5>
                                                        <p className="mb-0">{techAt.text}</p>
                                                    </label>
                                                </div>
                                            </div>)}
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group">
                                <label className="col-sm-3 input-label">Company name</label>
                                <div className="col-sm-9">
                                    <div className="input-group">
                                        <input className={inputClasses(this.companyNameInput, "companyName")}
                                               type="text" onKeyDown={onKeyDown}
                                               name="companyName" placeholder="e.g. Fing" ref={this.companyNameInput}
                                               maxLength="255"
                                               defaultValue={userProfile.companyName}/>
                                    </div>
                                </div>
                            </div>

                            {
                                isTechLevelSelected(techLevels[0]) &&
                                <div className="row form-group">
                                    <label className="col-sm-3 input-label">Job role</label>
                                    <div className="col-sm-9">
                                        <Select
                                            styles={reactSelectStyles()}
                                            placeholder="e.g. IT Director"
                                            defaultValue={defaultValueJobRole}
                                            onChange={(selected) => this.setState({selectedJobRole: selected})}
                                            options={options}
                                            formatGroupLabel={formatGroupLabel}
                                        />
                                    </div>
                                </div>
                            }

                            {
                                isTechLevelSelected(techLevels[0]) &&
                                <div className="row form-group">
                                    <label className="col-sm-3 input-label">Company domain</label>
                                    <div className="col-sm-9">
                                        <div className="input-group">
                                            <input className={inputClasses(this.companyDomainInput, "companyDomain")} type="text"
                                                   onKeyDown={onKeyDown}
                                                   name="companyDomain" placeholder="e.g. fing.com"
                                                   ref={this.companyDomainInput} maxLength="255"
                                                   defaultValue={userProfile.companyDomain}/>
                                        </div>
                                    </div>
                                </div>
                            }


                        </div>
                    </div>
                </div>
            </div>

            <div className="row my-5">
                <div className="col-sm-12 col-md-9 offset-md-3 d-flex">
                    <button className="btn btn-primary btn-sm" type="submit">Save Changes</button>
                    <button className="btn btn-ghost-danger btn-sm ml-auto" role="button" type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                showDialogById(DELETE_ACCOUNT_DIALOG);
                            }}><i className="fa fa-fw fa-trash-alt mr-2"/>Delete account
                    </button>
                </div>
            </div>

            {this.renderDeleteAccountDialog()}

        </form>
    }

    renderDeleteAccountDialog() {
        const onClose = (e) => {
            e.preventDefault();
            hideDialogById(DELETE_ACCOUNT_DIALOG);
        }
        const onDelete = (e) => {
            e.preventDefault();
            hideDialogById(DELETE_ACCOUNT_DIALOG);
            this.deleteUserAccount();
        };
        return <ModalDialog id={DELETE_ACCOUNT_DIALOG} title={"Confirm Delete Account"} onClose={onClose}>
            <ModalDialogBody className="py-3">
                <div className="text-center"><i className="fas fa-2x fa-user-slash text-danger mb-3"/></div>
                Are you sure you want to delete your account? All your network data, customization, settings,
                history and content will be permanently lost.<br/>Once completed, you will be signed out.
                <div className="alert alert-soft-danger mt-3 mb-1 text-sm-left">This operation cannot be undone!</div>
            </ModalDialogBody>
            <ModalDialogFooter className="py-3">
                <button className="btn btn-xs btn-ghost-danger" onClick={onDelete}>Delete my account entirely</button>
                <button className="btn btn-xs btn-primary" onClick={onClose}>Keep my account</button>
            </ModalDialogFooter>
        </ModalDialog>;
    }

    deleteUserAccount() {
        remove(`/rest/users/profile`)
            .then((result) => {
                successToast("User removed");
                window.location.href = "/login?logout";
            })
            .catch((err) => {
                failedToast("Unable to delete user account");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
                window.location.href = "/login?logout";
            })
    }

    renderChangeAvatarModal() {

        const {showChangeAvatarModal, clickedAvatar} = this.state;

        const handleClose = () => this.setState({showChangeAvatarModal: false, clickedAvatar: null});

        const handleBackgroundColor = (avatarPath) => {

            const {clickedAvatar} = this.state;

            if (avatarPath === clickedAvatar) {
                return "avatar avatar-xl avatar-circle avatar-primary mt-2";
            }

            return "avatar avatar-xl avatar-circle mt-2";
        };

        const handleChange = (event, avatarPath) => {

            event.preventDefault();

            const {clickedAvatar} = this.state;

            if (clickedAvatar === avatarPath) {
                this.setState({clickedAvatar: null});
            } else {
                this.setState({clickedAvatar: avatarPath})
            }

        };

        const handleSubmit = () => {
            const {clickedAvatar} = this.state;
            this.setState({showChangeAvatarModal: false, selectedAvatar: clickedAvatar})
        };

        return (
            <div>
                <div className={`modal-backdrop fade ${showChangeAvatarModal ? "show d-block" : "d-none"}`}></div>
                <div className={`modal fade ${showChangeAvatarModal ? "show d-block" : "d-none"}`} role="dialog"
                     tabIndex="-1"
                     aria-labelledby="Address_Modal_Label" aria-hidden={!showChangeAvatarModal}
                     aria-modal={showChangeAvatarModal}>
                    <div className="modal-dialog  modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div id="Address_Modal_Label" className="modal-header">
                                <h5 className="modal-title h6">Choose an avatar</h5>
                                <button type="button" className="close" onClick={handleClose} data-dismiss="modal"
                                        aria-label="Close">
                                    <span aria-hidden="true">×</span><span className="sr-only">Close</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="mx-3">
                                    {AVATAR_SET.map((avatarSrc, idx) => <div key={"avatar_img_" + idx}
                                                                             className={handleBackgroundColor(avatarSrc)}
                                                                             onClick={(event) => handleChange(event, avatarSrc)}>
                                        <img src={APP_FING_URL + "/" + avatarSrc} className="avatar-img" alt="Female 1"
                                             __idx="0"/>
                                    </div>)}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="d-flex justify-content-end">
                                    {this.state.clickedAvatar ? <button className="btn btn-success btn-xs mr-2"
                                                                        onClick={handleSubmit}> That's me!
                                    </button> : <button className="btn btn-success btn-xs mr-2"
                                                        onClick={handleSubmit} disabled> That's me!
                                    </button>}
                                    <button className="btn btn-outline-secondary btn-xs"
                                            onClick={handleClose}>Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

    renderEmptyState() {
        return <AccountSignedOutEmptyState/>;
    }

}

export default withRouter(AccountProfileEdit)