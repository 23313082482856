import React, { Component } from "react";
import './DataTableView.css';
import { UserContext } from "../../UserContext";
import { handleErrorFetch } from "../../services/APIService";
import { createUrlWithParams, get } from '../../services/APIService';
import Select from 'react-select'
import { reactSelectStyles } from "./helper/SelectHelper";
import { isEmptyObject } from '../../helpers/JsHelper';
import ActionButton, { BTN_SIZE_BIG } from '../dialog/ActionButton';
import { getParamsFromUrl } from "./helper/TableHelper";

const debounce = require('lodash.debounce');

export default class DataTable extends Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            rows: null,
            total: null,
            filtered: null,
            params: {},
            selectedPage: 1,
            searched: 0,
            loading: false,
            forbidden: false,
            autoBlockSearch: this.props.autoBlockSearch
        };
        this.updateQuery = debounce(this.updateQuery, 800);
    }

    componentDidMount() {
        const { resources } = this.props;

        if(this.props.autoBlockSearch){
            this.setState({
                rows: [],
                total: -1,
                start: 0
            },()=>{
                if (typeof window.initFlatpickr === "function") {
                    window.initFlatpickr();
                }
            })

            return;
        }


        this.setState({ loading: true }, () => this.getTableData(resources)
            .then(() => {
                if (typeof window.initFlatpickr === "function") {
                    window.initFlatpickr();
                }
            }));


        
    }

    async getTableData(resources, params) {   
        
        params = params || {};

        if(this.props.queryUrl !== undefined && this.props.queryUrl !== null){
            const p = getParamsFromUrl(new URLSearchParams(window.location.search),this.props.queryUrl);

            for(let e in p){
                if(params[e] === "search"){
                    params['length'] = p && p.length ? p.length : 12;
                }
                params[e] = p[e];
            }
        }
        
        
        const url = createUrlWithParams(resources, params);

        return get(url)
            .then((response) => {
                if (response) {
                    if(this.props.onSearch){
                        this.props.onSearch(response.data);
                    }
                    const num = this.state.searched + 1

                    this.setState({
                        rows: response.data,
                        total: response.total,
                        filtered: response.filtered,
                        start: response.start,
                        params: params,
                        searched: num
                    })
                }
            })
            .catch((err) => {
                if (err && err.code === 401) {
                    let newContext = handleErrorFetch(this.context);
                    this.context.userContextUpdater(newContext);
                }
                if (err && err.code === 403) {
                    this.setState({forbidden: true});
                    if (this.props.onForbidden)
                        this.props.onForbidden();
                }
            })
            .finally(() => this.setState({ loading: false }));

    }

    parseDate = dateString => {
        let toReturn = "";
        if (dateString.indexOf(' - ') !== -1) {
            toReturn = dateString.split(' - ').join(';');
        } else {
            toReturn = dateString;
        }
        toReturn = toReturn.split("/").join("-");
        return toReturn;

    }

    parentUpdateData = (updates) => {
        let { params, total  } = this.state;
        let prevPagination = false;
        if (updates) {
            for (let key of Object.keys(updates)) {
                if (key === "total") {
                    total = total + updates[key];
                    if (params.start >= total && params.start > 0) {
                        params.start = params.start - params.length;
                        prevPagination = true;
                    }
                }
            }
        }
        this.updateQuery(params)
    }

    handleOrder = column => {
        let { params } = this.state;
        if (params.sortedBy === column) {
            params.reverse = !params.reverse;
        } else {
            params.sortedBy = column;
            params.reverse = true;
        }
        this.updateQuery(params);
    }

    handleSearchOnChange = event => {
        const { params } = this.state;
        if (event.target) {
            params.search = event.target.value;
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set('search', params.search);
            window.history.replaceState(null, null, "?"+searchParams.toString());
            params.start = 0;
            this.setState({ selectedPage: 1 }, () => {
                if(!this.props.autoBlockSearch){
                    this.updateQuery(params);
                }
            });
        }
    }

    handleSelectLengthChange = event => {
        const { params } = this.state;

        if (event.target && event.target.value) {
            params.length = event.target.value;
            // pagination is changing and then we have to start to 0 again
            params.start = 0;
            this.setState({ selectedPage: 1 }, () => this.updateQuery(params))
        }
    }

    updateQuery = params => this.getTableData(this.props.resources, params);

    // --------------------------------------------------------------------------------
    // ANCILLARY RENDER FUNCTION
    // --------------------------------------------------------------------------------

    renderHeader() {
        const { headers } = this.props;
        return <tr>
            {React.Children.toArray(headers.map(col => <th style={{width: col.width}} className="h6 text-dark p-2" scope="col">
                <span className="d-flex align-items-center">{col.displayField}
                    {col.sortable && <span
                        onClick={() => this.handleOrder(col.name)}
                        role="button"
                        className="fa fa-sort ml-1 text-muted" />}
                </span>
            </th>))}
        </tr>;

    }

    renderCell(header, row) {
        return <td>{row[header.name] || ""}</td>
    }

    renderRows() {
        const { rows, loading } = this.state;
        let { headers, onRenderCell, onClickRow } = this.props;

        if (!onRenderCell) onRenderCell = this.renderCell;

        const handleClick = (row) => {
            if (onClickRow) {
                return onClickRow(row);
            }
        }

        return <>
            {React.Children.toArray(rows.map((row) =>
                <tr onClick={() => handleClick(row)}>
                    {React.Children.toArray(headers.map(header => onRenderCell(header, row)))}
                </tr>
            ))}
        </>;
    }

    renderPagination() {
        let { total, start, selectedPage, params, loading } = this.state;

        const {excludePageNumbers} = this.props;

        let startingRendering = 1;
        const maxButtonNumber = 5;

        total = total || 0;
        start = start || 0;
        params.length = params.length || 12;

        if (total <= params.length && !excludePageNumbers) return "";

        let pages = Math.ceil((Number(total) / Number(params.length)));

        if (selectedPage > pages && !excludePageNumbers) this.setState({ selectedPage: selectedPage -1});

        const classesPagination = (page) => {

            const { selectedPage } = this.state;

            if (!page) {

                let classPrevNext = "list-group-item list-group-item-action max-w-13rem text-center";
                if (selectedPage === 1 || selectedPage === pages) {
                    classPrevNext += " disabled";
                }

                return classPrevNext;
            }

            if (selectedPage === page) {
                return "list-group-item list-group-item-action list-group-item-primary max-w-8rem text-center";
            }

            return "list-group-item list-group-item-action max-w-8rem text-center";
        }



        const paginationClick = (e) => {

            let { params, loading } = this.state;

            if(loading) return;

            if (e.target && e.target.value) {
                params.start = params.length * (Number(e.target.value) - 1);
                this.setState({ selectedPage: e.target.value }, () => {
                    this.getTableData(this.props.resources, params)
                });
            }
        }

        const preOrNextPaginationClick = (isNext) => {
            let { selectedPage, params, loading } = this.state;
            if(loading) return;
            let newPage = isNext ? selectedPage + 1 : selectedPage - 1;
            if(newPage < 0 && excludePageNumbers) newPage = 0;
            params.start = params.length * (newPage - 1);
            this.setState({ selectedPage: newPage },() => this.getTableData(this.props.resources, params)); 
        }


        let i = 1;
        let setOfPages = [];

        setOfPages.push(<li value="previous"
            role="button"
            onClick={() => preOrNextPaginationClick(false)}
            className={"list-group-item list-group-item-action max-w-13rem text-center" + (loading || (this.state.selectedPage === 1 && !excludePageNumbers) ? " disabled" : "")}>Previous</li>);

            
        if(!excludePageNumbers){
            if (selectedPage > 3) {
                startingRendering = selectedPage - 2;
    
                // button first 
                setOfPages.push(<li value={1}
                    role="button"
                    onClick={paginationClick}
                    className={classesPagination(1)}>{1}</li>);
    
                // prevDots   
                setOfPages.push(<li value="prevDots"
                    className={"list-group-item list-group-item-action max-w-7rem"}>...</li>);
            }
    
            while (i <= pages) {
    
                if (i >= startingRendering) {
                    setOfPages.push(<li value={i}
                        role="button"
                        onClick={paginationClick}
                        className={classesPagination(i)}>{i}</li>)
                }
    
                i++;
    
                if (i > maxButtonNumber && i >= (selectedPage + 3)) {
                    break;
                }
    
            }
    
            if (pages > maxButtonNumber && ((selectedPage + 2) < pages)) {
    
                // nextDots
                setOfPages.push(<li value="nextDots"
                    className={"list-group-item list-group-item-action max-w-7rem"}>...</li>);
    
                // button last 
                setOfPages.push(<li value={pages}
                    role="button"
                    onClick={paginationClick}
                    className={classesPagination(pages)}>{pages}</li>);
            }
        }
        

        setOfPages.push(<li value="next" role="button" onClick={() => preOrNextPaginationClick(true)}
            className={"list-group-item list-group-item-action max-w-13rem text-center" + (loading || (this.state.selectedPage === pages && !excludePageNumbers) ? " disabled" : "")}>Next</li>);
        return <ul className="list-group list-group-horizontal-sm float-right">{React.Children.toArray(setOfPages)}</ul>
    }

    renderExtraSearchFields() {
        const { extraSearchFields } = this.props;

        const { loading } = this.state;
        if (!extraSearchFields) return "";


        const handleExtraSelectChange = (options, param) => {
            const { params } = this.state;

            if (param) {

                let values = '';

                if (options) {
                    if (Array.isArray(options)) {
                        values = options.map(opt => opt.value).join(";");
                    } else {
                        values = options.value || "";
                    }
                }
                let searchParams = new URLSearchParams(window.location.search);
                params[param] = values;
                searchParams.set(param,values);
                if(searchParams.toString() !== ''){
                    window.history.replaceState(null, null, "?"+searchParams.toString());
                }
                params.start = 0;

                this.setState({ selectedPage: 1 }, () => {
                    if(!this.props.autoBlockSearch){
                        this.getTableData(this.props.resources, params)
                    }
                });
            }
        }
        
        return <>
            {React.Children.toArray(extraSearchFields.map((extraSearch) => {
                const filterForEnumValues = (v) => extraSearch?.enumValues?.filter(val => val.value === v)[0]
                let col = 'col-lg-3 col-md-3';
                if(this.props.autoBlockSearch){
                    col = 'col-lg-2 col-md-2'
                }
                switch (extraSearch.type) {
                    case "enum":
                        const value = this.props.params[extraSearch.param]
                        let defaultValue;
                        if(value && value.includes(";")){
                            defaultValue = [];
                            for(const v of value.split(";")){
                                defaultValue.push(filterForEnumValues(v));
                            }
                        } else {
                            defaultValue = filterForEnumValues(value);
                        }
                        
                        return <div className={col}>
                            <Select
                                options={extraSearch.enumValues}
                                defaultValue={defaultValue}
                                isMulti={extraSearch.multiple || false}
                                styles={reactSelectStyles}
                                placeholder={extraSearch.label}
                                isDisabled={loading}
                                onChange={(e) => handleExtraSelectChange(e, extraSearch.param)}
                            />
                        </div>;
                    case "date":
                        const handlerChangeDate = (event, param) => {
                            const { params } = this.state;
                            if (param) {
                                params[param] = this.parseDate(event.target.value);
                                params.start = 0;
                                this.setState({ selectedPage: 1 }, () => {
                                    if(!this.props.autoBlockSearch){
                                        this.getTableData(this.props.resources, params)
                                    }
                                });
                            }
                        }

                        let dateOptions = `{
                            "appendTo": "#flatpickrWrapper",
                            "mode": "${extraSearch.range ? "range" : "single"}",
                            "static" : "true",
                            "defaultDate": "${extraSearch.defaultDate || ""}",
                            "dateFormat": "Y/m/d"
                            }
                        `;

                        return <div className={col + " my-2"}>
                            <div id="flatpickrWrapper" className="flatpickr w-100">
                                <input className={"js-range-flatpickr m-0 rounded-right w-100 border " + (loading ? 'bg-soft-dark' : 'bg-white')}
                                    type="date"
                                    style={{height:43}}
                                    disabled={loading}
                                    placeholder={extraSearch.label}
                                    onSelect={(e) => handlerChangeDate(e, extraSearch.param)}
                                    data-hs-flatpickr-options={dateOptions}></input>
                            </div>
                        </div>;
                    default:
                        return "";
                }

            }))}
        </>

    }

    // --------------------------------------------------------------------------------
    // RENDER FUNCTION
    // --------------------------------------------------------------------------------

    render() {
        const { rows, loading, forbidden } = this.state;
        // if (loading) return <div className="row"><div className="col-12 text-center"><WaitingComponent/></div></div>;

        if (forbidden) return '';

        const toTextValue = this.state.params && this.state.params.length ? this.state.params.length : 12;

        const onSearch = () => {
            
            this.setState({loading: true}, 
                () => this.updateQuery(this.state.params))
        }
        
        return (
            <div>
                <div className="row align-items-sm-center mb-5">
                    {this.props.search === true && <div className="col-lg-3 col-md-3">
                        <div className="input-group input-group-sm">
                                <input id="datatableSearch" 
                                    type="text"
                                    className={"m-0 border " + (loading ? 'bg-soft-dark' : 'bg-white')}
                                    disabled={loading}
                                    style={{
                                        paddingTop:6,
                                        paddingBottom:6,
                                    }}
                                    placeholder={"Search in " + this.props.name}
                                    aria-label="Search activities"
                                    defaultValue={this.props.params['search']}
                                    aria-describedby="searchActivities"
                                    onChange={this.handleSearchOnChange}/>
                        </div>
                    </div>}
                    {this.renderExtraSearchFields()}
                    {this.props.autoBlockSearch &&
                    <div>
                    <ActionButton
                            running={loading}
                            title={loading ? "Searching..." : "Search"}
                            disabled={loading}
                            size={BTN_SIZE_BIG}
                            action={onSearch}/>
                    </div>
                        }
                    {this.props.searchHint && <div className="col-12 small text-muted px-4">{this.props.searchHint}</div>}
                </div>

                

                {rows && rows.length ? <>
                    <div className="table-responsive-md ">
                        <table className="table table-striped table-borderless">
                            <thead>{this.renderHeader()}</thead>
                            <tbody>{this.renderRows()}</tbody>
                        </table>
                    </div>
                    <div className="row align-items-start">
                        <div className="col-lg-6">
                            {this.props.entries &&
                                <>
                                    <div className="input-group input-group-sm w-25">
                                        <select id="datatableEntries" disabled={loading} className="form-control-sm custom-select"
                                            onChange={this.handleSelectLengthChange}>
                                            <option value="6">6 entries</option>
                                            <option value="12" selected>12 entries</option>
                                            <option value="18">18 entries</option>
                                            <option value="24">24 entries</option>
                                        </select>
                                    </div>
                                    {!this.props.excludePageNumbers && <p className="h6 my-2">Showing 1 to {toTextValue} of {this.state.total} entries</p>}                                
                                </>
                            }
                        </div>
                        {this.props.pagination && <div className="col-lg-6">{this.renderPagination()}</div>}
                    </div>
                </> : this.renderEmptyResponse()}

            </div>
        )
    }

    renderEmptyResponse(){
        let text = 'Apply a filter and make a search to show the results';
        if(this.state.searched > 0 && this.state.rows?.length === 0){
            if(Object.keys(this.state.params).length > 0){
                text = 'Search result with no records';
            } else {
                text = 'No filter applied';
            }
        }
        return (
            <div className="alert alert-soft-primary">{text}</div>
        )
    }

}