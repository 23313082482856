import React,{Component} from "react";
import PropTypes from 'prop-types';
import ActionButton, { BTN_SIZE_JUMBO, BTN_TINT_DARK, BTN_TYPE_GHOST } from "./ActionButton";
import ModalDialog from "./CustomModalDialog";
import { hideDialogById, ModalDialogBody, ModalDialogFooter } from './ModalDialog';


export default class IAMDialog extends React.Component {
    render() {
        const messageStyle = this.props.message;
        
        let layout = messageStyle?.layout

        if(Array.isArray(messageStyle.layout))
            layout = layout[0];
        const DialogComponent = this.getDialogComponentForLayout(layout);
        let dialogProps = {
            id: this.props.id,
            onClose: this.props.onClose || hideDialogById(this.props.id),
            onHide: this.props.onClose || hideDialogById(this.props.id),
            image: this.getImages(messageStyle),
        };

        if (layout !== "IMAGE") {
            dialogProps.title = messageStyle?.titles?.en;
            dialogProps.text = messageStyle?.bodies?.en;
        }

        if (layout !== "TOP_BANNER") {
            dialogProps.buttonTitle = messageStyle?.primary_button?.texts?.en;
        }

        return <DialogComponent {...dialogProps} />;
    }

    getImages(messageStyle){

        if (messageStyle?.images?.animated) {
            return messageStyle.images.animated;
        }

        if (messageStyle?.images?.landscape) {
            return messageStyle.images.landscape;
        }

        return messageStyle?.images?.default;
    }

    getDialogComponentForLayout(layout) {
        if (layout === "MODAL") {
            return Modal;
        } else if (layout === "IMAGE") {
            return Image;
        } else if (layout === "CARD") {
            return Card;
        } else if (layout === "TOP_BANNER") {
            return TopBanner;
        }

        return '';
    }
}

class Modal extends React.Component {
    render() {    
        return <ModalDialog 
            id={this.props.id} 
            title={this.props.title}
            onHide={this.props.onHide}
            centerVertically
            centerTitle
        >
            <hr className="w-100 b-0 mb-0" />
            <ModalDialogBody className="text-center overflow-hidden p-3">
                <img className="bg-img-start w-100 mb-3" src={this.props.image} />
                {this.props.text}
            </ModalDialogBody>
            <DialogButtons 
                onClose={this.props.onClose}
                onAction={this.props.onAction} 
                actionTitle={this.props.buttonTitle} 
                action={this.props.buttonAction} 
            />
        </ModalDialog>;
    }
}

class Image extends React.Component {
    render() {
        return <ModalDialog 
            id={this.props.id}
            onHide={this.props.onHide}
            centerVertically
            centerTitle
        >
            <ModalDialogBody className="text-center overflow-hidden p-0">
                <img className="bg-img-start w-100" src={this.props.image} />
            </ModalDialogBody>
            <DialogButtons 
                onClose={this.props.onClose}
                onAction={this.props.onAction} 
                actionTitle={this.props.buttonTitle} 
                action={this.props.buttonAction} 
            />
        </ModalDialog>;
    }
}

class Card extends React.Component {
    render() {
        return <ModalDialog 
            id={this.props.id}
            onHide={this.props.onHide}
            centerVertically
            centerTitle
        >
            <ModalDialogBody className="p-0">
                <div className="card bg-light">
                    <img className="card-img-top" src={this.props.image}/>
                    <div className="card-body">
                        <h3 className="card-title mb-2">{this.props.title}</h3>
                        <p className="card-text">{this.props.text}</p>
                    </div>
                </div>
            </ModalDialogBody>
            
            <DialogButtons 
                onClose={this.props.onClose}
                onAction={this.props.onAction} 
                actionTitle={this.props.buttonTitle} 
                action={this.props.buttonAction} 
            />
        </ModalDialog>;
    }
}

class TopBanner extends React.Component {
    render() {
        return <ModalDialog 
            id={this.props.id}
            onHide={this.props.onHide}
            onClick={this.props.onAction ? () => this.props.onAction(this.props.buttonAction) : undefined}
        >
            <ModalDialogBody className="container bg-dark p-3">
                <div className="row overflow-hidden">
                    <img className="col-2 pr-0" src={this.props.image} style={{ objectFit:"cover"}}/>
                    <div className="col-10">
                        <h5 className="modal-title text-light mb-1">{this.props.title}</h5>
                        <p className="text-white h6">{this.props.text}</p>
                    </div>
                </div>
            </ModalDialogBody>
        </ModalDialog>;
    }
}

class DialogButtons extends React.Component {
    render() {
        let onAction = null;

        if (this.props.onAction) {
            onAction = () => {
                this.props.onAction(this.props.action);
            };
        }

        return <ModalDialogFooter className="btn-group p-0">
            <ActionButton 
                className="border-right m-0 rounded-0" 
                action={this.props.onClose} 
                title="Close" 
                type={BTN_TYPE_GHOST} 
                tint={BTN_TINT_DARK} 
                size={BTN_SIZE_JUMBO}
            />
            <ActionButton 
                className="m-0 rounded-0"
                action={onAction} 
                title={this.props.actionTitle}
                type={BTN_TYPE_GHOST} 
                size={BTN_SIZE_JUMBO}
            />
        </ModalDialogFooter>;
    }
}

IAMDialog.propTypes = {
    id: PropTypes.string,
    message: PropTypes.object,
    onAction: PropTypes.func,
    onClose: PropTypes.func,
    onHide: PropTypes.func
};
 