export const MAX_LENGTH = {
    AGENT: {
        agentId: 256,
        agentPlatform: 256,
        agentVersion: 256,
        userId: 256,
        customName: 256,
        shardId: 256,
        activationCode: 256,
        agentToken: 256,
        networkSignature: 256,
        networkId: 256,
        masterUserId: 256,
        hardwarePlatform: 256,
        makeBatch: 256,
        agentSerialNo: 256,
        customerTag: 256,
        lastActivationUserId: 256,
        lastDeactivationUserId: 256,
        fingboxType: 256,
        adminState: 256,
        notes: 256,
        geoCountryCode: 256,
        geoCountryCity: 256,
        agentChannel: 256,
    },
    CONNECTION: {
        userId: 256,
        clientId: 256,
        clientName: 256,
        clientType: 256,
        clientVersion: 256,
        clientPlatform: 256,
        lastAccessAddress: 256,
        loginProvidernotificationToken: 256,
        apnsToken: 256
    },
    OFFER: {
        promoText: 256,
        description: 256,
        purchaseRules: 256, 
        allowedCountries: 256,
        discountId: 256
    },
    POST: {
        contentUrl: 256,
        title: 256,
        description: 256,
        previewImageUrl: 256,
        source: 256,
        sourceIconURL: 256,
        curatorScore: 10,
        curatorComment: 256,
    },
    USER: {
        name: 64,
        firstName: 64,
        lastName: 64, 
        email: 64,
        timezone: 32,
        googleUserId: 256,
        facebookUserId: 256,
        appleId: 256,
        jobTitle: 64,
        techAttitude: 64,
        professionalField: 64,
        registrationDate: 64,
        alertEmail: 64,
        fadNodeId: 256,
        countryCode: 8,
        countryRegion: 32,
        countryCity: 32,
        isp: 64,
        languageCode: 64,
        companyName: 64,
        stripeCustomerId: 256,
        stripeActiveSubscriptionId: 256
    },
    VALIDATION: {
        userId: 256,
        gatewayHwAddress: 256,
        blockingClientHwAddress: 256,
        blockingClientId: 256,
        comment: 256,
        publicIpAddress: 256,
    }
}