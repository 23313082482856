/**
 * Created by marco on 28/03/2020.
 *
 * (C) Copyright Fing
 */

import React, { Component } from 'react';

import {getLinkForSignIn, isUserSignedIn, UserContext} from "../UserContext";
import AppNavbar from "./../components/AppNavbar";
import AppFooter from "../components/AppFooter";

import {remove} from "../services/APIService";

import { successToast, failedToast } from "../helpers/ToastHelper";

import 'react-toastify/dist/ReactToastify.css';
import {withRouter} from "react-router-dom";

import ModalDialog, {
    hideDialogById,
    ModalDialogBody,
    ModalDialogFooter,
    showDialogById
} from "../components/dialog/ModalDialog";
import {handleApiError} from "../components/table/helper/ErrorHelper";
import intl from "react-intl-universal";
import AccountDeleteImage from "../assets/svg/stories/peace-of-mind.svg";

const DELETE_ACCOUNT_DIALOG = "DELETE_ACCOUNT_DIALOG";

class AccountProfileDelete extends Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = "Fing - Delete account";
    }

    render() {
        return (
            <div>
                <AppNavbar mode='account' />

                <main id="content" role="main">
                    {this.renderMainContentInContext()}
                </main>

                <AppFooter />
            </div>
        );
    }

    renderMainContentInContext() {
        if (isUserSignedIn(this.context)) {
            return this.renderEmptyState(this.context);
        } else {
            return this.renderEmptyState();
        }
    }


    renderDeleteAccountDialog() {
        const onClose = (e) => {
            e.preventDefault();
            hideDialogById(DELETE_ACCOUNT_DIALOG);
        }
        const onDelete = (e) => {
            e.preventDefault();
            hideDialogById(DELETE_ACCOUNT_DIALOG);
            this.deleteUserAccount();
        };
        return <ModalDialog id={DELETE_ACCOUNT_DIALOG} title={"Confirm Delete Account"} onClose={onClose}>
            <ModalDialogBody className="py-3">
                <div className="text-center"><i className="fas fa-2x fa-user-slash text-danger mb-3"/></div>
                Are you sure you want to delete your account? All your network data, customization, settings,
                history and content will be permanently lost.<br/>Once completed, you will be signed out.
                <div className="alert alert-soft-danger mt-3 mb-1 text-sm-left">This operation cannot be undone!</div>
            </ModalDialogBody>
            <ModalDialogFooter className="py-3">
                <button className="btn btn-xs btn-ghost-danger" onClick={onDelete}>Delete my account entirely</button>
                <button className="btn btn-xs btn-primary" onClick={onClose}>Keep my account</button>
            </ModalDialogFooter>
        </ModalDialog>;
    }

    deleteUserAccount() {
        remove(`/rest/users/profile`)
            .then((result) => {
                successToast("User removed");
                window.location.href = "/login?logout";
            })
            .catch((err) => {
                failedToast("Unable to delete user account");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
                window.location.href = "/login?logout";
            })
    }
    renderEmptyState(userContext) {
        const canDelete = userContext && userContext.userProfile;
        return (
            <div className="container space-2">
                <div className="row justify-content-md-between align-items-md-center mb-10">
                    <div className="col-col-md-7 col-xl-6">
                        <div className="mb-6">
                            <h1 className="display-5 mb-3">Delete your account</h1>
                            <p className="lead mb-9">Not using Fing anymore? Remove your Fing Account with this procedure.</p>
                            <ul>
                                <li>Sign in with your credentials and follow the on-screen instructions to delete your account</li>
                                <li>Alternatively, you may <a href="https://help.fing.com/hc/en-us/requests/new">submit a request</a> to the Help Center, specifying you intend to permanently delete your account.
                                    The request will be accepted only if you write and reply from the email address of your account. This might take a few days to be processed</li>
                                <li>All your network data, customization, settings, history and content will be permanently lost. Once completed, you will be signed out.</li>
                            </ul>
                        </div>
                        <div>
                            {canDelete ?
                                <button className="btn btn-danger" role="button" type="button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            showDialogById(DELETE_ACCOUNT_DIALOG);
                                        }}><i className="fa fa-fw fa-trash-alt mr-2"/>{intl.get('account_delete')}</button>
                                :
                                <a className="btn btn-primary btn-wide mr-lg-4"
                                   href={getLinkForSignIn(this.context, window.location.href)}>{intl.get('account_sign_in')}</a>
                            }
                        </div>
                    </div>
                    <div className="col-md-5 col-xl-6">
                        <img className="img-fluid" src={AccountDeleteImage}
                             alt="Image Description" />
                    </div>

                </div>
                {this.renderDeleteAccountDialog()}
            </div>
        );
    }

}

export default withRouter(AccountProfileDelete)