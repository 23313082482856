import React from "react";

export default class EventComponent extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.handleInputFieldChange = this.handleInputFieldChange.bind(this);
        this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
        this.handleCheckListFieldChange = this.handleCheckListFieldChange.bind(this);
        this.handleMultiInputFieldChange = this.handleMultiInputFieldChange.bind(this);
        this.handleSwitchFieldChange = this.handleSwitchFieldChange.bind(this);
    } 

    handleSwitchFieldChange(event) {
        this.setState({[event.target.id]: !this.state[event.target.id]});
    }

    handleInputFieldChange(event) {
        // Thanks to ES6 and Babel, this works
        this.setState({[event.target.id]: event.target.value});
    }

    handleSelectFieldChange(value, event) {
        // Thanks to ES6 and Babel, this works
        this.setState({[event.name]: value});
    }

    handleCheckListFieldChange(event,state) {
        const values = state.allowedPlans
        values.map(row => {
            if(event.target.id === row.id){
                row.value = !row.value; 
            }
        });
        this.setState({allowedPlans: values});
    }

    handleMultiInputFieldChange(event,id, index) {
        const values = this.state[id];
        values[index] = event.target.value;
        this.setState({purchaseRules: values});
    }

}