/**
 * Created by setefano on 31/03/2020.
 *
 * (C) Copyright Fing
 */

import React, { Component } from 'react';
import BackButton from "../components/BackButton";


export default class AccountPageTitle extends Component {

    render() {

        return (
            <div className="d-flex justify-content-between align-items-center mb-2">
                <div>
                    <h3 className="mb-0">{this.props.title}</h3>
                    <p className="text-muted mb-0">{this.props.subtitle}</p>
                </div>
                <BackButton to="/account/dashboard" caption="Back to Dashboard" />
            </div>
        )
    }

}