export const REGEX = {
    IAB: {
        conversion:/^\S+$/,
        iabId:/^\S+$/,
        action:/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
        "color-light-border":/^#(?:[0-9a-fA-F]{3}){1,2}$/,
        "color-light-extra":/^#(?:[0-9a-fA-F]{3}){1,2}$/,
        "color-dark-border":/^#(?:[0-9a-fA-F]{3}){1,2}$/,
        "color-dark-extra":/^#(?:[0-9a-fA-F]{3}){1,2}$/,
        'image-default':/^https?:\/\/.*\/.*\.(png|gif|webp|jpeg|jpg)\??.*$/i,
        'image-landscape':/^https?:\/\/.*\/.*\.(png|gif|webp|jpeg|jpg)\??.*$/i,
        'image-animated':/^https?:\/\/.*\/.*\.(png|gif|webp|jpeg|jpg)\??.*$/i,
        "notification-local-time":/(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/,
        'frequency-per-device': /^\d*[1-9]+\d*$/,
        'days-between-display': /^\d*[1-9]+\d*$/,
        'days-across-any-message': /^\d*[1-9]+\d*$/,
        'days-since-first-open': /^\d*[1-9]+\d*$/
    },
    IAM: {
        "conversion-display":/^\S+$/,
        "conversion-click":/^\S+$/,
        iamId:/^\S+$/,
        action:/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
        'image-default':/^https?:\/\/.*\/.*\.(png|gif|webp|jpeg|jpg)\??.*$/,
        'image-landscape':/^https?:\/\/.*\/.*\.(png|gif|webp|jpeg|jpg)\??.*$/,
        'image-animated':/^https?:\/\/.*\/.*\.(png|gif|webp|jpeg|jpg)\??.*$/,
        "notification-local-time":/(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/,
        'frequency-per-device': /^\d*[1-9]+\d*$/,
        'days-between-display': /^\d*[1-9]+\d*$/,
        'days-across-any-message': /^\d*[1-9]+\d*$/,
        'days-since-first-open': /^\d*[1-9]+\d*$/
    }
}