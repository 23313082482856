import React from "react";
import { isNotEmptyString } from "../../../helpers/JsHelper";



export default class DateField extends React.Component {

    render() {
        const {label,value,attemptedSave, disabled, mandatory, id, onChange} = this.props;

        const isMissing = attemptedSave && mandatory && !isNotEmptyString(value, true);

        let wrapperId = `${id}_wrapper`;
        return <div className={"form-group mb-3"}>
            <label className="input-label my-0" htmlFor={id}>{label}</label>
            <div id={wrapperId} className="flatpickr w-100">
                <input className={`my-0 js-range-flatpickr form-control form-control-sm bg-transparent rounded-right w-100 ${isMissing ? "border-danger" : ""}`}
                       id={id}
                       disabled={disabled}
                       type="date"
                       placeholder={`Select ${label}`}
                       data-hs-flatpickr-options={`{
                            "appendTo": "#${wrapperId}",
                            "mode": "single",
                            "static" : "true",
                            "defaultDate": "",
                            "dateFormat": "Y/m/d"
                            }
                        `}
                       value={value} onChange={onChange}
                />
                {isMissing && <div className="text-danger">Please enter the {label}</div>}
            </div>
        </div>;
    }

}