import React from "react";


// labelSpan, badgeSpan -> How many columns takes the cell?

export default class TableBadgeField extends React.Component {

    render() {
        const {label, value, enumValue, labelSpan, badgeSpan} = this.props;
        return (
            <tr>
                <td style={{width:'50%'}} colspan={labelSpan || 1} className="p-2">{label}</td>
                <td style={{width:'50%'}} colspan={badgeSpan || 1} className="p-2 text-dark font-weight-bold">
                    {enumValue ? enumValue.badge : value}
                </td>
            </tr>
        );
    }

}