import React, {Component} from 'react';
import {Link} from "react-router-dom";

import AppStoreLogo from './../assets/svg/logos/AppStore_BN.svg';
import PlayStoreLogo from './../assets/svg/logos/GooglePlay_BN.svg';
import MacLogo from './../assets/svg/logos/MacOS_BN.svg';
import WinLogo from './../assets/svg/logos/Windows_BN.svg';

import intl from 'react-intl-universal';
import {
    APPLE_FING_URL, COMMUNITY_URL,
    FACEBOOK_FING_URL,
    GET_FING_URL,
    GOOGLE_FING_URL,
    HELP_URL,
    LINKEDIN_FING_URL,
    TWITTER_FING_URL,
    WEB_FING_URL
} from '../Constants';

export default class AppFooter extends Component {

    // --------------------------------------------------------------------------------
    // ANCILLARY RENDER FUNCTIONS
    // --------------------------------------------------------------------------------

    renderFooterInternalLink(to, txt) {
        return <li className="nav-item"><Link className="nav-link" to={to}>{txt}</Link></li>
    }

    renderFooterLink(href, txt) {
        return <li className="nav-item"><a className="nav-link" target="_blank" rel="noopener noreferrer" href={href}>{txt}</a></li>
    }

    renderLogoLink(href, logo, id, description) {
        return (
            <a href={href}>
                <div className="btn btn-xs transition-3d-hover">
                    <figure id={id} className="media align-items-center">
                        <img className="img-fluid" width="150" src={logo} alt={description} data-parent={`#${id}`} />
                    </figure>
                </div>
            </a>
        )
    }

    // --------------------------------------------------------------------------------
    // MAIN RENDER FUNCTIONS
    // --------------------------------------------------------------------------------

    render() {
        return (
            <footer className="position-relative bg-footer overflow-hidden" >
                <div className="container space-2 border-bottom border-bottom-footer">
                    <div className="row justify-content-md-between">
                        <div className="col-md-4 mb-4 mb-lg-0">
                            {/*<h4 className="h6 text-white font-weight-semi-bold">{intl.get('footer_about')}</h4>*/}
                            <ul className="nav nav-sm nav-x-0 nav-white flex-column mb-0">
                                {this.renderFooterLink(HELP_URL, intl.get('footer_help'))}
                                {this.renderFooterLink(COMMUNITY_URL, intl.get('footer_community'))}
                                {this.renderFooterLink(WEB_FING_URL + "/fing-terms-of-service", intl.get('footer_tos'))}
                                {this.renderFooterLink(WEB_FING_URL + "/fing-privacy-policy", intl.get('footer_privacy'))}
                            </ul>
                        </div>

                        <div className="col-md-8 text-center">
                            <h4 className="text-white font-weight-semi-bold mb-1">{intl.getHTML('footer_app_promo_title')}</h4>
                            <p className="mb-4 text-white-70">{intl.get('footer_app_promo_subtitle')}</p>
                            {this.renderLogoLink(APPLE_FING_URL, AppStoreLogo, "SVGAppStore", "App Store Logo")}
                            {this.renderLogoLink(GOOGLE_FING_URL, PlayStoreLogo, "SVGPlayStore", "Play Store Logo")}
                            {this.renderLogoLink(GET_FING_URL, WinLogo, "SVGWindows", "Windows Download")}
                            {this.renderLogoLink(GET_FING_URL, MacLogo, "SVGMacOs", "Mac OS Download")}
                        </div>
                    </div>
                </div>

                <div className="bg-footer-bottom-banner">
                    <div className="d-flex justify-content-between align-items-center py-7 container">
                        <p className="small text-muted mb-0">© {new Date().getFullYear()} Fing Limited - All rights reserved</p>
                        <ul id="menu-footer-social-menu" className="footer-social-menu list-inline mb-0">
                            <li className="list-inline-item">
                                <a title="Facebook" href={FACEBOOK_FING_URL} className="btn btn-sm btn-icon text-muted">
                                    <span className="fab fa-facebook-f btn-icon__inner" /></a>
                            </li>
                            <li className="list-inline-item">
                                <a title="Twitter" href={TWITTER_FING_URL} className="btn btn-sm btn-icon text-muted">
                                    <svg 
                                        className="x-twitter"
                                        xmlns="http://www.w3.org/2000/svg" 
                                        height="16px" 
                                        width="16px"
                                        viewBox="0 0 512 512">
                                        <path fill="#8c98a4" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                                    </svg>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a title="LinkedIn" href={LINKEDIN_FING_URL}
                                    className="btn btn-sm btn-icon text-muted">
                                    <span className="fab fa-linkedin-in btn-icon__inner" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>);
    }
}