import React from "react";

import {
    get,
    put,
    post
} from '../../services/APIService';
import {ToastContainer} from "react-toastify";
import {failedToast, successToast} from "../../helpers/ToastHelper";
import ModalDialog, {hideDialogById, ModalDialogBody, ModalDialogFooter, showDialogById} from "../dialog/ModalDialog";
import {Link} from "react-router-dom";
import {
    convertDateToState,
    convertStateToDate,
    convertStateToEnum,
} from './helper/ConversionHelper';
import { controlMandatoryFields, controlMaxLengthFields } from "./helper/ControlHelper";
import { handleApiError } from "./helper/ErrorHelper";
import { ENUMS } from "./helper/EnumHelper";
import { TITLES } from './helper/TitlesHelper';
import { COPY } from './helper/CopyHelper';
import { DISABLED } from './helper/DisableHelper';
import { MAX_LENGTH } from './helper/MaxLengthHelper';
import { checkAllPermissions, PERMISSION } from './helper/PermissionHelper';
import { REST_URL_AGENT } from "./helper/URLHelper";
import TableDataField from "./fields/TableDataField";
import TableBadgeField from "./fields/TableBadgeField";
import TextField from "./fields/TextField";
import CardTable from "./extra/CardTable";
import MainContent from "./extra/MainContent";
import EventComponent from "./extra/EventComponent";

const mandatory = {}

const maxLengths = MAX_LENGTH.AGENT;

const disabled = DISABLED.AGENT;

const copyable = COPY.AGENT;

const titles = TITLES.AGENT;

const WATCH_DIALOG = "WATCH_DIALOG";
const DEACTIVATE_DIALOG = "DEACTIVATE_DIALOG";

const permissionTypes = PERMISSION.AGENT.types;
const permissionUrl = PERMISSION.AGENT.url;


export default class AdminAgentEditPage extends EventComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            attemptedSave: false,
            forbidden: false,
            loading: true,
            seeTermsAndConditions: false
        };
    }

    componentDidMount() {
        checkAllPermissions(permissionUrl,permissionTypes).then(result=> {
            const state = this.state;
            for(const key in result){
                state['permission_'+key] = result[key];
            }
            this.setState({...state},this.initWithAgentId())
        }).catch((err) => {
            this.props.history.replace({ pathname: `/`});
            handleApiError(err, this.context, () => this.setState({forbidden: true}));
        });
    }

    initWithAgentId() {
        if(this.state.permission_view !== null && this.state.permission_view === false) 
            this.props.history.replace({ pathname: `/`});
        const agentId = this.props.match.params.agentId;
        get(`${REST_URL_AGENT}/${agentId}`)
            .then((a) => {
                this.convertFullAgentToState(a);
            })
            .catch((err) => {
                failedToast("Unable to read agent");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    convertFullAgentToState(response) {
        const newState = this.state;
        if (response) {
            const agent = response;
            newState.agentId = agent.agentId;
            newState.agentPlatform = agent.agentPlatform;
            newState.agentVersion = agent.agentVersion;
            newState.userId = agent.userId;
            newState.customName = agent.customName;
            if(agent.activationDate)
                newState.activationDate = convertDateToState(new Date(agent.activationDate));
            if(agent.shardAssignmentDate)
                newState.shardAssignmentDate = convertDateToState(new Date(agent.shardAssignmentDate));
            if(agent.firstConnectionDate)
                newState.firstConnectionDate = convertDateToState(new Date(agent.firstConnectionDate));
            if(agent.lastConnectionDate)
                newState.lastConnectionDate = convertDateToState(new Date(agent.lastConnectionDate));
            newState.shardId = agent.shardId;
            newState.activationCode = agent.activationCode;
            newState.agentToken = agent.agentToken;
            newState.networkSignature = agent.networkSignature;
            newState.state = agent.state;            
            newState.connectionState = agent.connectionState;
            newState.networkId = agent.networkId;
            newState.masterUserId = agent.masterUserId;
            newState.hardwarePlatform = agent.hardwarePlatform;
            newState.makeBatch = agent.makeBatch;
            newState.agentSerialNo = agent.agentSerialNo;
            newState.customerTag = agent.customerTag;
            newState.lastActivationUserId = agent.lastActivationUserId;
            if(agent.lastActivationDate)
                newState.lastActivationDate = convertDateToState(new Date(agent.lastActivationDate));
            newState.lastDeactivationUserId = agent.lastDeactivationUserId;
            if(agent.lastDeactivationDate)
                newState.lastDeactivationDate = convertDateToState(new Date(agent.lastDeactivationDate));
            if(agent.lastDisconnectionDate)
                newState.lastDisconnectionDate = convertDateToState(new Date(agent.lastDisconnectionDate));
            newState.fingboxType = agent.fingboxType;
            newState.adminState = agent.adminState;
            newState.notes = agent.notes;
            newState.geoCountryCode = agent.geoCountryCode;
            newState.geoCountryCity = agent.geoCountryCity;
            newState.agentChannel = agent.agentChannel;
            if(agent.firstActivationDate)
                newState.firstActivationDate = convertDateToState(new Date(agent.firstActivationDate));
        }
        this.setState(newState);
    }

    // --------------------------------------------------------------------------------
    // MAIN RENDERING
    // --------------------------------------------------------------------------------

    render() {
        disabled['userId'] = this.state.permission_assign;
        const onSave = ()=>this.saveAgent();
        const onWatch = () => showDialogById(WATCH_DIALOG);
        const onDeactivate = () => showDialogById(DEACTIVATE_DIALOG);
        let watchText = this.state.masterUserId !== null ? "Unwatch" : "Watch";
        return (
            <MainContent history={this.props.history}>
                <div className="container-fluid space-1 px-4">
                    <div className="d-flex border-bottom mb-6">
                        <div>
                            <h2 className="d-inline-block">Edit Agent</h2>
                            <span className="text-secondary mx-2">/</span>
                            <Link to="/admin/agents">Back to Agents</Link>
                        </div>
                        <div className="ml-auto">
                            {this.state.permission_watch && <button className="btn btn-sm btn-warning mr-2" onClick={onWatch}>{watchText}</button>}
                            {this.state.state === "ACTIVATED" && this.state.permission_deactivate &&
                                <button className="btn btn-sm btn-danger mr-2" onClick={onDeactivate}>Deactivate</button>}
                            {this.state.permission_edit && <button className="btn btn-sm btn-primary" onClick={onSave}>Save</button>}
                        </div>
                    </div>
                    <ToastContainer/>
                    {this.renderForm()}
                    {this.renderData()}
                    {this.renderWatchDialog()}
                    {this.renderDeactivateDialog()}
                </div>
            </MainContent>
            
        );
    }
    watchAgent() {
        const urlIdParam = this.props.match.params.agentId;
        post(`${REST_URL_AGENT}/${urlIdParam}/watch?enabled=${this.state.masterUserId === null}`)
            .then((a) => {
                if (a) {
                    this.setState(a, () => successToast(a.masterUserId !== null ? "Agent watched" : "Agent unwatched"))
                }
            })
            .catch((err) => {
                failedToast("Unable to watch/unwatch agent");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    deactivateAgent() {
        const urlIdParam = this.props.match.params.agentId;
        post(`${REST_URL_AGENT}/${urlIdParam}/deactivate`)
            .then((a) => {
                if (a) {
                    successToast("Agent deactivated");
                    this.props.history.replace({ pathname: `/admin/agents`});
                }
            })
            .catch((err) => {
                failedToast("Unable to deactivate agent");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    saveAgent() {
        // === VALIDATE ===
        if(!controlMandatoryFields(this.state,mandatory,this.setState({attemptedSave: true}))) 
            return
        if(!controlMaxLengthFields(this.state,maxLengths,this.setState({attemptedSave: true})))
            return
        
        // === SEND ===

        let body = {};

        const urlIdParam = this.props.match.params.agentId;

        body = {
            agentId: urlIdParam,
            agentPlatform:this.state.agentPlatform,
            agentVersion:this.state.agentVersion,
            userId:this.state.userId,
            customName:this.state.customName,
            activationDate: convertStateToDate(this.state.activationDate),
            shardAssignmentDate: convertStateToDate(this.state.shardAssignmentDate),
            firstConnectionDate: convertStateToDate(this.state.firstConnectionDate),
            lastConnectionDate: convertStateToDate(this.state.lastConnectionDate),
            shardId:this.state.shardId,
            activationCode:this.state.activationCode,
            agentToken:this.state.agentToken,
            networkSignature:this.state.networkSignature,
            state: convertStateToEnum(this.state.state),
            connectionState: convertStateToEnum(this.state.connectionState),
            networkId:this.state.networkId,
            masterUserId:this.state.masterUserId,
            hardwarePlatform:this.state.hardwarePlatform,
            makeBatch:this.state.makeBatch,
            agentSerialNo:this.state.agentSerialNo,
            customerTag:this.state.customerTag,
            lastActivationUserId:this.state.lastActivationUserId,
            lastActivationDate: convertStateToDate(this.state.lastActivationDate),
            lastDeactivationUserId:this.state.lastDeactivationUserId,
            lastDeactivationDate: convertStateToDate(this.state.lastDeactivationDate),
            lastDisconnectionDate: convertStateToDate(this.state.lastDisconnectionDate),
            fingboxType:this.state.fingboxType,
            adminState:this.state.adminState,
            notes:this.state.notes,
            geoCountryCode:this.state.geoCountryCode,
            geoCountryCity:this.state.geoCountryCity,
            agentChannel:this.state.agentChannel,
            firstActivationDate: convertStateToDate(this.state.firstActivationDate)
        }

        this.setState({loading: true, attemptedSave: false});
        put(`${REST_URL_AGENT}/${urlIdParam}`, body)
            .then((a) => {
                if (a) {
                    successToast("Agent saved");
                    this.convertFullAgentToState(a);
                    if (urlIdParam) {
                        this.props.history.replace({ pathname: `/admin/agent/${urlIdParam}`})
                    }
                }
            })
            .catch((err) => {
                failedToast("Unable to save agent");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    // -------------------------------------------------------------------------------------------------

    renderForm() {
        return (
            <form>
                <div className="row">
                    <div className="col-4">
                        {this.renderTextField("userId", this.state.state !== "ACTIVATED" || !this.state.permission_assign )}

                    </div>
                    <div className="col-4">
                        {this.renderTextField("notes")}
                    </div>
                </div>
            </form>
        );
    }

    renderData() {
        return <>
            <h3>About this agent</h3>
            <div className="row">
                <div className="col-xs-12 col-md-4 col-sm-12 mb-2">
                    <CardTable title="Setup" className="h-100">
                        {this.renderDataField("agentId")}
                        {this.renderDataField("shardId")}
                        {this.renderDataField("networkId")}
                        {this.renderDataField("customName")}
                        {this.renderEnumField("state", ENUMS.AGENT.STATE)}
                        {this.renderEnumField("connectionState", ENUMS.AGENT.CONNECTION)}
                        {this.renderDataField("masterUserId")}
                        {this.renderDataField("fingboxType")}
                        {this.renderDataField("adminState")}
                        {this.renderDataField("customerTag")}
                        {this.renderDataField("geoCountryCode")}
                        {this.renderDataField("geoCountryCity")}
                    </CardTable>
                    
                </div>
                <div className="col-xs-12 col-md-4 col-sm-12 mb-2">
                    <CardTable title="Firmware" className="h-100">
                        {this.renderDataField("agentPlatform")}
                        {this.renderDataField("agentVersion")}
                        {this.renderDataField("agentToken")}
                        {this.renderDataField("agentSerialNo")}
                        {this.renderDataField("hardwarePlatform")}
                        {this.renderDataField("makeBatch")}
                        {this.renderDataField("agentChannel")}
                        {this.renderDataField("activationCode")}
                        {this.renderDataField("networkSignature")}
                    </CardTable>
                    
                </div>
                <div className="col-xs-12 col-md-4 col-sm-12 mb-2">
                    <CardTable title="Activation history" className="h-100">
                        {this.renderDataField("activationDate")}
                        {this.renderDataField("shardAssignmentDate")}
                        {this.renderDataField("firstConnectionDate")}
                        {this.renderDataField("lastConnectionDate")}
                        {this.renderDataField("firstActivationDate")}
                        {this.renderDataField("lastActivationUserId")}
                        {this.renderDataField("lastActivationDate")}
                        {this.renderDataField("lastDeactivationUserId")}
                        {this.renderDataField("lastDeactivationDate")}
                        {this.renderDataField("lastDisconnectionDate")}
                    </CardTable>
                    
                </div>
            </div>
        </>
    }

    renderDataField(field) {
        return <TableDataField 
            value={this.state[field] || ''} 
            label={titles[field]} 
            copyable={copyable[field]}/>
    }

    renderEnumField(field, values) {

        const value = this.state[field] || '';
        return <TableBadgeField
            value={value}
            label={titles[field]}
            enumValue={values.find(v => v.value === value)}/>
    }

    renderTextField(id, disabled) {
        return <TextField
            id={id}
            className="mb-3"
            value={this.state[id] || ''}
            attemptedSave={this.state.attemptedSave}
            label={titles[id]}
            mandatory={mandatory[id] === true}
            maxLength={maxLengths[id] || 0}
            onChange={this.handleInputFieldChange}
            disabled={disabled || false}/>
    }

    renderWatchDialog() {
        const onClose = () => hideDialogById(WATCH_DIALOG);
        const onWatch = () => {
            hideDialogById(WATCH_DIALOG);
            this.watchAgent();
        };
        let actionText = this.state.masterUserId !== null ? "Unwatch" : "Watch";
        let watchText = this.state.masterUserId !== null ? "Unwatch agent" : "Watch agent";
        const bodyText = this.state.masterUserId !== null ? 
            "The agent " + this.state.agentId + " will be removed from the watch list. Do you want to proceed?" :
            "The agent " + this.state.agentId + " will be added on the watch list. Do you want to proceed?"
        return <ModalDialog id={WATCH_DIALOG} title={watchText} onClose={onClose}>
            <ModalDialogBody className="py-3">
                {bodyText}
                <br/>
                <div className="card card-body">
                    <span className="text-primary" onClick={() => this.setState({seeTermsAndConditions: !this.state.seeTermsAndConditions})}>
                        SEE TERMS AND CONDITIONS
                    </span>
                    {this.state.seeTermsAndConditions && <div>
                        <div>Night gathers, and now my watch begins.</div>
                        <div>It shall not end until my death.</div>
                        <div>I shall take no wife, hold no lands, father no children.</div>
                        <div>I shall wear no crowns and win no glory.</div>
                        <div>I shall live and die at my post.</div>
                        <div>I am the sword in the darkness.</div>
                        <div>I am the watcher on the walls.</div>
                        <div>I am the shield that guards the realms of men.</div>
                        <div>I pledge my life and honor to the Night’s Watch, for this night and all the nights to come.</div>
                    </div>}
                </div>
                
            </ModalDialogBody>
            <ModalDialogFooter className="py-3">
                <button className="btn btn-xs btn-ghost-danger" onClick={onClose}>Dismiss</button>
                <button className="btn btn-xs btn-primary" onClick={onWatch}>{actionText}</button>
            </ModalDialogFooter>
        </ModalDialog>;
    }

    renderDeactivateDialog() {
        const onClose = () => hideDialogById(DEACTIVATE_DIALOG);
        const onDeactivate = () => {
            hideDialogById(DEACTIVATE_DIALOG);
            this.deactivateAgent();
        };
        return <ModalDialog id={DEACTIVATE_DIALOG} title={"Deactivate agent"} onClose={onClose}>
            <ModalDialogBody className="py-3">
                The agent {this.state.agentId} will be deactivated.
                Do you want to proceed?
            </ModalDialogBody>
            <ModalDialogFooter className="py-3">
                <button className="btn btn-xs btn-ghost-danger" onClick={onDeactivate}><i className="fa fa-fw fa-trash mr-2"/>Deactivate</button>
                <button className="btn btn-xs btn-primary" onClick={onClose}>No, keep it connected</button>
            </ModalDialogFooter>
        </ModalDialog>;
    }

}