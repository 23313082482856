import React from "react";



export default class CardTable extends React.Component {

    render() {
        const cName = this.props.className || '';
        return (
            <div className={"card card-bordered " + cName}>
                <div className="card-header bg-soft-primary p-2">
                    {this.props.title}
                </div>
                <div className="card-body p-0">
                    <table className="table table-striped">
                        <tbody>
                            {this.props.children}
                        </tbody>
                    </table>
                </div>
                
                {this.props.footer && <div className="card-footer p-2">
                    {this.props.footer}
                </div>}
            </div>
        );
    }

}