import React from "react";
import DataTable from "./DataTable";
import {Link} from "react-router-dom";

import { getParamsFromUrl, HEADERS, EXTRA_SEARCH_FIELDS, ON_RENDER_CELL, QUERY_URL } from './helper/TableHelper';
import IABDialog from "../dialog/IABDialog";
import { PERMISSION } from "./helper/PermissionHelper";
import ModalDialog, {
    ModalDialogBody,
    ModalDialogFooter,
    showDialogById,
    hideDialogById
} from '../dialog/ModalDialog';

import TitleTable from "./extra/TitleTable";
import MainContent from "./extra/MainContent";
import EventComponent from "./extra/EventComponent";

const EXPORT_DIALOG = "EXPORT_DIALOG";
export default class AdminIABListPage extends EventComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            messages: [],
            records: [],
            exportFileName: ''
        }

        this.handleSwitchExportFieldChange = this.handleSwitchExportFieldChange.bind(this);
    }

    handleSwitchExportFieldChange(id) {
        const messages = this.state.records?.filter(msg => msg.id === id);
        if(messages.length > 0){
            const newMsg = messages[0];
            const oldMessages = this.state.messages;
            const index = oldMessages.findIndex(m => m.id === id)
            let result = oldMessages;
            if(index === -1){
                result.push(newMsg);
            } else {
                result.splice(index, 1);
            }
            if(newMsg !== null){
                this.setState({messages: result});
            }
        }
    }


    render() {
        const switchComponent = (id) => <td><input 
            type="checkbox"
            checked={this.state.messages.filter(m => m.id === id).length > 0}
            style={{width:14, margin:0, padding:0}}
            onChange={(event)=>this.handleSwitchExportFieldChange(id)}
            id={"switch-"+id}/></td>

        const onExport = () => showDialogById(EXPORT_DIALOG);
        return (
            <MainContent 
                history={this.props.history} checkUrl={PERMISSION.IN_APP.url + "view"}>
                <div className="container-fluid space-1 px-4">
                    <TitleTable
                        title="In App Banners"
                        info="Search will look into Banner ID.">
                        <div className="ml-auto">
                            <button onClick={onExport} disabled={this.state.messages.length === 0} className="btn btn-sm btn-primary mr-2">
                                <i className="fa fa-fw fa-file-export mr-1"/>Export
                            </button>
                            <Link to="/admin/iab/new" className="btn btn-sm btn-primary"><i className="fa fa-fw fa-plus mr-1"/>Create</Link>
                        </div>
                    </TitleTable>
                    <DataTable
                        search={true}
                        queryUrl={QUERY_URL.IN_APP}
                        onSearch={(rows) => this.setState({records: rows.map(msg => JSON.parse(msg.jsonBody))})}
                        params={getParamsFromUrl(new URLSearchParams(this.props.location.search),QUERY_URL.IN_APP)}
                        extraSearchFields={EXTRA_SEARCH_FIELDS.IN_APP}
                        pagination={true}
                        excludePageNumbers={false}
                        autoBlockSearch={true}
                        resources="/rest/admin/notification/list?notificationType=BANNER"
                        name="In App Banners"
                        headers={HEADERS.in_app('IAB')}
                        onRenderCell={(header,row) => ON_RENDER_CELL.IAB(header, row, switchComponent)}
                        onForbidden={() => this.setState({forbidden: true})}
                        entries={true}
                    />
                    {this.state.records.map(m => <IABDialog 
                        id={m.id + "_IAB_DIALOG"} 
                        message={m}/>)}
                        
                </div>
                {this.renderExportDialog()}
            </MainContent>
        );
    }

    renderExportDialog(){
        const onClose = () => {
            hideDialogById(EXPORT_DIALOG);
            this.state({exportFileName: ''});
        }

        const onExport = () => {
            const json = {
                version: "1.0",
                messages: this.state.messages
            }
            let a = document.createElement("a");
            let file = new Blob([JSON.stringify(json, undefined, 4)], {type: "text/json"});
            a.href = URL.createObjectURL(file);
            a.download = this.state.exportFileName + ".json";
            a.click();
            this.setState({messages:[], exportFileName: ''})
        }

        
        return <ModalDialog id={EXPORT_DIALOG} title={"You are exporting selected messages in a campaign"} onClose={onClose}>
            <ModalDialogBody className="py-3">
                <p>Type a name for the export file</p>
                <input type="text" id={"exportFileName"}
                    className={`form-control form-control-sm my-0`}
                    placeholder={`Enter file name without extension`}
                    value={this.state.exportFileName}
                    onChange={this.handleInputFieldChange}/>
                

            </ModalDialogBody>
            <ModalDialogFooter className="py-3">
                <button className="btn btn-xs btn-ghost-danger" onClick={onClose}>Close</button>
                <button className="btn btn-xs btn-primary" onClick={onExport}>Import</button>
            </ModalDialogFooter>
        </ModalDialog>;
    }
}