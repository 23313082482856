export const reactSelectStyles = {
    control:(base, state) => ({
        ...base,
        height: "43px",
    }),
    indicatorContainer: (base, state) => ({
        ...base,
        height: "43px",
        marginBottom:20,
        paddingTop:0,
        paddingBottom:0,
        paddingLeft:0
    }),
    valueContainer: (base, state) => ({
        ...base,
        height: "43px",
        
    }),
    indicatorSeparator: (base, state) => ({
        ...base,
        backgroundColor: "transparent",
    }),
    multiValueRemove: (base,state) => ({
        ...base,
        display:'none'
    }),
    multiValue:(base, state) => ({
        ...base,
        marginTop:4,
        padding:0,
        marginBottom:'auto',
    }),
    container: (base, state) => ({
        ...base,
        height: "43px",
        marginTop:0,
        backgroundColor: "transparent"
    }),
    clearIndicator: (base, state) => ({...base, padding:0}),
    dropdownIndicator: (base, state) => ({...base, paddingTop:0,paddingBottom:0,paddingLeft:0, paddingRight:1}),
    group: (base, state) => ({...base}),
    groupHeading: (base, state) => ({...base}),
    indicatorsContainer: (base, state) => ({...base}),
    input: (base, state) => ({...base}),
    loadingIndicator: (base, state) => ({...base}),
    loadingMessage: (base, state) => ({...base}),
    menu: (base, state) => ({...base}),
    menuList: (base, state) => ({...base}),
    menuPortal: (base, state) => ({...base}),
    multiValueLabel: (base, state) => ({...base, paddingLeft:1,paddingRight:1}),
    noOptionsMessage: (base, state) => ({...base}),
    option: (base, state) => ({...base}),
    placeholder: (base, state) => ({...base}),
    singleValue: (base, state) => ({...base}),
};
