import React from "react";

import DataTable from "./DataTable";
import { getParamsFromUrl, HEADERS, EXTRA_SEARCH_FIELDS, ON_RENDER_CELL } from './helper/TableHelper';
import { PERMISSION } from "./helper/PermissionHelper";
import { REST_URL_VALIDATION } from "./helper/URLHelper";


import TitleTable from "./extra/TitleTable";
import MainContent from "./extra/MainContent";

export default class AdminValidationListPage extends React.Component {

    render() {
        return (
            <MainContent history={this.props.history} checkUrl={PERMISSION.VALIDATION.url+"view"}>
                <div className="container-fluid space-1 px-4">
                    <TitleTable
                        title="Validations"
                        info="Search will look into Gateway HW Address and User ID"/>
                    <DataTable
                        search={true}
                        queryUrl={['validationState', 'search']}
                        params={getParamsFromUrl(new URLSearchParams(this.props.location.search),['validationState', 'search'])}
                        extraSearchFields={EXTRA_SEARCH_FIELDS.VALIDATION}
                        pagination={true}
                        autoBlockSearch={true}
                        excludePageNumbers={false}
                        resources={`${REST_URL_VALIDATION}s`}
                        name="Validations"
                        headers={HEADERS.VALIDATION}
                        onRenderCell={ON_RENDER_CELL.VALIDATION}
                        onForbidden={() => this.setState({forbidden: true})}
                        entries={true}/>
                </div>
            </MainContent>
        );
    }

}