import React from "react";
import { isNotEmptyString } from "../../../helpers/JsHelper";


// labelSpan, dateSpan -> How many columns takes the cell?

export default class TableDateField extends React.Component {

    render() {
        const {label,value,attemptedSave, disabled, mandatory, id, onChange, labelSpan, dateSpan} = this.props;

        const isMissing = attemptedSave && mandatory && !isNotEmptyString(value, true);
        const colorText = isMissing ? "text-danger" : "text-dark";

        let wrapperId = `${id}_wrapper`;
        return (
            <tr>
                <td className="p-2" colspan={labelSpan || 1}> <span className={colorText}>{label}</span></td>
                <td className="p-2" colspan={dateSpan || 1}>
                    <div id={wrapperId} className="flatpickr w-100">
                        <input className={`js-range-flatpickr form-control form-control-sm bg-transparent rounded-right w-100 my-0 ${isMissing ? "border-danger" : ""}`}
                            id={id}
                            disabled={disabled}
                            type="date"
                            placeholder={`Select ${label}`}
                            data-hs-flatpickr-options={`{
                                    "appendTo": "#${wrapperId}",
                                    "mode": "single",
                                    "static" : "true",
                                    "defaultDate": "",
                                    "dateFormat": "Y/m/d"
                                    }
                                `}
                            value={value} onChange={onChange}
                        />
                    </div>
                </td>
            </tr>
        );
    }

}