import React from 'react';

import AppNavbar from '../components/AppNavbar';

export default class SignInToSharePromoPage extends React.Component {

    render() {
        return (
            <div>
                <AppNavbar mode="account" />
                <main id="content" role="main">
                    <div className="container space-bottom-2">
                        <div className="w-lg-80 mx-lg-auto">
                            <div className="card shadow-sm p-7">
                                <h1 className="h2 font-weight-semi-bold mb-0">Share your query</h1>
                                <h2 className="h6 text-secondary">To do this you need to sign in with your Fing account.</h2>
                                <span>
                                    <a className="btn btn-primary btn-pill btn-wide transition-3d-hover mt-5"
                                        href={this.props.link}>
                                        Sign in to share your query
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}