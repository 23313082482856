import React from "react";

import { get, put, remove} from "../../services/APIService";
import {ToastContainer} from "react-toastify";
import {failedToast, successToast} from "../../helpers/ToastHelper";
import ModalDialog, {hideDialogById, ModalDialogBody, ModalDialogFooter, showDialogById} from "../dialog/ModalDialog";
import {Link} from "react-router-dom";
import { convertDateToState, convertEnumToState, convertStateToDate, convertStateToEnum } from "./helper/ConversionHelper";
import { controlMandatoryFields, controlMaxLengthFields } from "./helper/ControlHelper";
import { handleApiError } from "./helper/ErrorHelper";
import { ENUMS } from "./helper/EnumHelper";
import { TITLES } from './helper/TitlesHelper';
import { MANDATORY } from './helper/MandatoryHelper';
import { COPY } from './helper/CopyHelper';
import { DISABLED } from './helper/DisableHelper';
import { MAX_LENGTH } from './helper/MaxLengthHelper';
import { checkAllPermissions, PERMISSION } from "./helper/PermissionHelper";
import { REST_URL_VALIDATION } from "./helper/URLHelper";
import TableDataField from "./fields/TableDataField";
import EnumField from "./fields/EnumField";
import AreaField from "./fields/AreaField";
import DateField from "./fields/DateField";

import TitleTable from "./extra/TitleTable";
import Tooltip from './extra/Tooltip';
import CardTable from "./extra/CardTable";
import MainContent from "./extra/MainContent";
import EventComponent from "./extra/EventComponent";

const maxLengths = MAX_LENGTH.VALIDATION;

const copyable = COPY.VALIDATION;

const titles = TITLES.VALIDATION;

const mandatory = MANDATORY.VALIDATION;

const disabled = DISABLED.VALIDATION;

const DELETE_VALIDATION_DIALOG = "DELETE_VALIDATION_DIALOG";

const permissionTypes = PERMISSION.VALIDATION.types;
const permissionUrl = PERMISSION.VALIDATION.url;

export default class AdminValidationEditPage extends EventComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            attemptedSave: false,
            forbidden: false,
            loading: true
        };
    }

    componentDidMount() {
        checkAllPermissions(permissionUrl,permissionTypes).then(result=> {
            const state = this.state;
            for(const key in result){
                state['permission_'+key] = result[key];
            }
            this.setState({...state},this.initWithValidationId())
        }).catch((err) => {
            this.props.history.replace({ pathname: `/`});
            handleApiError(err, this.context, () => this.setState({forbidden: true}));
        });
        
    }

    initWithValidationId() {
        if(this.state.permission_view !== null && this.state.permission_view === false) 
            this.props.history.replace({ pathname: `/`});
        const validationId = this.props.match.params.validationId;
        if (validationId && Number(validationId) >= 0) {
            get(`${REST_URL_VALIDATION}/${validationId}`)
                .then((validation) => {
                    if (validation) {
                        this.convertFullValidationToState(validation);
                    }
                })
                .catch((err) => {
                    failedToast("Unable to read validation");
                    handleApiError(err, this.context, () => this.setState({forbidden: true}));
                })
                .finally(() => this.setState({loading: false}));
        } 
        
    }

    convertFullValidationToState(response) {
        const newState = {}
        if (response) {
            const validation = response;
            newState.validationId = validation.validationId || -1;
            newState.userId = validation.userId || '';
            newState.gatewayHwAddress = validation.gatewayHwAddress || '';
            newState.blockingClientHwAddress = validation.blockingClientHwAddress || '';
            newState.blockingClientId = validation.blockingClientId || '';
            newState.comment = validation.comment || '';
            newState.blockedDevices = validation.blockedDevices || '';
            newState.publicIpAddress = validation.publicIpAddress || '';
            newState.blockedDevicesCount = validation.blockedDevicesCount || 0;
            if (validation.termsAcceptanceDate) newState.termsAcceptanceDate = convertDateToState(new Date(validation.termsAcceptanceDate));
            if (validation.creationDate) newState.creationDate = convertDateToState(new Date(validation.creationDate));
            if (validation.validationState) newState.validationState = convertEnumToState(ENUMS.VALIDATION.STATE, validation.validationState);
            newState.devicesCount = validation.devicesCount || 0;
            newState.personalDevicesCount = validation.personalDevicesCount || 0;
            newState.digitalMediaDevicesCount = validation.digitalMediaDevicesCount || 0;
            newState.smartHomeDevicesCount = validation.smartHomeDevicesCount || 0;
            newState.networkingDevicesCount = validation.networkingDevicesCount || 0;
            newState.gamingDevicesCount = validation.gamingDevicesCount || 0;
            newState.protectionDevicesCount = validation.protectionDevicesCount || 0;
            newState.itDevicesCount = validation.itDevicesCount || 0;
        }
        this.setState(newState);
    }

    // --------------------------------------------------------------------------------
    // MAIN RENDERING
    // --------------------------------------------------------------------------------

    render() {
        const validationId = this.getCurrentValidationId();

        const onSave = ()=>this.saveValidation();
        const onDelete = ()=> {
            showDialogById(DELETE_VALIDATION_DIALOG);
        };
        return (
            <MainContent history={this.props.history}>
                <div className="container-fluid space-1 px-4">
                    <div className="d-flex border-bottom mb-6">
                        <div>
                            <h2 className="d-inline-block">Edit Validation</h2>
                            <span className="text-secondary mx-2">/</span>
                            <Link to="/admin/validations">Back to Validations</Link>
                        </div>
                        <div className="ml-auto">
                            {this.state.permission_edit && validationId >= 0 && <button className="btn btn-sm btn-danger mr-2" onClick={onDelete}>Delete</button>}
                            {this.state.permission_edit && <button className="btn btn-sm btn-primary" onClick={onSave}>Save</button>}
                        </div>
                    </div>
                    <ToastContainer/>
                    {this.renderForm()}
                    {this.renderData()}
                    {this.renderDeleteValidationDialog()}
                </div>
            </MainContent>
            
        );
    }

    getCurrentValidationId() {
        let validationId = -1
        if (this.props.match && this.props.match.params && this.props.match.params.validationId)
            validationId = Number(this.props.match.params.validationId);
        if (isNaN(validationId))
            validationId = -1;
        return validationId;
    }

    saveValidation() {
        const validationId = this.getCurrentValidationId();

        // === VALIDATE ===
        if(!controlMandatoryFields(this.state,mandatory,this.setState({attemptedSave: true}))) 
            return
        if(!controlMaxLengthFields(this.state,maxLengths,this.setState({attemptedSave: true})))
            return

        // === SEND ===

        let body = {};
        body = {
            validationId: validationId,
            creationDate: convertStateToDate(this.state.creationDate),
            userId: this.state.userId,
            gatewayHwAddress: this.state.gatewayHwAddress,

            blockingClientHwAddress: this.state.blockingClientHwAddress,
            blockingClientId: this.state.blockingClientId,
            comment: this.state.comment,
            blockedDevices: this.state.blockedDevices,
            blockedDevicesCount: this.state.blockedDevicesCount,
            publicIpAddress: this.state.publicIpAddress,
            validationState: convertStateToEnum(this.state.validationState),
            termsAcceptanceDate: convertStateToDate(this.state.termsAcceptanceDate),
            itDevicesCount: this.state.itDevicesCount,
            protectionDevicesCount: this.state.protectionDevicesCount,
            networkingDevicesCount: this.state.networkingDevicesCount,
            smartHomeDevicesCount: this.state.smartHomeDevicesCount,
            digitalMediaDevicesCount: this.state.digitalMediaDevicesCount,
            personalDevicesCount: this.state.personalDevicesCount,
            devicesCount: this.state.devicesCount
        }

        this.setState({loading: true, attemptedSave: false});
        put(`${REST_URL_VALIDATION}/${validationId}`, body)
            .then((validation) => {
                if (validation) {
                    successToast("Validation saved");
                    this.convertFullValidationToState(validation);
                    if (validationId === -1 && validation && validation.validationId) {
                        this.props.history.replace({ pathname: `/admin/validation/${validation.validationId}`})
                    }
                }
            })
            .catch((err) => {
                failedToast("Unable to save validation");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    deletePost() {
        const validationId = this.getCurrentValidationId();
        if (validationId < 0)
            return;

        // === SEND ===

        this.setState({loading: true});
        remove(`${REST_URL_VALIDATION}/${validationId}`)
            .then((result) => {
                successToast("Validation removed");
                this.props.history.replace({pathname: `/admin/validations`});
            })
            .catch((err) => {
                failedToast("Unable to delete validation");
                handleApiError(err, this.context, () => this.setState({forbidden: true}));
            })
            .finally(() => this.setState({loading: false}));
    }

    // -------------------------------------------------------------------------------------------------

    renderData() {
        return <>
            <h3>About this agent</h3>
            <div className="row">
                <div className="col-xs-12 col-md-6 col-sm-12 mb-2">
                    <CardTable title="Info" className="h-100">
                        {this.renderDataField("userId")}
                        {this.renderDataField("gatewayHwAddress")}
                        {this.renderDataField("blockingClientHwAddress")}
                        {this.renderDataField("blockedDevices")}
                        {this.renderDataField("publicIpAddress")}
                        {this.renderDataField("blockingClientId")}
                    </CardTable>
                    
                </div>
                <div className="col-xs-12 col-md-6 col-sm-12 mb-2">
                    <CardTable title="Counters" className="h-100">
                        {this.renderDataField("blockedDevicesCount")}
                        {this.renderDataField("devicesCount")}
                        {this.renderDataField("personalDevicesCount")}
                        {this.renderDataField("digitalMediaDevicesCount")}
                        {this.renderDataField("networkingDevicesCount")}
                        {this.renderDataField("smartHomeDevicesCount")}
                        {this.renderDataField("gamingDevicesCount")}
                        {this.renderDataField("protectionDevicesCount")}
                        {this.renderDataField("itDevicesCount")}
                    </CardTable>
                    
                </div>
                
            </div>
        </>
    }

    renderForm() {
        return (
            <form>
                <div className="row">
                    <div className="col-4">
                        {this.renderEnumField("validationState", ENUMS.VALIDATION.STATE)}
                    </div>
                    <div className="col-4">
                        {this.renderDateField("creationDate")}
                    </div>
                    <div className="col-4">
                        {this.renderDateField("termsAcceptanceDate")}
                    </div>
                    <div className="col-12">
                        {this.renderAreaField("comment")}
                    </div>
                </div>
            </form>
        );
    }

    // --------------------------------------------------------------------------------
    // FORM FIELDS
    // --------------------------------------------------------------------------------

    renderAreaField(id) {

        return <AreaField
            attemptedSave={this.state.attemptedSave}
            value={this.state[id]}
            label={titles[id]}
            maxLength={maxLengths[id] || 0}
            disabled={disabled[id]}
            onChange={this.handleInputFieldChange}
            id={id}
            mandatory={mandatory[id]=== true}/>
    }

    renderDateField(id) {
        return <DateField 
            id={id} 
            value={this.state[id]} 
            label={titles[id]} 
            onChange={this.handleInputFieldChange}
            disabled={disabled[id] || true}
            mandatory={mandatory[id] === true}
            attemptedSave={this.state.attemptedSave}/>
    }

    renderDataField(field) {
        return <TableDataField 
            value={this.state[field] || ''} 
            label={titles[field]} 
            copyable={copyable[field]}/>
    }

    renderEnumField(id, enumValues) {
        return <EnumField
            id={id}
            mandatory={mandatory[id] === true}
            isMulti={false}
            attemptedSave={this.state.attemptedSave}
            value={this.state[id]}
            label={titles[id]}
            enumValues={enumValues}
            onChange={this.handleSelectFieldChange}/>
    }

    renderDeleteValidationDialog() {
        const onClose = () => hideDialogById(DELETE_VALIDATION_DIALOG);
        const onDelete = () => {
            hideDialogById(DELETE_VALIDATION_DIALOG);
            this.deletePost();
        };
        return <ModalDialog id={DELETE_VALIDATION_DIALOG} title={"Confirm Delete post"} onClose={onClose}>
            <ModalDialogBody className="py-3">
                Are you sure you want to delete this validation? All its content
                and <b className="text-primary">counters</b> will be lost.
            </ModalDialogBody>
            <ModalDialogFooter className="py-3">
                <button className="btn btn-xs btn-ghost-danger" onClick={onDelete}>Yes, delete it entirely</button>
                <button className="btn btn-xs btn-primary" onClick={onClose}>No, keep the validation</button>
            </ModalDialogFooter>
        </ModalDialog>;
    }

}