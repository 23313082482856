import { handleErrorFetch } from "../../../services/APIService";

export function handleApiError(err, context, callback) {
    console.error(err);
    if (err && err.code === 401) {  // Unauthorized access
        let newContext = handleErrorFetch(context);
        if (context.userContextUpdater)
            context.userContextUpdater(newContext);
    }
    if (err && err.code === 403) {  // Forbidden resource
        callback();
        //this.setState({forbidden: true});
    }
}