import React from "react";

// labelSpan, numberSpan -> How many columns takes the cell?

export default class TableNumberField extends React.Component {

    render() {

        const {value, disabled, label, id, onChange, labelSpan, numberSpan} = this.props;

        return <tr>
            <td className="p-2 d-flex" colspan={labelSpan || 1} style={{height:"30px"}}><span className="text-dark">{label}</span></td>
            <td className="p-2" colspan={numberSpan || 1} style={{height:"30px"}}>
                <input type="number" id={id}
                    className={`form-control form-control-sm my-0 py-0`}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                />
            </td>
        </tr>
    }

}