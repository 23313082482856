export const TITLES = {
    AGENT: {
        agentId: "Agent ID",
        shardId: "Shard ID",
        networkId: "Network ID",
        customName: "Custom Name",
        state: "State",
        connectionState: "Connection State",
        masterUserId: "Master User ID",
        fingboxType: "Fingbox Type",
        adminState: "Admin State",
        customerTag: "Customer Tag",
        geoCountryCode: "Geo Country Code",
        geoCountryCity: "Geo Country City",
        agentPlatform: "Agent Platform",
        agentVersion: "Agent Version",
        agentToken: "Agent Token",
        agentSerialNo: "Agent Serial No",
        hardwarePlatform: "Hardware Platform",
        makeBatch: "Make Batch",
        agentChannel: "Agent Channel",
        activationCode: "Activation Code",
        networkSignature: "Network Signature",
        activationDate: "Activation Date",
        shardAssignmentDate: "Shard Assignment Date",
        firstConnectionDate: "First Connection Date",
        lastConnectionDate: "Last Connection Date",
        firstActivationDate: "First Activation Date",
        lastActivationUserId: "Last Activation User ID",
        lastActivationDate: "Last Activation Date",
        lastDeactivationUserId: "Last Deactivation User ID",
        lastDeactivationDate: "Last Deactivation Date",
        lastDisconnectionDate: "Last Disconnection Date",
        userId: "User ID",
        notes: "Notes"
    },
    CONNECTION: {
        userId: "User ID",
        clientId: "Client ID",
        clientName: "Client Name",
        clientType: "Client Type",
        clientVersion: "Client Version",
        clientPlatform: "Client Platform",
        firstAccess: "First Access",
        lastAccess: "Last Access",
        lastAccessAddress: "Last Access Address",
        loginProvidernotificationToken: "Login Provider Token",
        apnsToken: "APNS Token"
    },
    OFFER: {
        promoText:"Promo Text",
        description:"Description",
        startDate:"Start Date",
        endDate:"End Date",
        enabled:"Enabled",
        allowedCountries:"Countries",
        discountPlatform:"Discount Platform",
        discountId:"Discount ID",
        allowedPlans:"Plans",
    },
    IAB: {
        "account-has-desktop": "Has Desktop",
        "account-has-fingbox": "Has Fingbox",
        "account-has-mobile": "Has Mobile",
        iabId: "IAB ID",
        layout: "Layout",
        "color-light-border":"Border Color L",
        "color-light-extra":"Extra Color L",
        "color-dark-border":"Border Color D",
        "color-dark-extra":"Extra Color D",
        'image-default':"Default Image",
        'image-landscape': "Landscape Image",
        'image-animated': "Animated Image",
        action:"Action",
        countries: "Countries",
        "countries-exclude": "Countries Excluded",
        platforms: "Platform",
        "purchase-states": "Purchase State",
        'account-types': "Account Types",
        'tech-attitude': "Tech Attitude",
        conversion: "Conversion",
        start_ms: "Start Date",
        enabled: "Enabled",
        end_ms: "End Date",
        locationId: "Location ID",
        "app-version-v2-min": "App Version V2 Min",
        "app-version-v2-max": "App Version V2 Max",
        "notification-local-time": "Local Time",
        "notification-enabled": "Enabled"
    },
    IAM: {
        "account-has-desktop": "Has Desktop",
        "account-has-fingbox": "Has Fingbox",
        "account-has-mobile": "Has Mobile",
        iamId: "IAM ID",
        layout: "Layout",
        'image-default':"Default Image",
        'image-landscape': "Landscape Image",
        'image-animated': "Animated Image",
        'action-button':"Action Button",
        countries: "Countries",
        "countries-exclude": "Countries Excluded",
        platforms: "Platform",
        'account-types': "Account Types",
        'tech-attitude': "Tech Attitude",
        'conversion-display': "Conversion Display",
        'conversion-click': "Conversion Click",
        "notification-local-time": "Local Time",
        "notification-enabled": "Enabled",
        start_ms: "Start Date",
        enabled: "Enabled",
        end_ms: "End Date",
        'purchase-states': "Purchase State",
        "app-version-v2-min": "App Version V2 Min",
        "app-version-v2-max": "App Version V2 Max",
        'frequency-per-device': "Frequency per device",
        'days-between-display': "Days between display",
        'days-across-any-message': "Days across any message",
        'days-since-first-open': "Days since first open"
    },
    USER: {
        name: "Name",
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        timezone: "Timezone",
        zendeskId: "Zendesk ID",
        googleUserId: "Google ID",
        facebookUserId: "Facebook ID",
        appleId: "Apple ID",
        fadNodeId: "Fad Node ID",
        registrationDate: "Registration Date",
        failedLoginAttempts: "Failed Login Attempts",
        enableDigitalPresenceStats: "Digital Presence Stats Enabled",
        verified: "Verified",
        subscribedToNewsletter: "Subscribed to Newsletter",
        subscribedToContent: "Subscribed to Content",
        jobTitle: "Job Title",
        techAttitude: "Tech Attitude", 
        professionalField: "Professional Field",
        accountType: "Account Type",
        userRoles: "User Roles",
        companyName: "Company Name",
        networkCount: "Network count",
        recogCount: "Recog Count",
        countryCode: "Country Code",
        countryRegion: "Country Region",
        countryCity: "Country City",
        isp: "Isp",
        alertEmail: "Alert Email",
        languageCode: "Language Code",
        ispAlertType: "Isp Alert Type",
        alertMailType: "Alert Email Type",
        alertMessageType: "Alert Message Type",
        stripeCustomerId: "Customer ID",
        stripeActiveSubscriptionId: "Active Sub ID"
    },
    VALIDATION: {
        userId:"User ID",
        gatewayHwAddress:"Gateway HW Address",
        blockingClientHwAddress:"Blocking Client HW Address",
        validationState:"Validation State",
        creationDate:"Creation Date",
        termsAcceptanceDate:"Terms Acceptance Date",
        comment:"Comment",
        blockedDevices:"Blocked Devices",
        blockedDevicesCount:"Blocked Devices Count",
        publicIpAddress:"IP Address",
        blockingClientId: "Blocking Client ID",
        devicesCount:"Devices Count",
        personalDevicesCount:"Personal Devices Count",
        digitalMediaDevicesCount:"Digital Media Devices Count",
        networkingDevicesCount:"Networking Devices Count",
        smartHomeDevicesCount:"Smart Home Devices Count",
        gamingDevicesCount:"Gaming Devices Count",
        protectionDevicesCount:"Protection Devices Count",
        itDevicesCount:"IT Devices Count"
    }

}